import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  Table,
  message,
  Button,
  Modal,
  InputNumber,
  Typography,
  Popconfirm,
} from "antd";
import APICaller from "../../utils/APICaller";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HandleSearch from "../../utils/CommonSearchComponent";
import { selectedRowInfo } from "../../store/actions";
import useCurrentPath, { decryptData } from "../../components/Constants";
import { store } from "../../store";
import CustomLoader from "../../components/CustomLoader";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const Departments = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = useCurrentPath();
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  //Search Filter
  const { Search } = Input;
  const [searchText, setSearchText] = useState("");

  let storeRole = useSelector((state) => state.auth.role);

  let storeToken = useSelector((state) => state.auth.accessToken);

  let storeLIUId = useSelector((state) => state.auth.lIUId);

  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );
  // Initialize storeList with a default value
  const [storeList, setStoreList] = useState([]);
  const [data, setData] = useState([]);
  const storePagination = useSelector((state) => state.auth.pagination);

  const storeSelectedRowKeysChildInfo = useSelector(
    (state) => state.auth.selectedRowKeysChildInfo
  );

  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [collapseClass, setCollapseClass] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [list, setList] = useState([]);

  const [values, setValues] = useState({
    name: "",
    code: "",
    history: "",
    createdAt: "",
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  //Add Department
  const onFinish = (departmentValues) => {
    setShowLoader(true);
    const endpoint = "/departments";
    const method = "POST";
    const payload = {
      auth: storeToken,
      name: departmentValues?.name,
      code: departmentValues?.code,
      createdAt: new Date().toISOString(),
      historySource: "backend",
      historyAction: "create",
      historyUserID: storeLIUId,
    };
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        setCollapseClass("hide");
        message.success("Department added successfully.");
        form.resetFields();
        getDepartmentsList();
        setShowLoader(false);
      })
      .catch((error) => {
        // if (error.data && error.data.message) {
        //   message.error(error.data.message);
        // } else {
        //   message.error("An error occurred while creating the department");
        // }
        setShowLoader(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setShowLoader(false);
  };

  const getDepartmentsList = async (source = "", pagination = "") => {
    setShowLoader(true);

    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    setSelectedRowKeys([]);
    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
      fetchWithoutPagination: "yes",
    };

    await HandleSearch(
      storeRole,
      storeToken,
      storeLIUId,
      storeAccessTokenExpiresOn,

      searchIn,

      callActionFrom,
      allStates,
      ""
    );

    // Ensure storeList gets updated with the latest data
    const fetchedData = store.getState().auth.list[currentPath + "_info"];
    //const fetchedData = data ? decryptData(data) : [];

    setStoreList(fetchedData);

    // Update data state after fetching
    setData(fetchedData);
    setShowLoader(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getDepartmentsList();
    };
    fetchData();
  }, [storeLIUId, storeRole, storeToken, location.pathname]);

  {
    /*Delete action*/
  }
  const handleAction = (params) => {
    switch (params) {
      case "delete":
        let submitDeleteFormModal = "";

        if (isDeleteModalVisible === true) {
          if (storeRole === process.env.REACT_APP_SROLE) {
            submitDeleteFormModal = "clickedDeleteOK";
          }
        }

        if (submitDeleteFormModal === "clickedDeleteOK") {
          setShowLoader(true);
          const endpoint = "/deleteMul";
          const method = "POST";
          const payload = {
            role: storeRole,
            action: currentPath.replace("s", ""),
            ids: storeSelectedRowKeysChildInfo,
          };
          APICaller(
            storeRole,
            storeToken,
            endpoint,
            method,
            payload,
            storeAccessTokenExpiresOn
          )
            .then((response) => {
              setIsDeleteModalVisible(false);
              setCollapseClass("hide");
              getDepartmentsList();
              setSelectedRowKeys([]);
              dispatch(
                selectedRowInfo({
                  selectedRowKeysChildInfo: [],
                })
              );
              setShowLoader(false);
            })
            .catch((error) => {
              setShowLoader(false);
            });
        } else {
          setIsDeleteModalVisible(true);
          form.resetFields();
        }
        break;
    }
  };

  const columns = [],
    additionalColumns = [];

  if (storeRole !== process.env.REACT_APP_SROLE) {
    columns.push({
      title: "Sr. No.",
      dataIndex: "srno",
      width: "5%",
      render: (text, record, index) => index + 1,
    });
  }

  const commonColumns = [
    {
      title: "Department Name",
      dataIndex: "name",
      width: "15%",
      editable: true,
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Department Code",
      dataIndex: "code",
      width: "15%",
      editable: true,
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: "15%",
    },
  ];

  if (
    storeRole === process.env.REACT_APP_SROLE ||
    storeRole === process.env.REACT_APP_ADROLE
  ) {
    additionalColumns.push(
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        width: "20%",
      },
      {
        title: "Action",
        dataIndex: "action",
        width: "20%",
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
          );
        },
      }
    );
  }
  columns.push(...commonColumns, ...additionalColumns);

  const mergedColumns =
    columns &&
    columns.map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          inputType: "text",
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        }),
      };
    });

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      const selectedRowKeysChildInfoArr = selectedRows.map((row) => row.key);

      dispatch(
        selectedRowInfo({
          selectedRowKeysChildInfo: selectedRowKeysChildInfoArr,
        })
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  // Filter the storeList based on the searchText
  const searchFilterData =
    storeList &&
    storeList.filter((item) => {
      const name = item.name ? item.name.toLowerCase() : "";
      const code = item.code ? item.code.toLowerCase() : "";
      return (
        name.includes(searchText.toLowerCase()) ||
        code.includes(searchText.toLowerCase())
      );
    });

  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    tableForm.setFieldsValue({
      name: "",
      code: "",
      createdAt: "",
      updatedAt: "",
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await tableForm.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");

        const endpoint = "/departments/" + key;
        const method = "PUT";
        const payload = {
          name: newData[index]?.name,
          code: newData[index]?.code,
          historySource: "backend",
          historyAction: "update",
          historyUserID: storeLIUId,
        };
        APICaller(
          storeRole,
          storeToken,
          endpoint,
          method,
          payload,
          storeAccessTokenExpiresOn
        )
          .then((response) => {
            message.success("Department updated successfully.");
            tableForm.resetFields();
            getDepartmentsList();
          })
          .catch((error) => {});
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  return (
    <>
      <div className="container">
        <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-0">
          <div className="border-2 card-header p-5">
            <div className="align-items-center d-flex justify-content-between">
              <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark text-size-16">
                Departments
              </h3>

              <button
                className="btn font-size-lg font-weight-bold py-2 redbutton rounded-lg text-white collapsed"
                data-toggle="collapse"
                href="#newdep"
                role="button"
                aria-expanded="false"
                aria-controls="collapseContent"
              >
                <i className="flaticon2-plus mr-2 text-white"></i> Add New
                Department
              </button>
            </div>
          </div>

          {/**Add New Dep Start**/}
          <div id="newdep" className={`collapse p-3 ${collapseClass}`}>
            <div className="border border-light-dark p-4 m-0">
              <Form
                layout="vertical"
                autoComplete="off"
                className="form-validation-start"
                onFinish={onFinish}
                form={form}
                onFinishFailed={onFinishFailed}
              >
                <Row gutter={[24, 24]}>
                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Department Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter department name",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter the department name"
                        className="form-control h-45px rounded-md"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            name: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Department Code"
                      name="code"
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: "Please enter department code",
                      //   },
                      // ]}
                    >
                      <Input
                        placeholder="Enter the department code"
                        className="form-control h-45px rounded-md"
                        onChange={(e) => {
                          setValues({
                            ...values,
                            code: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn font-size-lg font-weight-bold ml-auto d-block py-2 redbutton rounded-lg text-white h-auto"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>

            <hr className="mt-10" />
          </div>
          {/**Add New DEp End**/}

          <div className="p-3">
            {storeRole === process.env.REACT_APP_SROLE &&
              storeSelectedRowKeysChildInfo &&
              storeSelectedRowKeysChildInfo.length > 0 && (
                <div className="">
                  <button
                    className="btn font-size-lg font-weight-bold ml-auto d-block py-2 redbutton rounded-lg text-white mb-5"
                    onClick={() => {
                      handleAction("delete");
                    }}
                  >
                    Delete
                  </button>
                </div>
              )}
            <div id="alltable-size">
              <CustomLoader showLoader={showLoader} />
              <Search
                className="table-search"
                placeholder="Search Departments Name & Code.."
                onSearch={(value) => setSearchText(value)}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Form form={tableForm} component={false}>
                <Table
                  rowSelection={
                    storeRole !== process.env.REACT_APP_SROLE
                      ? ""
                      : {
                          type: selectionType,
                          ...rowSelection,
                        }
                  }
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={searchFilterData}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
      {/*Delete Modal*/}
      <Modal
        title=""
        open={isDeleteModalVisible}
        onOk={() => {
          handleAction("delete");
        }}
        onCancel={() => {
          setIsDeleteModalVisible(false);
        }}
        okText={"Yes"}
        cancelText={"No"}
        className="delModal"
      >
        Are you sure you want to delete ?
      </Modal>
    </>
  );
};
export default Departments;
