import React, { useEffect, useState } from "react";
import APICaller from "../../utils/APICaller";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import CustomLoader from "../../components/CustomLoader";
import { decryptData } from "../../components/Constants";

const NotificationDetail = () => {
  let storeRole = useSelector((state) => state.auth.role);
  //storeRole = storeRole ? decryptData(storeRole) : null;

  let storeToken = useSelector((state) => state.auth.accessToken);
  //storeToken = storeToken ? decryptData(storeToken) : null;

  const location = useLocation();
  const id = location.pathname?.split("/")?.[2];
  const [notificationValues, setNotificationValues] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );

  const getNotificationDetail = () => {
    setShowLoader(true);
    const endpoint = `/notifications/${id}`;
    const method = "GET";
    const payload = {};
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    ).then((response) => {
      setNotificationValues(response);
      setShowLoader(false);
    });
  };

  useEffect(() => {
    getNotificationDetail();
  }, [storeRole, storeToken, id]);

  return (
    <div className="container">
      <CustomLoader showLoader={showLoader} />
      <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-0">
        <div className="border-2 card-header p-5">
          <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark text-size-16">
            Notification Detail
          </h3>
        </div>
        <div className="p-3">
          <table class="table table-striped border">
            <tbody className="font-weight-bold">
              <tr>
                <th>Assigned By</th>
                <th>Message</th>
                <th>Created At</th>
              </tr>
              <tr>
                <td>{notificationValues?.assignedBy}</td>
                <td
                  dangerouslySetInnerHTML={{
                    __html: notificationValues?.message,
                  }}
                />
                <td>
                  {notificationValues?.createdAt &&
                    moment(notificationValues?.createdAt).format(
                      "DD-MM-YY hh:mm A"
                    )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NotificationDetail;
