import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Table, message } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HandleSearch from "../../utils/CommonSearchComponent";
import APICaller from "../../utils/APICaller";
import useCurrentPath, { decryptData } from "../../components/Constants";
import { store } from "../../store";
import CustomLoader from "../../components/CustomLoader";

const Permissions = () => {
  const location = useLocation();
  const currentPath = useCurrentPath();
  const [form] = Form.useForm();

  let storeRole = useSelector((state) => state.auth.role);
  //storeRole = storeRole ? decryptData(storeRole) : null;

  let storeToken = useSelector((state) => state.auth.accessToken);
  //storeToken = storeToken ? decryptData(storeToken) : null;

  let storeLIUId = useSelector((state) => state.auth.lIUId);
  //storeLIUId = storeLIUId ? decryptData(storeLIUId) : null;

  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );
  // Initialize storeList with a default value
  const [storeList, setStoreList] = useState([]);
  const storePagination = useSelector((state) => state.auth.pagination);
  const storeSelectedRowKeysChildInfo = useSelector(
    (state) => state.auth.selectedRowKeysChildInfo
  );

  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [list, setList] = useState([]),
    [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [collapseClass, setCollapseClass] = useState("");
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  {
    /*Delete action*/
  }
  const handleAction = (params) => {
    switch (params) {
      case "delete":
        let submitDeleteFormModal = "";

        if (isDeleteModalVisible === true) {
          if (storeRole === process.env.REACT_APP_SROLE) {
            submitDeleteFormModal = "clickedDeleteOK";
          }
        }

        if (submitDeleteFormModal === "clickedDeleteOK") {
          setShowLoader(true);
          const endpoint = "/softDeleteMul";
          const method = "POST";
          const payload = {
            role: storeRole,
            action: currentPath,
            ids: storeSelectedRowKeysChildInfo,
          };

          APICaller(
            storeRole,
            storeToken,
            endpoint,
            method,
            payload,
            storeAccessTokenExpiresOn
          )
            .then((response) => {
              setIsDeleteModalVisible(false);
              getPermissionList();
              setSelectedRowKeys([]);
              setShowLoader(false);
            })
            .catch((error) => {
              setShowLoader(false);
            });
        } else {
          setIsDeleteModalVisible(true);
          form.resetFields();
        }
        break;
    }
  };
  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const [selectionType, setSelectionType] = useState("checkbox");
  const columns = [];
  if (storeRole !== process.env.REACT_APP_SROLE) {
    columns.push({
      title: "Sr. No.",
      render: (text, record, index) => index + 1,
    });
  }
  const additionalColumns = [
    {
      title: "Permission Name",
      dataIndex: "name",
      render: (text) => <>{text}</>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
  ];

  columns.push(...additionalColumns);

  //Add Permission
  const onFinish = (permissionValues) => {
    setShowLoader(true);
    const endpoint = "/permissions";
    const method = "POST";
    const payload = {
      auth: storeToken,
      name: permissionValues?.name,
      createdAt: new Date().toISOString(),
      historySource: "backend",
      historyAction: "create",
      historyUserID: storeLIUId,
    };
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        setCollapseClass("hide");
        message.success("Permission added successfully.");
        form.resetFields();
        getPermissionList();
        setShowLoader(false);
      })
      .catch((error) => {
        // if (error.data && error.data.message) {
        //   message.error(error.data.message);
        // } else {
        //   message.error("An error occurred while creating the permission");
        // }
        setShowLoader(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setShowLoader(false);
  };
  const getPermissionList = async (source = "", pagination = "") => {
    setShowLoader(true);
    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    setSelectedRowKeys([]);
    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
      fetchWithoutPagination: "yes",
    };

    await HandleSearch(
      storeRole,
      storeToken,
      storeLIUId,
      storeAccessTokenExpiresOn,
      searchIn,
      callActionFrom,
      allStates,
      ""
    );
    // Update local state after fetching data
    const decryptedData = store.getState().auth.list[currentPath + "_info"];
    //const decryptedData = data ? decryptData(data) : [];

    setStoreList(decryptedData);

    setShowLoader(false);
  };

  useEffect(() => {
    getPermissionList();
  }, []);
  return (
    <>
      <div className="container">
        <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg py-5 px-0">
          <div className="border-2 card-header p-3 px-5 pb-6">
            <div className="align-items-center d-flex justify-content-between">
              <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark text-size-16">
                Permissions
              </h3>

              <button
                className="btn font-size-lg font-weight-bold py-2 redbutton rounded-lg text-white collapsed"
                data-toggle="collapse"
                href="#newPermission"
                role="button"
                aria-expanded="false"
                aria-controls="collapseContent"
              >
                <i className="flaticon2-plus mr-2 text-white"></i> Add New
                Permission
              </button>
            </div>
          </div>

          {/**Add New Permission Start**/}
          <div
            id="newPermission"
            className={`collapse card-body ${collapseClass}`}
          >
            <div className="border border-light-dark p-4 m-0">
              <Form
                layout="vertical"
                autoComplete="off"
                className="form-permission form-validation-start"
                onFinish={onFinish}
                form={form}
                onFinishFailed={onFinishFailed}
              >
                <Row gutter={[24, 24]}>
                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input name",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter the name"
                        className="form-control h-45px"
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn font-size-lg font-weight-bold ml-auto d-block py-2 redbutton rounded-lg text-white h-auto"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>

            <hr className="mt-10" />
          </div>
          {/**Add New Permission End**/}

          <div className="card-body">
            {storeRole === process.env.REACT_APP_SROLE &&
              storeSelectedRowKeysChildInfo &&
              storeSelectedRowKeysChildInfo.length > 0 && (
                <div className="">
                  <button
                    className="btn font-size-lg font-weight-bold ml-auto d-block py-2 redbutton rounded-lg text-white mb-5"
                    onClick={() => {
                      handleAction("delete");
                    }}
                  >
                    Delete
                  </button>
                </div>
              )}
            <CustomLoader showLoader={showLoader} />
            <Table
              rowSelection={
                storeRole !== process.env.REACT_APP_SROLE
                  ? ""
                  : {
                      type: selectionType,
                      ...rowSelection,
                    }
              }
              columns={columns}
              dataSource={storeList}
              pagination={storePagination}
              onChange={(e) => {
                getPermissionList("pagination", e);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Permissions;
