import React from "react";
import "./main.css";
import TopHeader from "../TopHeader";
import Sidebar from "../Sidebar";

const Main = ({ children = null }) => {
  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-row flex-column-fluid page">
        <Sidebar />
        <div
          className="d-flex flex-column flex-row-fluid wrapper"
          id="kt_wrapper"
        >
          <TopHeader />
          <div
            className="content d-flex flex-column flex-column-fluid"
            id="kt_content"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
