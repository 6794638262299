import React, { useEffect, useState } from "react";
import { Col, Form, Input, Row, Table, message, Button, Select } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HandleSearch from "../../utils/CommonSearchComponent";
import APICaller from "../../utils/APICaller";
import useCurrentPath, { decryptData } from "../../components/Constants";
import { store } from "../../store";

const Roles = () => {
  const location = useLocation();
  const currentPath = useCurrentPath();
  const [form] = Form.useForm();

  let storeRole = useSelector((state) => state.auth.role);
  //storeRole = storeRole ? decryptData(storeRole) : null;

  let storeToken = useSelector((state) => state.auth.accessToken);
  //storeToken = storeToken ? decryptData(storeToken) : null;

  let storeLIUId = useSelector((state) => state.auth.lIUId);
  //storeLIUId = storeLIUId ? decryptData(storeLIUId) : null;

  const storePagination = useSelector((state) => state.auth.pagination);
  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );

  // Initialize storeList with a default value
  const [storeList, setStoreList] = useState([]);

  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [list, setList] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [collapseClass, setCollapseClass] = useState("");

  // Fetch role list
  const getRoleList = async (source = "", pagination = "") => {
    setShowLoader(true);

    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    setSelectedRowKeys([]);
    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
      fetchWithoutPagination: "yes",
    };

    await HandleSearch(
      storeRole,
      storeToken,
      storeLIUId,
      storeAccessTokenExpiresOn,
      searchIn,
      callActionFrom,
      allStates,
      ""
    );

    // Update local state after fetching data
    const decryptedData = store.getState().auth.list[currentPath + "_info"];
    //const decryptedData = data ? decryptData(data) : [];

    setStoreList(decryptedData);
    setShowLoader(false);
  };

  // Fetch permissions
  const getPermissions = () => {
    const endpoint = "/permissions/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response?.response?.map((permission, index) => {
          temp.push({ label: permission?.name, value: permission?._id });
        });
        setPermissions(temp);
      })
      .catch((error) => {
        // message.error("Failed to fetch permissions.");
      });
  };

  useEffect(() => {
    getRoleList();
    getPermissions();
  }, [currentPath]); // Added currentPath as dependency to re-fetch when it changes

  const onFinish = (roleValues) => {
    setShowLoader(true);
    const endpoint = "/roles";
    const method = "POST";
    const payload = {
      auth: storeToken,
      name: roleValues?.name,
      permissions: JSON.stringify(roleValues?.permissions),
      createdAt: new Date().toISOString(),
      historySource: "backend",
      historyAction: "create",
      historyUserID: storeLIUId,
    };
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        setCollapseClass("hide");
        message.success("Role added successfully.");
        form.resetFields();
        getRoleList(); // Refresh the role list after adding a new role
        setShowLoader(false);
      })
      .catch((error) => {
        // if (error.data && error.data.message) {
        //   message.error(error.data.message);
        // } else {
        //   message.error("An error occurred while creating the role");
        // }
        setShowLoader(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setShowLoader(false);
  };

  // Define columns with conditional rendering
  const columns = [];
  if (storeRole !== process.env.REACT_APP_SROLE) {
    columns.push({
      title: "Sr. No.",
      render: (text, record, index) => index + 1,
    });
  }
  const additionalColumns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => <>{text}</>,
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      render: (text) => <>{text}</>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
  ];
  columns.push(...additionalColumns);

  // Row selection configuration
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  return (
    <>
      <div className="container">
        <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-0">
          <div className="border-2 card-header p-5">
            <div className="align-items-center d-flex justify-content-between">
              <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark text-size-16">
                Roles
              </h3>

              <button
                className="btn font-size-lg font-weight-bold py-2 redbutton rounded-lg text-white collapsed"
                data-toggle="collapse"
                href="#newRole"
                role="button"
                aria-expanded="false"
                aria-controls="collapseContent"
              >
                <i className="flaticon2-plus mr-2 text-white"></i> Add New Role
              </button>
            </div>
          </div>

          {/** Add New Role Start **/}
          <div id="newRole" className={`collapse p-3 ${collapseClass}`}>
            <div className="border border-light-dark p-4 m-0">
              <Form
                layout="vertical"
                autoComplete="off"
                className="form-validation-start"
                onFinish={onFinish}
                form={form}
                onFinishFailed={onFinishFailed}
              >
                <Row gutter={[24, 24]}>
                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input name",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter the name"
                        className="form-control h-45px rounded-md"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Permissions"
                      name="permissions"
                      rules={[
                        {
                          required: true,
                          message: "Please enter permission",
                        },
                      ]}
                    >
                      <Select
                        size="large"
                        className="rounded-md h-45px rounded-md"
                        isclearable="true"
                        mode="multiple"
                        placeholder="Select Permission"
                        options={permissions}
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn font-size-lg font-weight-bold ml-auto d-block py-2 redbutton rounded-lg text-white h-auto"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>

            <hr className="mt-10" />
          </div>
          {/** Add New Role End **/}

          <div className="p-3" id="alltable-size">
            {showLoader && (
              <div className="customLoader">
                <div className="cust-loader"></div>
              </div>
            )}
            <Table
              rowSelection={
                storeRole !== process.env.REACT_APP_SROLE
                  ? {}
                  : {
                      type: "checkbox",
                      ...rowSelection,
                    }
              }
              columns={columns}
              dataSource={storeList}
              pagination={storePagination}
              onChange={(pagination) => {
                getRoleList("pagination", pagination);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Roles;
