import {
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Button,
  Table,
  Typography,
  Modal,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import APICaller from "../../utils/APICaller";
import moment from "moment";
import Select from "react-select";
import Column from "antd/es/table/Column";
import { CgCalendarDates } from "react-icons/cg";
import useCurrentPath, { decryptData } from "../../components/Constants";
import CustomLoader from "../../components/CustomLoader";

export default function AddExpense() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = useCurrentPath();

  const { RangePicker } = DatePicker;
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  let storeRole = useSelector((state) => state.auth.role);

  let storeToken = useSelector((state) => state.auth.accessToken);

  let storeLIUId = useSelector((state) => state.auth.lIUId);

  let storeUserInfo = useSelector((state) => state.auth.userInfo);
  storeUserInfo = decryptData(storeUserInfo);
  const storeEmpID = storeUserInfo?.user?.empID;
  const storeEmpName = storeUserInfo?.user?.name;

  const storeEmpDepartmentID = storeUserInfo?.user?.departmentID;
  const storeEmpDepartmentName = storeUserInfo?.userDepartment;

  // Initialize storeList with a default value
  const [storeList, setStoreList] = useState([]);
  const [images, setImages] = useState(null);

  const [expenseCodes, setExpenseCodes] = useState([]),
    [projectCodes, setProjectCodes] = useState([]),
    [departments, setDepartments] = useState([]),
    [errors, setErrors] = useState([]),
    [users, setUsers] = useState([]),
    [adminApprovers, setAdminApprovers] = useState([]);

  const [addExpenseValues, setAddExpenseValues] = useState(null);

  const [showLoader, setShowLoader] = useState(false);
  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );

  const handleOk = () => {
    if (!addExpenseValues) return;
    setIsModalVisible(false);
    setShowLoader(true);
    setErrors({ fileSizeExceed: "", fileCountExceed: false });
    const endpoint = "/pending-requests";
    const method = "POST";
    const payload = {
      empName: addExpenseValues.empName,
      empID: parseInt(storeEmpID),
      departmentID: storeEmpDepartmentID,
      expensePeriodFromDate: expenseValues?.expensePeriodFromDate,
      expensePeriodToDate: expenseValues?.expensePeriodToDate,
      businessPurpose: addExpenseValues.businessPurpose,
      projectCodeID: addExpenseValues.projectCodeID?.value,
      expensesDetails: rows,
      historySource: "backend",
      historyAction: "create",
      historyUserID: storeLIUId,
      status: process.env.REACT_APP_PENDING_STATUS.toUpperCase(),
    };

    if (adminApprovers.length > 0) {
      payload.adminSelectedApprover = expenseValues?.approvers?.value;
    }

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        message.success("Expense added successfully.");
        form.resetFields();
        form.setFieldsValue({
          empID: storeEmpID,
          empName: storeEmpName,
        });
        setExpenseValues({ ...expenseValues, advanceAmount: "" });
        setRows([
          {
            date: "",
            description: "",
            departmentID: "",
            expenseCodeID: "",
            cost: "",
            mediaInfo: [],
          },
        ]); // Reset rows to initial state
        setShowLoader(false);
        navigate(`/${process.env.REACT_APP_DASHBOARD_EL_SEARCH_IN}`);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // SetValue
  const [expenseValues, setExpenseValues] = useState({
    empName: "",
    empID: "",
    departmentID: "",
    expensePeriodFromDate: "",
    expensePeriodToDate: "",
    expenseEmpIDRole: "",
    businessPurpose: "",
    expensesDetails: "",
    subTotal: "",
    totalReimbursement: "",
  });
  const [rows, setRows] = useState([
    {
      date: "",
      description: "",
      departmentID: "",
      expenseCodeID: "",
      cost: "",
      mediaInfo: [],
    },
  ]);
  const [mediaInfoRows, setMediaInfoRows] = useState([
    {
      files: [],
    },
  ]);
  const parseToFloat = (value) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  };
  const subTotal = rows.reduce(
    (total, row) => total + parseToFloat(row.cost),
    0
  );

  const totalReimbursement = subTotal.toFixed(2);

  // Add Expense API
  const onFinish = (values) => {
    if (errors?.fileSizeExceed === "single") {
      message.error("File size exceeds 5 MB.");
    } else if (errors?.fileSizeExceed === "total") {
      message.error("The total size of all files must not exceed 10 MB.");
    } else if (errors?.fileCountExceed) {
      message.error(
        "You cannot only upload one attachment so upload accordingly."
      );
    } else if (totalReimbursement.indexOf("-") !== -1) {
      message.error(
        totalReimbursement +
          " amount is with you. If you have any concern then please contact with Finance Department!!"
      );
    } else {
      setAddExpenseValues(values);
      setIsModalVisible(true);
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setShowLoader(false);
  };

  // GetDepartments
  const getDepartments = () => {
    const endpoint = "/departments/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response &&
          response?.response?.map((department, index) => {
            temp.push({ label: department?.name, value: department?._id });
          });
        setDepartments(temp);
      })
      .catch((error) => {});
  };

  // GetExpenseCodes
  const getExpenseCodes = () => {
    const endpoint = "/expense-code/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response &&
          response?.response?.map((expenseCode, index) => {
            temp.push({
              label: expenseCode?.costCode + " - " + expenseCode?.name,
              value: expenseCode?._id,
            });
          });

        setExpenseCodes(temp);
      })
      .catch((error) => {});
  };

  // GetProjectCodes
  const getProjectCodes = () => {
    const endpoint = "/project-code/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [{ label: "N/A", value: null }];
        response &&
          response?.response?.map((projectCode, index) => {
            temp.push({
              label: projectCode?.code + " - " + projectCode?.companyName,
              value: projectCode?._id,
            });
          });

        setProjectCodes(temp);
      })
      .catch((error) => {});
  };

  // GetUsers
  const getUsers = () => {
    const endpoint = "/users/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response &&
          response?.response?.map((emp, index) => {
            temp.push({
              empID: emp?.empID,
              empName: emp?.name,
            });
          });
        setUsers(temp);
      })
      .catch((error) => {});
  };

  const getAdminApprovers = () => {
    const endPoint = "/findUserByExpenseCategory";
    const method = "POST";
    const payload = {
      category: process.env.REACT_APP_EXPENSE_CATEGORY_CAPEX,
    };

    APICaller(
      storeRole,
      storeToken,
      endPoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response &&
          response?.map((user) => {
            temp.push({
              label: user?.name,
              value: user?._id,
            });
          });

        setAdminApprovers(temp);
      })
      .catch((error) => {});
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        date: "",
        description: "",
        departmentID: "",
        expenseCodeID: "",
        cost: "",
        mediaInfo: [],
      },
    ]);
  };

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];

    if (field === "departmentID" || field === "expenseCodeID") {
      updatedRows[index][field] = value?.value;
    } else {
      updatedRows[index][field] = value;
    }

    setRows(updatedRows);
  };

  const handleRemoveRow = (indexToRemove) => {
    const updatedRows =
      rows && rows.filter((row, index) => index !== indexToRemove);
    setRows(updatedRows);
    // Convert date fields back to moment objects if they exist
    const updatedFields =
      updatedRows &&
      updatedRows.map((row) => ({
        ...row,
        date: row.date ? moment(row.date) : null,
      }));
    form.setFieldsValue({ rows: updatedFields });
  };

  const handleFileChange = (index, event) => {
    const files = event.target.files;
    if (files.length && files.length > 1) {
      setErrors({ fileCountExceed: true });
      message.error(
        `Only one file can be uploaded. Allowed file type: .jpg, .png, max size: 4MB.`
      );
    } else {
      setErrors({ fileCountExceed: false });

      let totalSize = 0;
      const maxTotalSize = 10 * 1024 * 1024; // 10 MB in bytes
      const maxSingleFileSize = 4 * 1024 * 1024; // 5 MB in bytes
      const fileSources = [];
      const updatedRows = [...rows];

      const arrayBufferToBase64 = (buffer) => {
        let binary = "";
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      };

      const loadFile = (file) => {
        const reader = new FileReader();
        const fileType = file.type;
        totalSize += file.size;

        if (file.size > maxSingleFileSize) {
          setErrors({ fileSizeExceed: "single" });
          message.error(`File "${file.name}" exceeds 4 MB.\n`);
        } else {
          reader.onload = (e) => {
            if (fileType.startsWith("image/")) {
              // Directly use the base64 data URL for images
              fileSources.push({
                src: e.target.result,
                name: file.name,
                type: fileType,
              });
            } else {
              // Convert ArrayBuffer to base64 for non-image files
              const base64Data = arrayBufferToBase64(e.target.result);
              fileSources.push({
                src: `data:${fileType};base64,${base64Data}`,
                name: file.name,
                type: fileType,
              });
            }

            if (fileSources.length <= 1) {
              updateRowFiles(index, fileSources, updatedRows);
            }
          };

          if (fileType.startsWith("image/")) {
            reader.readAsDataURL(file); // For images
          } else {
            reader.readAsArrayBuffer(file); // For non-image files
          }
        }
      };
      Array.from(files).forEach(loadFile);
    }
  };

  const updateRowFiles = (index, fileSources, updatedRows) => {
    if (!updatedRows[index].mediaInfo) {
      updatedRows[index].mediaInfo = [];
    }
    updatedRows[index].mediaInfo = fileSources;
    setRows(updatedRows);
  };

  const validationRules = {
    date: [{ required: true, message: "Please select a date" }],
    description: [{ required: true, message: "Please enter a description" }],
    departmentID: [{ required: true, message: "Please select a department" }],
    expenseCodeID: [
      { required: true, message: "Please select an expense code" },
    ],
    cost: [
      { required: true, message: "Please enter a cost" },
      {
        pattern: /^[0-9]*\.?[0-9]+$/,
        message: "Only numbers and decimals are allowed",
      },
    ],
    upload: [{ required: true, message: "Please choose a file" }],
  };

  const handleButtonClick = (index) => {
    document.getElementById(`fileInput${index}`).click();
  };

  useEffect(() => {
    getDepartments();
    getExpenseCodes();
    getProjectCodes();
    getUsers();
    if (storeRole === process.env.REACT_APP_ADROLE) {
      getAdminApprovers();
    }
    if (storeEmpID) {
      const currEmpID = parseInt(storeEmpID);
      const emp = users.find((emp) => emp.empID === currEmpID);

      const expenseEmpIDRole = emp ? emp.roleID?.name : "";

      setExpenseValues({
        ...expenseValues,
        expenseEmpIDRole: expenseEmpIDRole,
      });
      form.setFieldsValue({ empID: storeEmpID });
      form.setFieldsValue({ empName: storeEmpName });
      form.setFieldsValue({ departmentID: storeEmpDepartmentName });
    }
  }, []);

  // Disable dates after the current date
  const disableFutureDates = (current) => {
    const startDate = moment(current).subtract(1, "month").startOf("day");

    // Calculate the end date for the next financial year
    const currentYear = moment().year();
    const nextFinancialYearEnd = moment()
      .month(2)
      .date(31)
      .year(
        currentYear + (moment().isAfter(moment().month(2).date(31)) ? 1 : 0)
      )
      .endOf("day");

    // Disable dates outside the range two months ago from today to next financial year's 31st March
    return (
      current &&
      (current.isBefore(startDate) || current.isAfter(moment().endOf("day")))
    );
  };

  const validateFilePresence = (_, value) => {
    return rows.some((row) => row.mediaInfo && row.mediaInfo.length > 0)
      ? Promise.resolve()
      : Promise.reject(new Error("Please upload a file!"));
  };
  return (
    <>
      <div className="container">
        <CustomLoader showLoader={showLoader} />
        <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-0">
          <div className="border-2 card-header p-6">
            <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark font-size-lg">
              Add New Expense
            </h3>
          </div>
          <div className="card-body p-3">
            <Form
              layout="vertical"
              autoComplete="off"
              className="form-validation-start"
              onFinish={onFinish}
              form={form}
              onFinishFailed={onFinishFailed}
              initialValues={{ rows: [{ mediaInfo: [] }] }}
            >
              <Row gutter={[24, 24]}>
                <Col sm={8}>
                  <Form.Item
                    className="m-0"
                    label="Employee ID"
                    key="empID"
                    name="empID"
                    rules={[
                      {
                        pattern: /^[0-9]*\.?[0-9]+$/,
                        message:
                          "Alpbhabets and special symbols not allowing in Employee ID",
                      },
                    ]}
                  >
                    <Input
                      className="form-control h-45px rounded-md"
                      value={expenseValues?.empID}
                      disabled={expenseValues?.empID !== 1 ? "disabled" : ""}
                      placeholder="Employee ID"
                    />
                  </Form.Item>
                </Col>
                <Col sm={8}>
                  <Form.Item
                    className="m-0"
                    label="Employee Name"
                    key="empName"
                    name="empName"
                    rules={[
                      {
                        pattern: /^[^\d]*$/,
                        message: "Numbers are not allowed in the employee name",
                      },
                    ]}
                  >
                    <Input
                      className="form-control h-45px rounded-md"
                      placeholder="Employee Name"
                      disabled
                    />
                  </Form.Item>
                </Col>

                <Col sm={8}>
                  <Form.Item
                    className="m-0"
                    label="Department"
                    key="departmentID"
                    name="departmentID"
                  >
                    <Input
                      className="form-control h-45px rounded-md"
                      value={storeEmpDepartmentName}
                      disabled="disabled"
                      placeholder="Select Department"
                    />
                    {/* <Select
                      allowClear
                      className="rounded-md h-45px"
                      placeholder="Select Department"
                      options={departments}
                      value={expenseValues?.departmentID}
                      onChange={(selectedOption) =>
                        setExpenseValues({
                          ...expenseValues,
                          departmentID: selectedOption,
                        })
                      }
                    /> */}
                  </Form.Item>
                </Col>

                <Col sm={8}>
                  <Form.Item
                    className="m-0"
                    label="Expense Period: (From - To)"
                    key="expensePeriodFromDate"
                    name="expensePeriodFromDate"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Expense Period: (From - To)",
                      },
                    ]}
                  >
                    <RangePicker
                      className="form-control h-45px rounded-md d-inline-flex"
                      format="DD-MM-YYYY"
                      disabledDate={disableFutureDates}
                      onChange={(date, dates) => {
                        const formattedDates =
                          dates &&
                          dates.map((date) =>
                            moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")
                          );
                        if (
                          formattedDates &&
                          formattedDates[0] &&
                          formattedDates[1]
                        ) {
                          setExpenseValues({
                            ...expenseValues,
                            expensePeriodFromDate: formattedDates[0],
                            expensePeriodToDate: formattedDates[1],
                          });
                        }
                      }}
                    />
                  </Form.Item>
                </Col>

                {/* <Col sm={8}>
                  <Form.Item
                    className="m-0"
                    label="Advance Amount"
                    key="advanceAmount"
                    name="advanceAmount"
                    rules={[
                      {
                        pattern: /^[0-9]*\.?[0-9]+$/,
                        message:
                          "Alpbhabets and special symbols not allowing in Advance Amount",
                      },
                    ]}
                  >
                    <Input
                      placeholder="₹0000"
                      className="form-control h-45px rounded-md"
                      onChange={(e) => {
                        setExpenseValues({
                          ...expenseValues,
                          advanceAmount: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>

                {expenseValues?.advanceAmount && (
                  <Col sm={8}>
                    <Form.Item
                      className="m-0"
                      label="Advance Details"
                      key="advanceDetails"
                      name="advanceDetails"
                      rules={[
                        {
                          required: true,
                          message: "Please enter advance details",
                        },
                      ]}
                    >
                      <TextArea
                        placeholder="Advance Details"
                        className="form-control h-45px rounded-md"
                        autoSize={{
                          minRows: 2,
                          maxRows: 4,
                        }}
                        onChange={(e) => {
                          setExpenseValues({
                            ...expenseValues,
                            advanceDetails: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                )} */}

                <Col sm={8}>
                  <Form.Item
                    className="m-0"
                    label="Project Code"
                    key="projectCodeID"
                    name="projectCodeID"
                    rules={[
                      {
                        required: true,
                        message: "Please select project code",
                      },
                    ]}
                  >
                    <Select
                      className="h-45px rounded-md"
                      onChange={(value) => {
                        setExpenseValues({
                          ...expenseValues,
                          projectCodeID: value,
                        });
                      }}
                      options={projectCodes}
                    />
                  </Form.Item>
                </Col>
                {storeRole === process.env.REACT_APP_ADROLE && (
                  <Col sm={8}>
                    <Form.Item
                      className="m-0"
                      label="Approvers"
                      key="approvers"
                      name="approvers"
                      rules={[
                        {
                          required: true,
                          message: "Please select approver",
                        },
                      ]}
                    >
                      <Select
                        className="h-45px rounded-md"
                        onChange={(value) => {
                          setExpenseValues({
                            ...expenseValues,
                            approvers: value,
                          });
                        }}
                        options={adminApprovers}
                      />
                    </Form.Item>
                  </Col>
                )}
                <Col sm={8}>
                  <Form.Item
                    className="m-0"
                    label="Business Purpose"
                    key="businessPurpose"
                    name="businessPurpose"
                    rules={[
                      {
                        required: true,
                        message: "Please enter business purpose ",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Business Purpose"
                      className="form-control rounded-md"
                      autoSize={{
                        minRows: 2,
                        maxRows: 4,
                      }}
                      onChange={(e) => {
                        setExpenseValues({
                          ...expenseValues,
                          businessPurpose: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="main-repeater mt-10 ">
                <Table
                  className="table"
                  dataSource={rows}
                  rowKey={(record) => record.id}
                  pagination={false}
                >
                  <Column
                    className="w-120px"
                    title={
                      <>
                        {validationRules.date.some((rule) => rule.required) && (
                          <span className="expenseAddRowRequiredSymbol"></span>
                        )}
                        Date
                      </>
                    }
                    dataIndex="date"
                    key="date"
                    render={(text, record, index) => (
                      <Form.Item
                        key={index}
                        name={["rows", index, "date"]}
                        rules={validationRules.date}
                      >
                        <DatePicker
                          disabledDate={disableFutureDates}
                          format="DD-MM-YYYY"
                          value={record.date ? moment(record.date) : null}
                          placeholder="Select Date"
                          className="form-control h-45px rounded-md"
                          onChange={(date) => {
                            const formattedDate = date.format("YYYY-MM-DD");

                            handleChange(index, "date", formattedDate);
                          }}
                        />
                      </Form.Item>
                    )}
                  />

                  <Column
                    className="w-120px"
                    title={
                      <>
                        {validationRules.description.some(
                          (rule) => rule.required
                        ) && (
                          <span className="expenseAddRowRequiredSymbol"></span>
                        )}
                        Description
                      </>
                    }
                    dataIndex="description"
                    key="description"
                    render={(text, record, index) => (
                      <Form.Item
                        key={index}
                        name={["rows", index, "description"]}
                        rules={validationRules.description}
                      >
                        <Input
                          value={record.description}
                          className="form-control h-45px rounded-md"
                          onChange={(e) =>
                            handleChange(index, "description", e.target.value)
                          }
                        />
                      </Form.Item>
                    )}
                  />
                  <Column
                    className="w-120px text-capitalize"
                    title={
                      <>
                        {validationRules.departmentID.some(
                          (rule) => rule.required
                        ) && (
                          <span className="expenseAddRowRequiredSymbol"></span>
                        )}
                        Department
                      </>
                    }
                    dataIndex="departmentID"
                    key="departmentID"
                    render={(text, record, index) => (
                      <Form.Item
                        key={index}
                        name={["rows", index, "departmentID"]}
                        rules={validationRules.departmentID}
                      >
                        <Select
                          value={record.departmentID}
                          onChange={(value) =>
                            handleChange(index, "departmentID", value)
                          }
                          className="h-45px rounded-md"
                          options={departments}
                        />
                      </Form.Item>
                    )}
                  />
                  <Column
                    className="w-120px text-capitalize"
                    title={
                      <>
                        {validationRules.expenseCodeID.some(
                          (rule) => rule.required
                        ) && (
                          <span className="expenseAddRowRequiredSymbol"></span>
                        )}
                        Expense Code
                      </>
                    }
                    dataIndex="expenseCodeID"
                    key="expenseCodeID"
                    render={(text, record, index) => (
                      <Form.Item
                        key={index}
                        name={["rows", index, "expenseCodeID"]}
                        rules={validationRules.expenseCodeID}
                      >
                        <Select
                          value={record.expenseCodeID}
                          onChange={(value) =>
                            handleChange(index, "expenseCodeID", value)
                          }
                          className="h-45px rounded-md"
                          options={expenseCodes}
                        />
                      </Form.Item>
                    )}
                  />

                  <Column
                    className="w-120px"
                    title={
                      <>
                        {validationRules.cost.some((rule) => rule.required) && (
                          <span className="expenseAddRowRequiredSymbol"></span>
                        )}
                        Cost
                      </>
                    }
                    dataIndex="cost"
                    key="cost"
                    render={(text, record, index) => (
                      <Form.Item
                        key={index}
                        name={["rows", index, "cost"]}
                        rules={validationRules.cost}
                      >
                        <Input
                          type="text"
                          className="form-control h-45px rounded-md"
                          value={record.cost}
                          onChange={(e) =>
                            handleChange(index, "cost", e.target.value)
                          }
                        />
                      </Form.Item>
                    )}
                  />
                  <Column
                    className="w-90px"
                    title={
                      <>
                        {/* {validationRules.upload.some(
                          (rule) => rule.required
                        ) && (
                          <span className="expenseAddRowRequiredSymbol"></span>
                        )} */}
                        Upload
                      </>
                    }
                    dataIndex="upload"
                    key="upload"
                    render={(text, record, index) => (
                      <Form.Item key={index} name={["rows", index, "upload"]}>
                        <input
                          id={`fileInput${index}`}
                          type="file"
                          accept=".png, .jpg, .jpeg, .pdf, .docx, .doc"
                          onChange={(event) => {
                            handleFileChange(index, event);
                          }}
                          style={{ display: "none" }}
                        />
                        <Button
                          type="primary"
                          onClick={() => handleButtonClick(index)}
                          className="redbutton mx-auto d-block"
                        >
                          Upload
                        </Button>

                        {record.mediaInfo && record.mediaInfo.length > 0 ? (
                          <Typography.Text>
                            {record.mediaInfo.length} file(s) attached
                          </Typography.Text>
                        ) : (
                          <Typography.Text>
                            Only one file can be uploaded. Allowed file type:
                            .jpg, .png, max size: 4MB.
                          </Typography.Text>
                        )}
                      </Form.Item>
                    )}
                  />
                  {rows?.length > 1 && (
                    <Column
                      className="w-90px text-center"
                      title="Actions"
                      key="actions"
                      render={(text, record, index) => (
                        <>
                          <i
                            className="flaticon-delete icon-lg text-danger cursor-pointer"
                            onClick={() => handleRemoveRow(index)}
                          ></i>
                        </>
                      )}
                    />
                  )}
                </Table>
                <Button
                  type="dashed"
                  onClick={handleAddRow}
                  className="h-auto btn d-block font-size-lg font-weight-bold mx-auto px-5 redbutton rounded-lg text-white"
                  style={{ marginTop: 16 }}
                >
                  <i className="flaticon2-plus icon-md mr-1 text-white"></i> Add
                  More
                </Button>
              </div>
              <Row>
                <Col xs={24}>
                  <div className="border-top mb-5 mt-10"></div>
                </Col>

                {/* <Col xs={24}>
                  <p className="font-size-h5 font-weight-boldest m-0 ml-auto text-uppercase text-right">
                    TOTAL: ₹ {subTotal}
                  </p>
                </Col> */}

                {/* <Col xs={24}>
                  <p className="font-size-h5 font-weight-boldest m-0 ml-auto text-uppercase text-right">
                    Advance: ₹ {advance}
                  </p>
                </Col>

                 */}
                <Col xs={24}>
                  <p className="font-size-h5 font-weight-boldest m-0 ml-auto text-uppercase text-right">
                    Total Reimbursement: ₹ {totalReimbursement}
                  </p>
                </Col>
                <Col xs={24}>
                  <div className="border-top mt-5"></div>
                </Col>
              </Row>

              <div className="text-right mt-5">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="h-auto btn d-block font-size-h5 font-weight-bold mx-auto my-8 px-10 py-3 redbutton rounded-lg text-white"
                >
                  Submit
                </Button>
              </div>

              <Modal
                title="Confirm Expense"
                open={isModalVisible}
                onCancel={handleCancel}
                footer={[
                  <div className="addExpenseConfirmationModalFooter">
                    <Button
                      key="submit"
                      className="okSbmit submit-btn border-0 rounded"
                      onClick={handleOk}
                    >
                      Yes
                    </Button>
                    <div className={`loader`}></div>
                  </div>,
                  <Button
                    key="back"
                    className="cancelSbmit submit-btn border-0 rounded"
                    onClick={handleCancel}
                  >
                    No
                  </Button>,
                ]}
              >
                <p>
                  Please review your selected or entered information carefully
                  before submitting the form, as changes may not be possible
                  once it's submitted.
                </p>
              </Modal>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
