import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  Table,
  message,
  Button,
  Select,
  Checkbox,
  InputNumber,
  Typography,
  Popconfirm,
} from "antd";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import HandleSearch from "../../utils/CommonSearchComponent";
import APICaller from "../../utils/APICaller";
import useCurrentPath, { decryptData } from "../../components/Constants";
import { store } from "../../store";
import CustomLoader from "../../components/CustomLoader";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  roles,
  departments,
  ...restProps
}) => {
  let inputNode;
  if (dataIndex === "role") {
    inputNode = <Select options={roles} />;
  } else if (dataIndex === "department") {
    inputNode = <Select options={departments} />;
  } else {
    inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Users = () => {
  const location = useLocation();
  const currentPath = useCurrentPath();
  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();

  //Search Filter
  const { Search } = Input;
  const [searchText, setSearchText] = useState("");

  const [canSendExpenseRequest, setCanSendExpenseRequest] = useState();

  let storeRole = useSelector((state) => state.auth.role);

  let storeToken = useSelector((state) => state.auth.accessToken);

  let storeLIUId = useSelector((state) => state.auth.lIUId);

  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );
  const [storeList, setStoreList] = useState([]);
  const storePagination = useSelector((state) => state.auth.pagination);

  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [list, setList] = useState([]),
    [selectedRowKeys, setSelectedRowKeys] = useState([]),
    [departments, setDepartments] = useState([]),
    [roles, setRoles] = useState([]),
    [managers, setManagers] = useState([]),
    [selectedRole, setSelectedRole] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [collapseClass, setCollapseClass] = useState("");

  const [profilePicInfo, setProfilePicInfo] = useState("");
  const [profilePicWithUrlChecked, setProfilePicWithUrlChecked] =
    useState(false);

  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    tableForm.setFieldsValue({
      role: "",
      name: "",
      email: "",
      createdAt: "",
      updatedAt: "",
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await tableForm.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");

        const endpoint = "/users/" + key;
        const method = "PUT";
        const payload = {
          empID: newData[index]?.empID,
          name: newData[index]?.name,
          email: newData[index]?.email,
          status: newData[index]?.status,
          historySource: "backend",
          historyAction: "update",
          historyUserID: storeLIUId,
        };

        // Only include roleID if it has been edited
        if (newData[index]?.role !== item?.role) {
          payload.roleID = newData[index]?.role;
        }

        APICaller(
          storeRole,
          storeToken,
          endpoint,
          method,
          payload,
          storeAccessTokenExpiresOn
        )
          .then((response) => {
            message.success("User updated successfully.");
            tableForm.resetFields();
            getUserList();
          })
          .catch((error) => {});
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const handleCheckboxChange = (e) => {
    setProfilePicWithUrlChecked(e.target.checked);
  };

  const inputComponent = <Input className="w-100 p-2 rounded-2" />;

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    // Read image parameters
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        // Check for SVG file type
        const isSVG = file.type === "image/svg+xml";
        img.src && setProfilePicInfo(img.src);
      };
    };

    reader.readAsDataURL(file);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  // Filter the storeList based on the searchText
  const searchFilterData =
    storeList &&
    storeList.filter((item) => {
      const role = item.role?.name ? item.role?.name.toLowerCase() : "";
      const empID = item.empID ? item.empID : "";
      const name = item.name ? item.name.toLowerCase() : "";
      const email = item.email ? item.email : "";
      const department = item.department ? item.department.toLowerCase() : "";
      const status = item.status ? item.status.toLowerCase() : "";

      return (
        role.includes(searchText.toLowerCase()) ||
        empID === Number(searchText) ||
        name.includes(searchText.toLowerCase()) ||
        email.includes(searchText) ||
        department.includes(searchText.toLowerCase()) ||
        status.includes(searchText.toLowerCase())
      );
    });

  const [selectionType, setSelectionType] = useState("checkbox");

  /*begin::getRoles will fetch the list of Roles*/
  const getRoles = () => {
    const endpoint = "/roles/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response?.response?.map((role, index) => {
          temp.push({ label: role?.name, value: role?._id });
        });
        setRoles(temp);
      })
      .catch((error) => {});
  };
  /*end::getRoles will fetch the list of Roles*/

  /*begin::getManagers will fetch the list of Managers*/
  const getManagers = () => {
    const endpoint = "/users/managers";
    const method = "GET";
    const payload = {};
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response?.response?.map((manager, index) => {
          temp.push({ label: manager?.name, value: manager?._id });
        });
        setManagers(temp);
      })
      .catch((error) => {});
  };
  /*end::getManagers will fetch the list of Managers*/

  /*begin::getDepartments will fetch the list of Departments*/
  const getDepartments = () => {
    const endpoint = "/departments/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response?.response?.map((department, index) => {
          temp.push({ label: department?.name, value: department?._id });
        });
        setDepartments(temp);
      })
      .catch((error) => {});
  };
  /*end::getDepartments will fetch the list of Departments*/
  useEffect(() => {
    getUserList();
    getRoles();
    getDepartments();
    getManagers();
  }, []);

  const columns = [],
    additionalColumns = [];
  if (storeRole !== process.env.REACT_APP_SROLE) {
    columns.push({
      title: "Sr. No.",
      render: (text, record, index) => index + 1,
    });
  }
  const commonColumns = [
    {
      title: "Role",
      dataIndex: "role",
      width: "15%",
      editable: true,
      render: (text) => <>{text}</>,
    },
    {
      title: "Emp. ID",
      dataIndex: "empID",
      width: "7%",
      render: (text) => <>{text}</>,
    },
    {
      title: "Name",
      dataIndex: "name",
      width: "10%",
      editable: true,
      render: (text) => <>{text}</>,
    },
    {
      title: "Email",
      dataIndex: "email",
      width: "15%",
      editable: true,
      render: (text) => <>{text}</>,
    },
    {
      title: "Department",
      dataIndex: "department",
      width: "10%",
      render: (text) => <>{text}</>,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "10%",
      editable: true,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Created At",
      width: "11%",
      dataIndex: "createdAt",
    },
  ];

  if (
    storeRole === process.env.REACT_APP_SROLE ||
    storeRole === process.env.REACT_APP_ADROLE
  ) {
    additionalColumns.push(
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        width: "11%",
      },
      {
        title: "Action",
        dataIndex: "action",
        width: "10%",
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
          );
        },
      }
    );
  }

  columns.push(...commonColumns, ...additionalColumns);

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        roles: roles,
        departments: departments,
      }),
    };
  });

  //Add User
  const onFinish = (userValues) => {
    setShowLoader(true);
    const headers = {
      "Content-Type": "multipart/form-data", // Make sure to set the content type
    };
    const getBase64ImageExtension = (urlOrBase64) => {
      // Check if the input is a Base64 string
      if (urlOrBase64.startsWith("data:image")) {
        // Extract the MIME type from the Base64 string
        const mimeTypeMatch = urlOrBase64.match(
          /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/
        );
        if (mimeTypeMatch && mimeTypeMatch[1]) {
          // Extract the file extension from the MIME type
          const extension = mimeTypeMatch[1].split("/")[1];
          return extension;
        } else {
          // Default to empty string if no extension found
          return "";
        }
      } else {
        // Extract the file extension from the URL
        const ext = urlOrBase64.split(".").pop().split("?")[0];
        return ext;
      }
    };
    const endpoint = "/users";
    const method = "POST";
    const payload = {
      auth: storeToken,
      roleID: userValues?.role,
      empID: parseInt(userValues?.empID),
      name: userValues?.name,
      email: userValues?.email,
      departmentID: userValues?.department,
      imageName:
        parseInt(userValues?.empID) +
        "_" +
        userValues?.name?.replaceAll(" ", "_"),
      uploadType: profilePicWithUrlChecked === true ? "thumbUrl" : "other",
      profilePicUrl:
        profilePicWithUrlChecked === true
          ? userValues?.profilePicUrl
          : profilePicInfo
          ? profilePicInfo
          : null,
      ext:
        profilePicWithUrlChecked === true
          ? getBase64ImageExtension(userValues?.profilePicUrl)
          : profilePicInfo
          ? getBase64ImageExtension(profilePicInfo)
          : "", //values?.profilePicUrl?.split(".").pop()
      //createdAt: new Date().toISOString(),
      canSendExpenseRequest: canSendExpenseRequest,
      expenseCatID: userValues?.expenseCatID,
      historySource: "backend",
      historyAction: "create",
      historyUserID: storeLIUId,
    };
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn,
      headers
    )
      .then((response) => {
        setCollapseClass("hide");
        message.success("User added successfully.");
        form.resetFields();
        getUserList();
        getManagers();
        setShowLoader(false);
      })
      .catch((error) => {
        // if (error.response.data) {
        //   const errorData= error.response.data && JSON.parse(error.response.data)
        //   message.error(errorData.message);
        // }else if (error.data && error.data.message) {
        //   message.error(error.data.message+",ddd");
        // } else {
        //   message.error("An error occurred while creating the user");
        // }
        setShowLoader(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setShowLoader(false);
  };

  const getUserList = async (source = "", pagination = "") => {
    setShowLoader(true);

    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    setSelectedRowKeys([]);
    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
      fetchWithoutPagination: "yes",
    };

    await HandleSearch(
      storeRole,
      storeToken,
      storeLIUId,
      storeAccessTokenExpiresOn,
      searchIn,
      callActionFrom,
      allStates,
      ""
    );
    // Update local state after fetching data
    const fetchedData = store.getState().auth.list[currentPath + "_info"];
    //const fetchedData = data ? decryptData(data) : [];

    setStoreList(fetchedData);

    // Update data state after fetching
    setData(fetchedData);
    setShowLoader(false);
  };

  return (
    <>
      <div className="container">
        <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-0">
          <div className="border-2 card-header p-5">
            <div className="align-items-center d-flex justify-content-between">
              <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark text-size-16">
                Users
              </h3>

              <button
                className="btn font-size-lg font-weight-bold py-2 redbutton rounded-lg text-white collapsed"
                data-toggle="collapse"
                href="#newUser"
                role="button"
                aria-expanded="false"
                aria-controls="collapseContent"
              >
                <i className="flaticon2-plus mr-2 text-white"></i> Add New User
              </button>
            </div>
          </div>

          {/**Add New User Start**/}
          <div id="newUser" className={`collapse card-body ${collapseClass}`}>
            <div className="border border-light-dark p-4 m-0">
              <Form
                layout="vertical"
                autoComplete="off"
                className="form-validation-start"
                onFinish={onFinish}
                form={form}
                onFinishFailed={onFinishFailed}
              >
                <Row gutter={[24, 24]}>
                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Role"
                      name="role"
                      rules={[
                        {
                          required: true,
                          message: "Please enter role",
                        },
                      ]}
                    >
                      <Select
                        className="rounded-md h-45px"
                        isclearable="true"
                        placeholder="Select Role"
                        status=""
                        value=""
                        options={roles}
                        onChange={(val, data) => {
                          setSelectedRole(data?.label);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  {selectedRole === process.env.REACT_APP_AROLE && (
                    <Col sm={12}>
                      <Form.Item
                        className="m-0"
                        label="Can send an Expense Request?"
                        name="canSendExpenseRequest"
                        rules={[
                          {
                            required: true,
                            message: "Please select an option",
                          },
                        ]}
                      >
                        <Select
                          className="rounded-md h-45px"
                          isclearable="true"
                          placeholder="Select an option"
                          status=""
                          value=""
                          options={[
                            { label: "Yes", value: true },
                            { label: "No", value: false },
                          ]}
                          onChange={(val) => {
                            setCanSendExpenseRequest(val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}

                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Employee ID"
                      name="empID"
                      rules={[
                        {
                          required: true,
                          message: "Please input employee id",
                        },
                      ]}
                    >
                      <InputNumber
                        placeholder="Enter the employee id"
                        className="form-control h-45px rounded-md pt-2 px-2"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please input name",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter the name"
                        className="form-control h-45px rounded-md"
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Email"
                      name="email"
                      rules={[
                        { required: true, message: "Please input your email!" },
                        {
                          type: "email",
                          message: "Please enter a valid email address",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Enter the email"
                        className="form-control h-45px rounded-md"
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Department"
                      name="department"
                      rules={[
                        {
                          required: true,
                          message: "Please enter department",
                        },
                      ]}
                    >
                      <Select
                        mode={"multiple"}
                        className="rounded-md h-45px"
                        isclearable="true"
                        placeholder="Select Department"
                        status=""
                        value=""
                        allowClear
                        options={departments}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Expense Category"
                      name="expenseCatID"
                    >
                      <Select
                        className="rounded-md h-45px"
                        isclearable="true"
                        placeholder="Select an option"
                        status=""
                        value=""
                        options={[
                          { label: "OPEX", value: "OPEX" },
                          { label: "CAPEX", value: "CAPEX" },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={12}>
                    <Form.Item label="Profile Image" name="profilePicUrl">
                      {profilePicWithUrlChecked === true ? (
                        inputComponent
                      ) : (
                        <input
                          type="file"
                          onChange={handleFileChange}
                          className="upload-list-block"
                        />
                      )}
                    </Form.Item>
                    <Form.Item label="" name="profilePicUrl">
                      <Checkbox
                        checked={profilePicWithUrlChecked}
                        onChange={handleCheckboxChange}
                        className="form-label-antd profilePicWithUrlCheckBox"
                        name="profilePicWithUrlCheckBox"
                      >
                        Upload with url
                      </Checkbox>
                    </Form.Item>
                  </Col>

                  <Col sm={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn font-size-lg font-weight-bold ml-auto d-block py-2 redbutton rounded-lg text-white h-auto"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>

            <hr className="mt-10" />
          </div>
          {/**Add New User End**/}

          <div className="card-body">
            <div className="table-responsive border">
              <CustomLoader showLoader={showLoader} />
              <Search
                className="table-search"
                placeholder="Search Users Name & Code.."
                onSearch={(value) => setSearchText(value)}
                onChange={(e) => setSearchText(e.target.value)}
              />
              <Form form={tableForm} component={false}>
                <Table
                  rowSelection={
                    storeRole !== process.env.REACT_APP_SROLE
                      ? ""
                      : {
                          type: selectionType,
                          ...rowSelection,
                        }
                  }
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={searchFilterData}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
