import React, { useEffect, useMemo, useState } from "react";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import { JsonToExcel } from "react-json-to-excel";
import useCurrentPath, { decryptData } from "../../components/Constants";
import ExpenseMultitasking from "../../components/ExpenseMultitasking";
import moment from "moment";

export default function PendingRequests() {
  const { MonthPicker } = DatePicker;
  const { RangePicker } = DatePicker;
  const currentPath = useCurrentPath();
  const storeList = useSelector(
    (state) => state.auth.list?.[currentPath + "_info"] || []
  );

  // Memoize filteredList
  const filteredList = useMemo(() => {
    //const decrypted = decryptData(storeList);

    // Ensure decrypted is an array before calling map
    if (Array.isArray(storeList)) {
      return storeList.map(({ key, id, ...rest }) => rest);
    }

    // Return an empty array if decryption fails or result is not an array
    return [];
  }, [storeList]);

  const [filteredData, setFilteredData] = useState([]);
  const [isFilteredDates, setIsFilteredDates] = useState(false);
  const [filterValues, setFilterValues] = useState(null);

  const onDateChange = (dates, dateStrings) => {
    setFilterValues(dates ? dateStrings : null);
    setIsFilteredDates(true);
  };

  const handleMonthChange = (monthVal) => {
    setFilterValues(monthVal);
    setIsFilteredDates(true);
  };

  const startMonth = moment("2023-11", "YYYY-MM");
  const endMonth = moment();
  const disabledDate = (current) => {
    return (
      current &&
      (current < startMonth.startOf("month") ||
        current > endMonth.endOf("month"))
    );
  };

  // Callback to get filtered data from ExpenseMultitasking
  const handleFilteredData = (data) => {
    setFilteredData(data);
  };
  // Use useEffect to update filteredData when filteredList changes
  useEffect(() => {
    if (!isFilteredDates && filteredData !== filteredList) {
      setFilteredData(filteredList);
    }
  }, [filteredList, isFilteredDates]);

  // Transforming the data and adding custom fields
  const transformedData =
    filteredData &&
    filteredData.map((item) => {
      const additional =
        item &&
        item.approvers.map((sbitem) => {
          const statusMessage =
            sbitem.status === process.env.REACT_APP_PENDING_STATUS.toUpperCase()
              ? "Pending Approval: Request Sent"
              : sbitem.status
              ? sbitem.status
              : "Awaiting previous Approver's Approval";

          return `${sbitem.additional.name} (${statusMessage})`;
        });
      return {
        ...item,
        approvers: additional.join(","), // Adding a custom field
      };
    });
  return (
    <>
      <div className="container">
        <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-5">
          <div className="d-flex justify-content-between">
            <h3 className="font-weight-boldest text-dark text-size-16 m-0">
              Pending Requests
              <span
                className="label label-dark ml-2 text-white font-weight-bold w-30px h-30px"
                style={{ fontSize: "11px" }}
              >
                {filteredData ? filteredData.length : 0}
              </span>
            </h3>
            <div className="card-toolbar d-flex m-0">
              <div className="bg-gray-200 border border-light-dark mr-5 pl-3 rounded text-dark-custom">
                <span className="font-weight-bolder m-0 mr-3">Filter</span>
                {/* <RangePicker
                  className="border-0 h-100 rangepicker"
                  onChange={onDateChange}
                /> */}
                <MonthPicker
                  disabledDate={disabledDate}
                  placeholder="Select Month"
                  onChange={handleMonthChange}
                  className="border-0 h-100 rangepicker"
                />
              </div>
              <JsonToExcel
                title={
                  <>
                    <i className="fas fa-file-download mr-1"></i>Export Excel
                  </>
                }
                data={transformedData}
                fileName="pending-requests"
                btnClassName="border border-light-dark btn font-weight-bolder px-4 py-2 text-dark-custom bg-transparent export-excel-button"
              />
            </div>
          </div>

          <div className="table-area mt-10">
            <div className="table-responsive" id="alltable-size">
              <ExpenseMultitasking
                recordLimit={"50"}
                status={[
                  process.env.REACT_APP_PENDING_STATUS.toUpperCase(),
                  process.env.REACT_APP_NORESPONSE_STATUS.replace(
                    " ",
                    ""
                  ).toUpperCase(),

                  process.env.REACT_APP_RESUBMITTED_STATUS.toUpperCase(),
                ]}
                showLoaderOnList="yes"
                isFilterSelected={filterValues}
                onFilteredData={handleFilteredData}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
