import axios from "axios";
import { message } from "antd";
import { store } from "../store";
import { clearRedux } from "../store/actions";

const mainDomain = process.env.REACT_APP_BACKEND;

const APICaller = async (
  role,
  token,
  endpoint,
  method,
  payload,
  tokenExpiresOn = new Date().getTime() - 60000,
  //tokenExpiresOn,
  responseType = "json"
) => {
  try {
    //const { instance } = useMsal();
    const currentTime = new Date().getTime();

    /*if (tokenExpiresOn && currentTime >= tokenExpiresOn) {
      message.error(
        "Due to inactivity, your session has expired. Please log in again to continue.",
        2
      );
      // Dispatch reset action to clear Redux state
      store.dispatch(clearRedux());
      // Log the user out
      window.location.href = "/";

      return;
    } else {*/
      const response = await axios({
        url: `${mainDomain}${endpoint}`,
        method: method || "GET",
        data: payload,
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
          role: role,
        },
        responseType: responseType,
      });

      let data = response.data;

      return data;
    //}
  } catch (error) {
    handleErrorResponse(error, token, role);
    throw error;
  }
};

// Centralized error handling function
const handleErrorResponse = (error, token, role) => {
  let errorMsg = "";

  // Case: No server response
  if (!error.response) {
    if (token && role !== "") {
      errorMsg = "Unable to connect to the server. Please sign-in later.";
    } else {
      errorMsg = "The server is currently offline. Please try again later.";
    }
    if (Array.isArray(errorMsg)) {
      // If it's an array (multiple validation errors), join with a new line for readability
      showError(errorMsg.join("\n"), "yes"); // Call a function to show the error in Ant Design's message component
    } else {
      // If it's a string, display as-is
      showError(errorMsg);
    }
  } else {
    // Case: Server responded with error
    const { statusCode, message: errorMessage } =
      error.response.data || typeof error.response.data === "string"
        ? error.response.data && JSON.parse(error.response.data)
        : {};

    if (error.response.status === 404) {
      errorMsg = errorMessage || "Resource not found.";
    } else if (error.response.status === 500) {
      errorMsg = errorMessage || "Internal server error. Please try again.";
    } else if (error.response.data && typeof error.response.data === "string") {
      try {
        const parsedError =
          error.response.data && JSON.parse(error.response.data);
        errorMsg = parsedError.message || "An error occurred.";
      } catch (e) {
        errorMsg = error.response.data;
      }
    } else {
      errorMsg = errorMessage || "An unexpected error occurred.";
    }
    if (Array.isArray(errorMsg)) {
      // If it's an array (multiple validation errors), join with a new line for readability
      showError(errorMsg.join("\n"), "yes"); // Call a function to show the error in Ant Design's message component
    } else {
      // If it's a string, display as-is
      showError(errorMsg);
    }
  }
};

// Helper function to show Ant Design error message
const showError = (errorMsg, moreThan = "no") => {
  message.error({
    content: (
      <div style={{ whiteSpace: "pre-line" }}>
        {moreThan === "yes" ? (
          <strong>Errors occurred:</strong>
        ) : (
          <strong>An error occurred:</strong>
        )}
        <p>{errorMsg}</p> {/* This ensures line breaks are respected */}
      </div>
    ),
    duration: 5, // duration in seconds
    className: "error-message", // Add any custom styles you need
  });
};
export default APICaller;
