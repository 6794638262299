import React, { useState, useEffect } from "react";
import {
  Col,
  Form,
  Input,
  Row,
  Button,
  message,
  Table,
  InputNumber,
  Typography,
  Popconfirm,
} from "antd";
import { useSelector } from "react-redux";
import APICaller from "../../utils/APICaller";
import HandleSearch from "../../utils/CommonSearchComponent";
import { useLocation } from "react-router-dom";
import useCurrentPath, { decryptData } from "../../components/Constants";
import { store } from "../../store";
import CustomLoader from "../../components/CustomLoader";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
export default function ExpenseCode() {
  const { Search } = Input;
  const [searchText, setSearchText] = useState("");

  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const location = useLocation();
  const currentPath = useCurrentPath();

  let storeLIUId = useSelector((state) => state.auth.lIUId);
  //storeLIUId = storeLIUId ? decryptData(storeLIUId) : null;

  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );
  let storeRole = useSelector((state) => state.auth.role);
  //storeRole = storeRole ? decryptData(storeRole) : null;

  let storeToken = useSelector((state) => state.auth.accessToken);
  //storeToken = storeToken ? decryptData(storeToken) : null;

  // Initialize storeList with a default value
  const [storeList, setStoreList] = useState([]);
  const [data, setData] = useState([]);
  const storeTotalCount = useSelector((state) => state.auth.totalCount);

  const [showLoader, setShowLoader] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [collapseClass, setCollapseClass] = useState("");
  const [list, setList] = useState([]),
    [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });

  const [addExpenseCode, setAddExpenseCode] = useState({
    name: "",
    costCode: "",
    history: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });

  {
    /*getExpenseCodeList*/
  }
  const getExpenseCodeList = async (source = "", pagination = "") => {
    setShowLoader(true);

    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    setSelectedRowKeys([]);
    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
      fetchWithoutPagination: "yes",
    };

    await HandleSearch(
      storeRole,
      storeToken,
      storeLIUId,
      storeAccessTokenExpiresOn,
      searchIn,
      callActionFrom,
      allStates,
      ""
    );

    // Ensure storeList gets updated with the latest data
    const fetchedData = store.getState().auth.list[currentPath + "_info"];
    //const fetchedData = data ? decryptData(data) : [];

    setStoreList(fetchedData);

    // Update data state after fetching
    setData(fetchedData);
    setShowLoader(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      await getExpenseCodeList();
    };
    fetchData();
  }, [storeLIUId, storeRole, storeToken, location.pathname]);

  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    tableForm.setFieldsValue({
      name: "",
      costCode: "",
      createdAt: "",
      updatedAt: "",
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey("");
  };
  const save = async (key) => {
    try {
      const row = await tableForm.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");

        const endpoint = "/expense-code/" + key;
        const method = "PUT";
        const payload = {
          name: newData[index]?.name,
          costCode: newData[index]?.costCode,
          historySource: "backend",
          historyAction: "update",
          historyUserID: storeLIUId,
        };
        APICaller(
          storeRole,
          storeToken,
          endpoint,
          method,
          payload,
          storeAccessTokenExpiresOn
        )
          .then((response) => {
            message.success("Expense Code updated successfully.");
            tableForm.resetFields();
            getExpenseCodeList();
          })
          .catch((error) => {});
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };
  const columns = [],
    additionalColumns = [];
  if (storeRole !== process.env.REACT_APP_SROLE) {
    columns.push({
      title: "Sr. No.",
      dataIndex: "srno",
      width: "10%",
      render: (text, record, index) => index + 1,
    });
  }
  const commonColumns = [
    {
      title: "Expense Name",
      dataIndex: "name",
      width: "35%",
      editable: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Expense Code",
      dataIndex: "costCode",
      width: "15%",
      editable: true,
      sorter: (a, b) => a.costCode.localeCompare(b.costCode),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: "20%",
    },
  ];

  if (
    storeRole === process.env.REACT_APP_SROLE ||
    storeRole === process.env.REACT_APP_ADROLE
  ) {
    additionalColumns.push(
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        width: "20%",
      },
      {
        title: "Action",
        dataIndex: "action",
        width: "20%",
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
          );
        },
      }
    );
  }
  columns.push(...commonColumns, ...additionalColumns);

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const onFinish = (expenseValues) => {
    setShowLoader(true);
    const endpoint = "/expense-code";
    const method = "POST";
    const payload = {
      auth: storeToken,
      name: expenseValues?.name,
      costCode: expenseValues?.costCode,
      historySource: "backend",
      historyAction: "create",
      historyUserID: storeLIUId,
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        setCollapseClass("hide");
        message.success("Expense Code added successfully.");
        form.resetFields();
        getExpenseCodeList();
        setShowLoader(false);
      })
      .catch((error) => {
        // if (error.data && error.data.message) {
        //   message.error(error.data.message);
        // } else {
        //   message.error("An error occurred while creating the Project Code");
        // }
        setShowLoader(false);
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setShowLoader(false);
  };

  const searchFilterData =
    data &&
    data.filter((item) => {
      const name = item.name ? item.name.toLowerCase() : "";
      const costCode = item.costCode ? item.costCode.toLowerCase() : "";
      return (
        name.includes(searchText.toLowerCase()) ||
        costCode.includes(searchText.toLowerCase())
      );
    });

  return (
    <>
      <div className="container">
        <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-0">
          <div className="border-2 card-header p-5">
            <div className="align-items-center d-flex justify-content-between">
              <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark text-size-16">
                Expense Code
                <span
                  className="label label-dark ml-2 text-white font-weight-bold w-30px h-30px"
                  style={{ fontSize: "11px" }}
                >
                  {storeTotalCount ? storeTotalCount : 0}
                </span>
              </h3>

              <button
                className="btn font-size-lg font-weight-bold py-2 redbutton rounded-lg text-white collapsed"
                data-toggle="collapse"
                href="#newcode"
                role="button"
                aria-expanded="false"
                aria-controls="collapseContent"
              >
                <i className="flaticon2-plus mr-2 text-white"></i> Add New Code
              </button>
            </div>
          </div>

          {/**Add New Code Start**/}
          <div id="newcode" className={`collapse p-3 ${collapseClass}`}>
            <div className="border border-light-dark p-4 m-0">
              <Form
                layout="vertical"
                autoComplete="off"
                className="form-validation-start"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Row gutter={[24, 24]}>
                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Expense Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Please enter expense name",
                        },
                      ]}
                    >
                      <Input
                        className="h-45px rounded-md"
                        placeholder="Expense Name"
                        onChange={(e) => {
                          setAddExpenseCode({
                            ...addExpenseCode,
                            name: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={12}>
                    <Form.Item
                      className="m-0"
                      label="Expense Code"
                      name="costCode"
                      rules={[
                        {
                          required: true,
                          message: "Please enter expense code",
                        },
                      ]}
                    >
                      <Input
                        className="h-45px rounded-md"
                        placeholder="Expense Name"
                        onChange={(e) => {
                          setAddExpenseCode({
                            ...addExpenseCode,
                            costCode: e.target.value,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col sm={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="btn font-size-lg font-weight-bold ml-auto d-block py-2 redbutton rounded-lg text-white h-auto"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>

            <hr className="mt-10" />
          </div>
          {/**Add New Code End**/}

          <div id="alltable-size" className="p-3">
            <CustomLoader showLoader={showLoader} />
            <Search
              placeholder="Search Expense Name & Code"
              className="table-search"
              onSearch={(value) => setSearchText(value)}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Form form={tableForm} component={false}>
              <Table
                rowSelection={
                  storeRole !== process.env.REACT_APP_SROLE
                    ? ""
                    : {
                        type: selectionType,
                        ...rowSelection,
                      }
                }
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={searchFilterData}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
              />
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
