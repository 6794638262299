import React, { useRef, useState } from "react";
import axios from "axios";
import { message } from "antd";
import CustomLoader from "../components/CustomLoader";

const FileUpload = (props) => {
  const { role } = props;
  const [file, setFile] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const formRef = useRef(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setShowLoader(true);
    const formData = new FormData();
    formData.append("role", role);
    formData.append("model", event.target.model.value);
    formData.append("file", file);
    formData.append("fileName", file.name);
    const headers = {
      role: role,
      // "Content-Type": "multipart/form-data", // Make sure to set the content type
    };

    axios
      .post(process.env.REACT_APP_BACKEND + "/data/import", formData, {
        headers,
      })
      .then((response) => {
        setShowLoader(false);
        message.success("Data Imported successfully.");
        resetForm();
      })
      .catch((error) => {
        message.error("There was an error!" + error);
        setShowLoader(false);
      });
  };
  const handleReset = () => {
    setFile(null); // Clear the file state
    if (formRef.current) {
      formRef.current.reset(); // Reset the form fields
    }
  };
  const resetForm = () => {
    setFile(null);
    if (formRef.current) {
      formRef.current.reset();
    }
  };
  return (
    <>
      <CustomLoader showLoader={showLoader} />
      <form onSubmit={handleSubmit} ref={formRef}>
        <select name="model">
          <option value="">Select an option</option>
          <option value="expenseCode">Expense Code</option>
          <option value="departments">Departments</option>
          <option value="projectCode">Project Code</option>
          <option value="expenseApprovers">Expense Approvers</option>
          <option value="users">Users</option>
        </select>
        <input type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
        <button
          className="btn font-size-lg font-weight-bold py-2 redbutton rounded-lg text-white collapsed"
          type="submit"
          role="button"
          aria-expanded="false"
          aria-controls="collapseContent"
        >
          Import
        </button>
        <button
          type="button"
          className="btn font-size-lg font-weight-bold py-2 bluebutton rounded-lg text-white collapsed"
          onClick={handleReset}
        >
          Reset
        </button>
      </form>
    </>
  );
};

export default FileUpload;
