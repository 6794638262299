import React from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./utils/msalConfig";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "./store";
import { MsalProvider } from "@azure/msal-react";
import { createRoot } from "react-dom/client";
import { loginSuccess } from "./store/actions";

const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount(event.payload.account);

    // Dispatch login success with access token and account info
    store.dispatch(
      loginSuccess({
        lIU: event.payload.account.name,
        accessToken: event.payload.accessToken,
      })
    );

    // Check if refresh token is present
    if (event.payload.refreshToken) {
      // Store refresh token and its expiration time
      store.dispatch(
        loginSuccess({
          refreshToken: event.payload.refreshToken,
          refreshTokenExpiry: new Date(
            event.payload.refreshTokenExpiresOn
          ).getTime(),
        })
      );
    }
  }
});

const RootComponent = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <React.StrictMode>
              <App msalInstance={msalInstance} />
            </React.StrictMode>
          </BrowserRouter>
        </MsalProvider>
      </PersistGate>
    </Provider>
  );
};

const root = createRoot(document.getElementById("root"));
root.render(<RootComponent />);

reportWebVitals();
