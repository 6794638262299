import React from "react";
import { Link } from "react-router-dom";
import media from "../../media";

const NotFound = () => {
  return (
    <div className="center-container">
      <div className="centered-text">
        <img
          src={media.error404}
          className='class="d-block max-w-500px mb-10 mr-auto w-100'
        />
      </div>
      <p className="font-size-h3 pb-1 text-grey-custom">
        Please go back <Link to="/"> Home</Link>
      </p>
    </div>
  );
};

export default NotFound;
