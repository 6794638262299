//import secureLocalStorage from "react-secure-storage";
const role = "";

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENTID,
    authority: process.env.REACT_APP_AUTHORITY_LINK,
    redirectUri:
      role === "agent"
        ? process.env.REACT_APP_FRONTEND_AGENT_MICROSOFT_REDIRECT_URI
        : process.env.REACT_APP_FRONTEND_OTHERS_MICROSOFT_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    // loggerOptions: {
    //   loggerCallback: (Level, message, containsPII) => {
    //
    //   },
    //   logLevel: "Verbose",
    // },
    tokenRenewalOffsetSeconds: 30,
  },
};

export const loginRequest = {
  scopes: ["user.read", "offline_access"],
};
