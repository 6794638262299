import React, { useState, useEffect, useMemo } from "react";
import { DatePicker } from "antd";
import { useSelector } from "react-redux";
import { JsonToExcel } from "react-json-to-excel";
import useCurrentPath, { decryptData } from "../../components/Constants";
import ExpenseMultitasking from "../../components/ExpenseMultitasking";
import moment from "moment";
import { jsPDF } from "jspdf";
import media from "../../components/media";

export default function ListOfExpense() {
  const { MonthPicker } = DatePicker;
  const { RangePicker } = DatePicker;
  const currentPath = useCurrentPath();
  let storeRole = useSelector((state) => state.auth.role);
  //storeRole = storeRole ? decryptData(storeRole) : null;

  const initialState = "ALL";
  const [currentStatus, setCurrentStatus] = useState(initialState);
  const storeList = useSelector(
    (state) => state.auth.list?.[currentPath + "_info"] || []
  );

  // Memoize filteredList
  const filteredList = useMemo(() => {
    //const decrypted = decryptData(storeList);

    // Ensure decrypted is an array before calling map
    if (Array.isArray(storeList)) {
      return storeList.map(({ key, id, ...rest }) => rest);
    }

    // Return an empty array if decryption fails or result is not an array
    return [];
  }, [storeList]);

  const [filteredData, setFilteredData] = useState([]);

  const [isFilteredDates, setIsFilteredDates] = useState(false);
  const [filterValues, setFilterValues] = useState(null);

  // Callback to get filtered data from ExpenseMultitasking
  const handleFilteredData = (data) => {
    setFilteredData(data);
  };
  // Use useEffect to update filteredData when filteredList changes
  useEffect(() => {
    if (!isFilteredDates && filteredData !== filteredList) {
      setFilteredData(filteredList);
    }
  }, [filteredList, isFilteredDates]);

  const onDateChange = (dates, dateStrings) => {
    setFilterValues(dates ? dateStrings : null);
    setIsFilteredDates(true);
  };

  const handleMonthChange = (monthVal) => {
    setFilterValues(monthVal);
    setIsFilteredDates(true);
  };

  const startMonth = moment("2023-11", "YYYY-MM");
  const endMonth = moment();
  const disabledDate = (current) => {
    return (
      current &&
      (current < startMonth.startOf("month") ||
        current > endMonth.endOf("month"))
    );
  };

  const handleTabClick = (status) => {
    setCurrentStatus(status);
  };

  // Transforming the data and adding custom fields
  const transformedData =
    filteredData &&
    Array.isArray(filteredData) &&
    filteredData.map((item) => {
      const additional =
        item &&
        item.approvers.map((sbitem) => {
          const statusMessage =
            sbitem.status === process.env.REACT_APP_PENDING_STATUS.toUpperCase()
              ? "Pending Approval: Request Sent"
              : sbitem.status
              ? sbitem.status
              : "Awaiting previous Approver's Approval";

          return `${sbitem.additional.name} (${statusMessage})`;
        });

      return {
        ...item, // Keep existing fields
        approvers: additional.join(","), // Adding a custom field
      };
    });
  return (
    <div className="container">
      <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-5">
        <div className="d-flex justify-content-between">
          <h3 className="font-weight-bolder text-dark text-size-16 m-0">
            Expense Listing
          </h3>
          <div className="card-toolbar d-flex m-0">
            <div className="bg-gray-200 border border-light-dark mr-5 pl-3 rounded text-dark-custom">
              <span className="font-weight-bolder m-0 mr-3">Filter</span>
              {/* <RangePicker
                className="border-0 h-100 rangepicker"
                onChange={onDateChange}
              /> */}
              <MonthPicker
                disabledDate={disabledDate}
                placeholder="Select Month"
                onChange={handleMonthChange}
                className="border-0 h-100 rangepicker"
              />
            </div>
            <JsonToExcel
              title={
                <>
                  <i className="fas fa-file-download mr-1"></i>Export Excel
                </>
              }
              data={transformedData}
              fileName="list-of-expense"
              btnClassName="border border-light-dark btn font-weight-bolder px-4 py-2 text-dark-custom bg-transparent export-excel-button"
            />
          </div>
        </div>
        <div className="table-area">
          <ul className="nav nav-tabs nav-tabs-line mb-5">
            <li className="nav-item">
              <a
                className="nav-link listOfExpenseNavItem active"
                data-toggle="tab"
                onClick={() => handleTabClick(initialState)}
              >
                <span className="nav-text text-dark-custom px-3 cursor-pointer">
                  All
                </span>
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link listOfExpenseNavItem"
                data-toggle="tab"
                onClick={() =>
                  handleTabClick([
                    process.env.REACT_APP_PENDING_STATUS.toUpperCase(),
                    process.env.REACT_APP_NORESPONSE_STATUS.replace(
                      " ",
                      ""
                    ).toUpperCase(),
                    process.env.REACT_APP_RESUBMITTED_STATUS.toUpperCase(),
                  ])
                }
              >
                <span className="nav-text text-dark-custom px-3 cursor-pointer">
                  {process.env.REACT_APP_PENDING_STATUS}
                </span>
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link listOfExpenseNavItem"
                data-toggle="tab"
                onClick={() =>
                  handleTabClick([
                    process.env.REACT_APP_SENTBACK_STATUS.toUpperCase(),
                  ])
                }
              >
                <span className="nav-text text-dark-custom px-3 cursor-pointer">
                  {process.env.REACT_APP_SENTBACK_STATUS}
                </span>
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link listOfExpenseNavItem"
                data-toggle="tab"
                onClick={() =>
                  handleTabClick([
                    process.env.REACT_APP_CANCELED_STATUS.toUpperCase(),
                  ])
                }
              >
                <span className="nav-text text-dark-custom px-3 cursor-pointer">
                  {process.env.REACT_APP_CANCELED_STATUS}
                </span>
              </a>
            </li>

            <li className="nav-item">
              <a
                className="nav-link listOfExpenseNavItem"
                data-toggle="tab"
                onClick={() =>
                  handleTabClick([
                    process.env.REACT_APP_APPROVED_STATUS.toUpperCase(),
                  ])
                }
              >
                <span className="nav-text text-dark-custom px-3 cursor-pointer">
                  {process.env.REACT_APP_APPROVED_STATUS}
                </span>
              </a>
            </li>
          </ul>
          <div className="table-responsive">
            <div className="tab-content" id="alltable-size">
              <ExpenseMultitasking
                recordLimit={"50"}
                status={currentStatus}
                showLoaderOnList="yes"
                isFilterSelected={filterValues}
                onFilteredData={handleFilteredData} // Pass callback function
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
