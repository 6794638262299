import React from "react";

const DashboardCards = (props) => {
  const { item, itemValue, isCurrency } = props;
  const updatedItem = item.toUpperCase().replace(" ", "_");

  return (
    <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-5">
      <p className="text-dark-grey-custom mb-4 text-uppercase font-weight-bold">
        {item}
      </p>
      <div className="font-weight-bold font-size-sm">
        <span className="text-dark-75 font-weight-boldest font-size-h6 mr-2">
          {!isCurrency && "₹"}
          {itemValue[updatedItem]}
        </span>
      </div>
    </div>
  );
};

export default DashboardCards;
