import {
  LOGIN_SUCCESS,
  EMAIL_VERIFICATION_SUCCESS,
  CLEAR_REDUX,
  SEARCH_INFO,
  SELECTEDROWS_INFO,
} from "./actions";

const initialState = {
  isLoggedIn: null,
  accessToken: null,
  accessTokenExpiresOn: null,
  refreshToken: null,
  refreshTokenExpiresOn: null,
  lIUId: null,
  role: null,
};

const expenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        lIU: action.payload.lIU,
        accessToken: action.payload.accessToken,
        accessTokenExpiresOn: action.payload.accessTokenExpiresOn,
        refreshToken: action.payload.refreshToken,
        refreshTokenExpiresOn: action.payload.refreshTokenExpiresOn,
      };
    case EMAIL_VERIFICATION_SUCCESS:
      return {
        ...state,
        isLoggedIn: action.payload.isLoggedIn,
        lIUId: action.payload.lIUId,
        role: action.payload.role,
        name: action.payload.name,
        empID: action.payload.empID,
        profileImage: action.payload.profileImage,
        userInfo: action.payload.userInfo,
      };
    case CLEAR_REDUX:
      return initialState;
    case SEARCH_INFO:
      return {
        ...state,
        list: action.payload.list,
        recordCountFor: action.payload.recordCountFor,
        recordCount: action.payload.recordCount,
        pagination: action.payload.pagination,
        totalCount: action.payload.totalCount,
      };
    case SELECTEDROWS_INFO:
      return {
        ...state,
        selectedRowKeysChildInfo: action.payload.selectedRowKeysChildInfo,
      };
    default:
      return state;
  }
};

export default expenseReducer;
