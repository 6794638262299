import React from "react";
import media from "../components/media";
import moment from "moment";
import { Badge, Card, List, Col, Row, Tooltip } from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";

const ExportPdf = (props) => {
  const { expenseDetail } = props;

  const getStatusBadge = (status, reason) => {
    switch (status) {
      case "APPROVED":
        return (
          <Badge
            status="success"
            text="Approved"
            icon={<CheckCircleOutlined />}
            className="approved-badge"
          />
        );
      case "PENDING":
        return (
          <Badge
            status="warning"
            text="Pending Approval: Request Sent"
            icon={<ClockCircleOutlined />}
            className="pending-badge"
          />
        );
      case "CANCELED":
        return (
          <Badge
            status="error"
            text={
              <span>
                <strong>Canceled</strong>: {reason}
              </span>
            }
            icon={<CloseCircleOutlined />}
            className="canceled-badge"
          />
        );
      case "SENTBACK":
        return (
          <Badge
            status="processing"
            text={
              <span>
                <strong>Sent Back</strong>: {reason}
              </span>
            }
            icon={<RedoOutlined />}
            className="sentBack-badge"
          />
        );
      default:
        return (
          <Badge
            status="default"
            text="Awaiting previous Approver's Approval"
            icon={<ExclamationCircleOutlined />}
          />
        );
    }
  };
  return (
    <div className="pdf-inner">
      <div className="border-2 border-bottom border-light-light mb-6 pb-5">
        <div className="row">
          <div className="col-sm-12">
            <img src={media.PdfLogo} alt="logo" className="w-25" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-3 mb-6">
          <label className="font-size-sm font-weight-bold text-dark-50">
            Employee ID
          </label>
          <p className="m-0 font-weight-boldest">{expenseDetail?.empID}</p>
        </div>

        <div className="col-sm-3 mb-6">
          <label className="font-size-sm font-weight-bold text-dark-50">
            Employee Name
          </label>
          <p className="m-0 font-weight-boldest">{expenseDetail?.empName}</p>
        </div>

        <div className="col-sm-3 mb-6">
          <label className="font-size-sm font-weight-bold text-dark-50">
            Department
          </label>
          <p className="m-0 font-weight-boldest">
            {expenseDetail?.departmentID?.name}
          </p>
        </div>

        <div className="col-sm-3 mb-6">
          <label className="font-size-sm font-weight-bold text-dark-50">
            Expense Period: (From - To)
          </label>
          <p className="m-0 font-weight-boldest">
            {[
              expenseDetail?.expensePeriodFromDate &&
              expenseDetail?.expensePeriodToDate
                ? moment(expenseDetail?.expensePeriodFromDate).format(
                    "DD MMM, YYYY"
                  ) +
                  " - " +
                  moment(expenseDetail?.expensePeriodToDate).format(
                    "DD MMM, YYYY"
                  )
                : null,
            ]}
          </p>
        </div>
        <div className="col-sm-3 mb-6">
          <label className="font-size-sm font-weight-bold text-dark-50">
            Project Code
          </label>
          <p className="m-0 font-weight-boldest">
            {expenseDetail?.projectCodeID?.code
              ? `${expenseDetail.projectCodeID?.code}-${expenseDetail.projectCodeID?.companyName}`
              : "NA"}
          </p>
        </div>
        <div className="col-sm-3 mb-6">
          <label className="font-size-sm font-weight-bold text-dark-50">
            Business Purpose
          </label>
          <p className="m-0 font-weight-boldest">
            {expenseDetail?.businessPurpose}
          </p>
        </div>
      </div>

      <div className="border border-light-light col-sm-12 my-3"></div>

      {expenseDetail?.expensesDetails &&
        expenseDetail?.expensesDetails.map((item, index) => {
          return (
            <>
              <div className="row" key={item?.id || index}>
                <div className="col-sm-3 mb-6">
                  <label className="font-size-sm font-weight-bold text-dark-50">
                    Date
                  </label>
                  <p className="m-0 font-weight-boldest">
                    {[
                      item?.date
                        ? moment(expenseDetail?.expensesDetails?.date).format(
                            "MM DD, YYYY"
                          )
                        : null,
                    ]}
                  </p>
                </div>
                <div className="col-sm-3 mb-6">
                  <label className="font-size-sm font-weight-bold text-dark-50">
                    Description
                  </label>
                  <p className="m-0 font-weight-boldest">{item?.description}</p>
                </div>
                <div className="col-sm-3 mb-6">
                  <label className="font-size-sm font-weight-bold text-dark-50">
                    Department
                  </label>
                  <p className="m-0 font-weight-boldest">
                    {item?.departmentID?.name}
                  </p>
                </div>
                <div className="col-sm-3 mb-6">
                  <label className="font-size-sm font-weight-bold text-dark-50">
                    Expense Code
                  </label>
                  <p className="m-0 font-weight-boldest">
                    {item?.expenseCodeID?.costCode}
                  </p>
                </div>
                <div className="col-sm-3 mb-6">
                  <label className="font-size-sm font-weight-bold text-dark-50">
                    Cost
                  </label>
                  <p className="m-0 font-weight-boldest">{item?.cost}</p>
                </div>{" "}
                {item?.mediaInfo.length !== 0 && (
                  <div className="col-sm-12 mb-6">
                    <label className="font-size-sm font-weight-bold text-dark-50">
                      Uploaded Document
                    </label>
                    <div className="row">
                      {item?.mediaInfo.map((mediaItem, mediaIndex) => {
                        return (
                          <div className="col-sm-6 mb-3" key={mediaIndex}>
                            <img
                              className="rounded w-50"
                              key={index}
                              src={`${process.env.REACT_APP_BACKEND}/${mediaItem?.savedFilepath}`}
                              alt="media"
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="border border-light-light col-sm-12 mb-3"></div>
              </div>
            </>
          );
        })}

      <div className="row">
        <div className="col-sm-12">
          <h4 className="font-weight-bold text-dark-50 d-block">
            Approvers Status
          </h4>

          {expenseDetail?.approvers &&
            expenseDetail?.approvers.map((approver, index) => {
              return (
                <>
                  <div className="m-0 mb-2">
                    <strong>Approver Name: </strong>
                    {approver?.additional?.name}
                    <p className="my-2 font-weight-boldest">
                      {approver.status ===
                      process.env.REACT_APP_PENDING_STATUS.toUpperCase()
                        ? "Pending Approval: Request Sent"
                        : approver.status
                        ? approver.status
                        : "Awaiting previous Approver's Approval"}
                    </p>
                    <p className="m-0 font-weight-boldest">
                      {approver?.reason}
                    </p>
                  </div>
                </>
              );
            })}
        </div>
        <div className="col-sm-12 mt-5">
          {expenseDetail?.adminApprover &&
          expenseDetail?.adminApprover.length > 0 ? (
            expenseDetail?.adminApprover.map((adminApprover) => {
              return (
                <>
                  <div>
                    <h4 className="font-weight-bold text-dark-50 d-block">
                      Admin Approver Status
                    </h4>

                    <div className="m-0">
                      <strong>Approver Name: </strong>
                      {adminApprover?.userID?.name}
                      <p className="my-2 font-weight-boldest">
                        {adminApprover?.status}
                      </p>
                      <p className="m-0">{adminApprover?.reason}</p>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <>
              <h4 className="font-weight-bold text-dark-50 d-block">
                Admin Approvers Status
              </h4>
              <p className="my-2">
                Admin approval will take place after all approvers complete
                their approvals.
              </p>
            </>
          )}
        </div>
      </div>

      {/* <div className="status-message">
        
        <Card
          title="Approvers Status"
          bordered={true}
          style={{ marginBottom: "20px" }}
        >
          {expenseDetail?.approvers && (
            <List
              itemLayout="horizontal"
              dataSource={expenseDetail?.approvers}
              renderItem={(approver, index) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <>
                        <Tooltip
                          title={`Approver ${index + 1}: ${
                            approver?.additional?.name
                          }`}
                        >
                          <span>
                            <strong>Approver Name: </strong>
                            {approver?.additional?.name}
                          </span>
                        </Tooltip>
                      </>
                    }
                    description={getStatusBadge(
                      approver?.status,
                      approver?.reason
                    )}
                  />
                </List.Item>
              )}
            />
          )}
        </Card>
        

        <Card title="Admin Approver Status" bordered={true}>
          {expenseDetail?.adminApprover &&
          expenseDetail?.adminApprover.length > 0 ? (
            <List
              itemLayout="horizontal"
              dataSource={expenseDetail?.adminApprover}
              renderItem={(adminApprover, index) => (
                <List.Item>
                  <List.Item.Meta
                    title={
                      <>
                        <Tooltip
                          title={`Approver: ${
                            adminApprover?.userID?.name
                          }`}
                        >
                          <span>
                            <strong>Approver Name: </strong>
                            {adminApprover?.userID?.name}
                          </span>
                        </Tooltip>
                      </>
                    }
                    description={getStatusBadge(
                      adminApprover?.status,
                      adminApprover?.reason
                    )}
                  />
                </List.Item>
              )}
            />
          ) : (
            <div className="admin-status-awaiting">
              <span>
                Admin approval will take place after all approvers complete
                their approvals.
              </span>
            </div>
          )}
        </Card>
      </div> */}
    </div>
  );
};
export default ExportPdf;
