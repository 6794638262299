import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  message,
  Button,
  Table,
  Select,
  InputNumber,
  Typography,
  Popconfirm,
} from "antd";
import { useSelector } from "react-redux";
import APICaller from "../../utils/APICaller";
import { useLocation } from "react-router-dom";
import HandleSearch from "../../utils/CommonSearchComponent";
import useCurrentPath, { decryptData } from "../../components/Constants";
import { store } from "../../store";
import CustomLoader from "../../components/CustomLoader";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  departments,
  approvers,
  ...restProps
}) => {
  let inputNode;
  if (dataIndex === "departmentID") {
    inputNode = (
      <Select
        isclearable
        options={departments}
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
      />
    );
  } else if (dataIndex === "approvers") {
    inputNode = (
      <Select
        mode={"multiple"}
        isclearable
        options={approvers}
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
      />
    );
  } else {
    inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
export default function ProjectCode() {
  const { Search } = Input;
  const [searchText, setSearchText] = useState("");

  const [form] = Form.useForm();
  const [tableForm] = Form.useForm();
  const location = useLocation();
  const currentPath = useCurrentPath();
  let storeRole = useSelector((state) => state.auth.role);
  //storeRole = storeRole ? decryptData(storeRole) : null;

  let storeToken = useSelector((state) => state.auth.accessToken);
  //storeToken = storeToken ? decryptData(storeToken) : null;

  let storeLIUId = useSelector((state) => state.auth.lIUId);
  //storeLIUId = storeLIUId ? decryptData(storeLIUId) : null;

  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );

  // Initialize storeList with a default value
  const [storeList, setStoreList] = useState([]);
  const [data, setData] = useState([]);
  const storeTotalCount = useSelector((state) => state.auth.totalCount);

  const [showLoader, setShowLoader] = useState(false);
  const [selectionType, setSelectionType] = useState("checkbox");
  const [collapseClass, setCollapseClass] = useState("");
  const [list, setList] = useState([]),
    [selectedRowKeys, setSelectedRowKeys] = useState([]),
    [departments, setDepartments] = useState([]),
    [approvers, setApprovers] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });

  const [addProjectCode, setAddProjectCode] = useState({
    departmentID: "",
    approvers: [],
    code: "",
    clientName: "",
    companyName: "",
    history: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });

  //Fetch approvers list
  const getApprovers = () => {
    const endpoint = "/users/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response &&
          response?.response?.map((approver, index) => {
            if (approver?.roleID?.name === process.env.REACT_APP_AROLE) {
              temp.push({ label: approver?.name, value: approver?._id });
            }
          });
        setApprovers(temp);
      })
      .catch((error) => {});
  };
  /*begin::getDepartments will fetch the list of Departments*/

  const getDepartments = () => {
    const endpoint = "/departments/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response &&
          response?.response?.map((department, index) => {
            temp.push({ label: department?.name, value: department?._id });
          });
        setDepartments(temp);
      })
      .catch((error) => {});
  };

  /*end::getDepartments will fetch the list of Departments*/

  const getProjectCodeList = async (source = "", pagination = "") => {
    setShowLoader(true);

    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    setSelectedRowKeys([]);
    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    await HandleSearch(
      storeRole,
      storeToken,
      storeLIUId,
      storeAccessTokenExpiresOn,
      searchIn,
      callActionFrom,
      allStates,
      ""
    );
    // Ensure storeList gets updated with the latest data
    const fetchedData = store.getState().auth.list[currentPath + "_info"];
    //const fetchedData = data ? decryptData(data) : [];

    setStoreList(fetchedData);

    // Update data state after fetching
    setData(fetchedData);
    setShowLoader(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getProjectCodeList();
    };
    fetchData();
  }, [storeLIUId, storeRole, storeToken, location.pathname]);

  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    tableForm.setFieldsValue({
      departmentID: "",
      code: "",
      clientName: "",
      companyName: "",
      createdAt: "",
      updatedAt: "",
      ...record,
    });
    tableForm.setFieldsValue({
      approvers: record.approvers.split(",") || [], // Set default selected values
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey("");
  };

  const save = async (key) => {
    try {
      const row = await tableForm.validateFields();
      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];

        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey("");

        const endpoint = `/project-code/${key}`;
        const method = "PUT";
        const payload = {
          code: newData[index]?.code,
          clientName: newData[index]?.clientName,
          companyName: newData[index]?.companyName,
          status: newData[index]?.status,
          historySource: "backend",
          historyAction: "update",
          historyUserID: storeLIUId,
        };

        // Only include departmentID if it has been edited
        if (newData[index]?.departmentID !== item?.departmentID) {
          payload.departmentID = newData[index]?.departmentID;
        }
        // Only include approvers if it has been edited
        payload.approvers = newData[index]?.approvers;

        APICaller(
          storeRole,
          storeToken,
          endpoint,
          method,
          payload,
          storeAccessTokenExpiresOn
        )
          .then((response) => {
            message.success("Project Code updated successfully.");
            tableForm.resetFields();
            getProjectCodeList();
          })
          .catch((error) => {});
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const onFinish = (projectValues) => {
    setShowLoader(true);
    const endpoint = "/project-code";
    const method = "POST";
    const payload = {
      auth: storeToken,
      departmentID: projectValues?.departmentID,
      approvers: projectValues?.approvers,
      code: projectValues?.code,
      clientName: projectValues?.clientName,
      companyName: projectValues?.companyName,
      historySource: "backend",
      historyAction: "create",
      historyUserID: storeLIUId,
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        setCollapseClass("hide");
        message.success("Project Code added successfully.");
        form.resetFields();
        getProjectCodeList();
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setShowLoader(false);
  };

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {},
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  const columns = [],
    additionalColumns = [];
  if (storeRole !== process.env.REACT_APP_SROLE) {
    columns.push({
      title: "Sr. No.",
      dataIndex: "srno",
      width: "10%",
      render: (text, record, index) => index + 1,
    });
  }
  const commonColumns = [
    {
      title: "Department",
      dataIndex: "departmentID",
      width: "10%",
      editable: true,
      sorter: (a, b) => a.departmentID.localeCompare(b.departmentID),
    },
    {
      title: "Code",
      dataIndex: "code",
      width: "10%",
      editable: true,
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      width: "10%",
      editable: true,
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      width: "15%",
      editable: true,
      sorter: (a, b) => a.companyName.localeCompare(b.companyName),
    },
    {
      title: "Approvers",
      dataIndex: "approvers",
      width: "20%",
      editable: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      width: "7%",
      editable: true,
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: "12%",
    },
  ];

  if (
    storeRole === process.env.REACT_APP_SROLE ||
    storeRole === process.env.REACT_APP_ADROLE
  ) {
    additionalColumns.push(
      {
        title: "Updated At",
        dataIndex: "updatedAt",
        width: "12%",
      },
      {
        title: "Action",
        dataIndex: "action",
        width: "10%",
        render: (_, record) => {
          const editable = isEditing(record);
          return editable ? (
            <span>
              <Typography.Link
                onClick={() => save(record.key)}
                style={{
                  marginRight: 8,
                }}
              >
                Save
              </Typography.Link>
              <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                <a>Cancel</a>
              </Popconfirm>
            </span>
          ) : (
            <Typography.Link
              disabled={editingKey !== ""}
              onClick={() => edit(record)}
            >
              Edit
            </Typography.Link>
          );
        },
      }
    );
  }
  columns.push(...commonColumns, ...additionalColumns);

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        departments: departments,
        approvers: approvers,
      }),
    };
  });

  useEffect(() => {
    getDepartments();
    getApprovers();
  }, []);

  const searchFilterData =
    data &&
    Array.isArray(data) &&
    data.filter((item) => {
      const departmentID = item?.departmentID?.name
        ? item?.departmentID?.name.toLowerCase()
        : "";
      const code = item?.code ? item?.code.toLowerCase() : "";
      const clientName = item?.clientName ? item?.clientName.toLowerCase() : "";
      const companyName = item?.companyName
        ? item?.companyName.toLowerCase()
        : "";
      return (
        departmentID.includes(searchText.toLowerCase()) ||
        code.includes(searchText.toLowerCase()) ||
        clientName.includes(searchText.toLowerCase()) ||
        companyName.includes(searchText.toLowerCase())
      );
    });

  return (
    <>
      <div className="container">
        <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-0">
          <div className="border-2 card-header p-5">
            <div className="align-items-center d-flex justify-content-between">
              <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark text-size-16">
                Project Code
                <span
                  className="label label-dark ml-2 text-white font-weight-bold w-30px h-30px"
                  style={{ fontSize: "11px" }}
                >
                  {storeTotalCount ? storeTotalCount : 0}
                </span>
              </h3>
              {(storeRole === process.env.REACT_APP_SROLE ||
                storeRole === process.env.REACT_APP_ADROLE) && (
                <button
                  className="btn font-size-lg font-weight-bold py-2 redbutton rounded-lg text-white collapsed"
                  data-toggle="collapse"
                  href="#newcode"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseContent"
                >
                  <i className="flaticon2-plus mr-2 text-white"></i> Add New
                  Code
                </button>
              )}
            </div>
          </div>

          {(storeRole === process.env.REACT_APP_SROLE ||
            storeRole === process.env.REACT_APP_ADROLE) && (
            <>
              {/**Add New Code Start**/}
              <div id="newcode" className={`collapse p-3 ${collapseClass}`}>
                <div className="border border-light-dark p-4 m-0">
                  <Form
                    layout="vertical"
                    autoComplete="off"
                    className="form-validation-start"
                    onFinish={onFinish}
                    form={form}
                    onFinishFailed={onFinishFailed}
                  >
                    <Row gutter={[24, 24]}>
                      <Col sm={12}>
                        <Form.Item
                          className="m-0"
                          label="Department"
                          name="departmentID"
                          rules={[
                            {
                              required: true,
                              message: "Please select department",
                            },
                          ]}
                        >
                          <Select
                            className="h-45px rounded-md"
                            isclearable
                            placeholder="Select Department"
                            status=""
                            value=""
                            options={departments}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12}>
                        <Form.Item
                          className="m-0"
                          label="Approvers"
                          name="approvers"
                          rules={[
                            {
                              required: true,
                              message: "Please select approver",
                            },
                          ]}
                        >
                          <Select
                            className="rounded-md"
                            mode={"multiple"}
                            isclearable
                            placeholder="Select Approver"
                            status=""
                            value=""
                            options={approvers}
                            filterOption={(input, option) =>
                              option.label
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12}>
                        <Form.Item
                          className="m-0"
                          label="Client  Name"
                          name="clientName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter client name",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter the client name"
                            className="form-control h-45px rounded-md"
                            onChange={(e) => {
                              setAddProjectCode({
                                ...addProjectCode,
                                clientName: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12}>
                        <Form.Item
                          className="m-0"
                          label="Client Code"
                          name="code"
                          rules={[
                            {
                              required: true,
                              message: "Please enter client code",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter the client code"
                            className="form-control h-45px rounded-md"
                            onChange={(e) => {
                              setAddProjectCode({
                                ...addProjectCode,
                                code: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={12}>
                        <Form.Item
                          className="m-0"
                          label="Company Name"
                          name="companyName"
                          rules={[
                            {
                              required: true,
                              message: "Please enter company name",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Enter the company name"
                            className="form-control h-45px rounded-md"
                            onChange={(e) => {
                              setAddProjectCode({
                                ...addProjectCode,
                                companyName: e.target.value,
                              });
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col sm={24}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="btn font-size-lg font-weight-bold ml-auto d-block py-2 redbutton rounded-lg text-white h-auto"
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>

                <hr className="mt-10" />
              </div>
              {/**Add New Code End**/}
            </>
          )}

          <div className="m-0 p-3">
            <CustomLoader showLoader={showLoader} />
            <Search
              placeholder="Search Department, Code, Client Name & Company Name"
              className="table-search"
              onSearch={(value) => setSearchText(value)}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <Form form={tableForm} component={false}>
              <div id="alltable-size">
                <Table
                  rowSelection={
                    storeRole !== process.env.REACT_APP_SROLE
                      ? ""
                      : {
                          type: selectionType,
                          ...rowSelection,
                        }
                  }
                  components={{
                    body: {
                      cell: EditableCell,
                    },
                  }}
                  bordered
                  dataSource={searchFilterData}
                  columns={mergedColumns}
                  rowClassName="editable-row"
                  pagination={{
                    onChange: cancel,
                  }}
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}
