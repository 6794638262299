import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HandleSearch from "../../../utils/CommonSearchComponent";
import useCurrentPath, { decryptData } from "../../Constants";
import { Link } from "react-router-dom";
import { Form } from "antd";
import { store } from "../../../store";
import APICaller from "../../../utils/APICaller";
import moment from "moment";
import media from "../../media";

export default function NotificationPanel(props) {
  const { storeList, nCount } = props;

  return (
    <>
      <div id="kt_quick_panel" className="offcanvas offcanvas-right pt-5 pb-10">
        <div className="offcanvas-header offcanvas-header-navs d-flex align-items-center justify-content-between mb-5 p-3 border-2 border-bottom">
          <h5 className="font-weight-bold ml-4">Notifications</h5>

          <div className="offcanvas-close mt-n1 position-relative pr-5">
            <span
              className="btn btn-dark btn-icon btn-xs"
              id="kt_quick_panel_close"
              onClick={() => {
                document
                  .getElementById("kt_quick_panel")
                  .classList.remove("offcanvas-on");
                document
                  .querySelector("body")
                  .classList.remove("overflow-hidden");
              }}
            >
              <i className="ki ki-close icon-xs text-white"></i>
            </span>
          </div>
        </div>

        <div className="offcanvas-content">
          {/*  px-10 */}
          <div className="notificationPanel tab-content">
            {nCount !== 0 && (
              <div className="viewAll">
                <Link
                  to="/notifications"
                  className="text-white btn font-size-sm font-weight-bold px-3 py-1 redbutton rounded-lg"
                >
                  View All
                </Link>
              </div>
            )}
            {storeList &&
              storeList.map((list, index) => {
                return (
                  <div className="navi-link rounded" key={index}>
                    <div className="navi-text">
                      <div className="user-section">
                        <div className="symbol symbol-30 symbol-lg-40 symbol-circle">
                          <img
                            alt="Pic"
                            className="w-100px"
                            src={
                              list?.assignedBy &&
                              list?.assignedBy.split("~")?.[2]
                                ? process.env.REACT_APP_BACKEND +
                                  "/images/" +
                                  list?.assignedBy.split("~")?.[2]
                                : media.user
                            }
                          />
                        </div>

                        <div className="user-info font-size-md">
                          <span className="font-weight-bold font-size-md text-capitalize">
                            {list?.assignedBy &&
                              list?.assignedBy.split("~")?.[0] +
                                " " +
                                list?.assignedBy.split("~")?.[1]}
                          </span>
                          <Link to={`/notifications/${list?.id}`}>
                            {list?.title.replace("Notification:", "")}
                          </Link>

                          <div className="sent-on">
                            <strong>Sent On: </strong>

                            {list.createdAt}
                          </div>
                        </div>
                      </div>
                      {/* <div className="text-muted">
                        <div
                          dangerouslySetInnerHTML={{ __html: list.excerpt }}
                        />
                      </div> */}
                    </div>
                    {/* <div className="viewSingle">
                      <Link to={`/notifications/${list?.id}`}>View</Link>
                    </div> */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
