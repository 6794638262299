import React from "react";

const CustomLoader = ({ showLoader }) => {
  return (
    <>
      {showLoader === true && (
        <div className="customLoader">
          <div className="cust-loader"></div>
        </div>
      )}
    </>
  );
};

export default CustomLoader;
