import Logo from "../media/logo.svg";
import user from "../media/user.png";
import bill from "../media/bills.jpg";
import dummyBills from "../media/dummyBill.png";
import PdfLogo from "../media/PdfLogo.png";
import error404 from "../media/404.png";

const media = { Logo, user, dummyBills, bill, PdfLogo, error404 };

export default media;
