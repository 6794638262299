// store/index.js

import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer ,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootReducer'; // Make sure to adjust the path as needed

// Define persistConfig for redux-persist
const persistConfig = {
  key: 'expense',
  storage,
  // Optionally define which reducers to persist
   whitelist: ['auth'], // Uncomment if you want to persist only 'auth' state
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Example middleware to log dispatches
const loggingMiddleware = (store) => (next) => (action) => {
    console.log('Dispatching action:', action);
    return next(action);
  };

// Configure the Redux store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  // devTools: process.env.NODE_ENV !== 'production',
});

// Create a persistor object
const persistor = persistStore(store);

export { store, persistor };
