import ReactDOM from "react-dom/client";
import React, { useEffect, useRef, useState } from "react";
import { DatePicker, Input, Table, Tooltip } from "antd";
import { Link, useLocation } from "react-router-dom";
import HandleSearch from "../../utils/CommonSearchComponent";
import { useSelector } from "react-redux";
import { store } from "../../store";
import media from "../media";
import CustomLoader from "../CustomLoader";
import ExportPdf from "../../utils/exportPdf";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOMServer from "react-dom/server";
import APICaller from "../../utils/APICaller";
import html2pdf from "html2pdf.js";
import { decryptData } from "../Constants";

// import { PDFDocument } from "pdf-lib";
// import { saveAs } from "file-saver";

const convertToDate = (dateStr) => {
  const [day, month, year] = dateStr && dateStr.split("-").map(Number);
  const currentYear = new Date().getFullYear();
  const currentYearLastTwoDigits = currentYear % 100;
  let fullYear =
    year < 100
      ? year <= currentYearLastTwoDigits
        ? currentYear - currentYearLastTwoDigits + year
        : currentYear - currentYearLastTwoDigits - 100 + year
      : year;
  return new Date(Date.UTC(fullYear, month - 1, day));
};

export default function ExpenseMultitasking(props) {
  const { Search } = Input;
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const storeListRef = useRef([]);
  const {
    name,
    recordLimit = 50,
    status,
    reqSearchIn,
    showLoaderOnList,
    isFilterSelected,
    onFilteredData,
  } = props;

  let storeRole = useSelector((state) => state.auth.role);

  let storeToken = useSelector((state) => state.auth.accessToken);

  let storeLIUId = useSelector((state) => state.auth.lIUId);

  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );
  const [idForExpenseDetailPdf, setIdForExpenseDetailPdf] = useState();
  const [storeERList, setStoreERList] = useState([]);
  const [storeEListing, setStoreEListing] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: recordLimit,
  });
  const [list, setList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false); // Add a state to track if data has been initially loaded

  const currentPath = location.pathname.replace("/", "");
  const searchIn =
    name === "dashboardPendingRequest"
      ? "pending-requests"
      : name === "dashboardListOfExpense"
      ? "list-of-expense"
      : currentPath;

  const getPendingRequestsList = async (source = "", pagination = "") => {
    setShowLoader(showLoaderOnList === "yes");

    const callActionFrom = source ? source : "";
    const allStates = {
      showLoader,
      setShowLoader,
      pagination: pagination
        ? pagination
        : {
            current: 1,
            page: 1,
            pageSize: recordLimit,
          },
      setPagination,
      list,
      setList,
      status,
    };

    try {
      await HandleSearch(
        storeRole,
        storeToken,
        storeLIUId,
        storeAccessTokenExpiresOn,
        searchIn,
        callActionFrom,
        allStates,
        ""
      );

      const authList = store.getState().auth.list;

      if (name === "dashboardPendingRequest") {
        setStoreERList(
          authList[process.env.REACT_APP_DASHBOARD_PR_SEARCH_IN + "_info"]
        );
      } else if (name === "dashboardListOfExpense") {
        setStoreEListing(
          authList[process.env.REACT_APP_DASHBOARD_EL_SEARCH_IN + "_info"]
        );
      } else {
        const expenseInfo = authList[searchIn + "_info"];

        if (isFilterSelected === null) {
          setStoreList(
            status === "ALL"
              ? expenseInfo
              : expenseInfo &&
                  expenseInfo.filter((item) =>
                    status.includes(item.status.toUpperCase())
                  )
          );
          // Call the callback function to pass filtered data
          if (onFilteredData) {
            onFilteredData(
              status === "ALL"
                ? expenseInfo
                : expenseInfo &&
                    expenseInfo.filter((item) =>
                      status.includes(item.status.toUpperCase())
                    )
            );
          }
        }
      }
    } catch (error) {
      console.error("Error fetching expense requests list: ", error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (!isFilterSelected) {
      setDataLoaded(false);
      getPendingRequestsList();
    } else {
      setDataLoaded(true);
      if (dataLoaded === true) {
        const startRange =
          isFilterSelected && isFilterSelected.length
            ? isFilterSelected[0] && isFilterSelected[0]
            : isFilterSelected.startOf("month").format("YYYY-MM-DD");

        const endRange =
          isFilterSelected && isFilterSelected.length
            ? isFilterSelected[1] && isFilterSelected[1]
            : isFilterSelected.endOf("month").format("YYYY-MM-DD");

        const startDate = new Date(`${startRange}T00:00:00Z`);
        const endDate = new Date(`${endRange}T23:59:59Z`);

        const filtered =
          storeList &&
          storeList.filter((item) => {
            const dateToCheck = item.expensePeriodFromDate?.split(" ")[0];
            const dateToCheckObj = dateToCheck && convertToDate(dateToCheck);
            return dateToCheckObj >= startDate && dateToCheckObj <= endDate;
          });

        storeListRef.current = filtered;
        // Call the callback function to pass filtered data
        if (onFilteredData) {
          onFilteredData(filtered);
        }
        getPendingRequestsList("filtered", pagination);
      }
    }
  }, [
    name,
    recordLimit,
    ...(searchIn === process.env.REACT_APP_DASHBOARD_EL_SEARCH_IN
      ? status
      : ""),
    currentPath,
    isFilterSelected,
    dataLoaded,
  ]);

  const sAColumn = [
    {
      title: "Approvers",
      dataIndex: "approvers",
      render: (approvers) => (
        <div className="d-flex justify-content-center">
          {approvers &&
            approvers.map((approver, index) => (
              <Tooltip
                key={index}
                title={`${approver?.additional?.name.toUpperCase()}, ${
                  approver?.status ? approver?.status : "NOT SENT"
                }`}
                className={`approvers-tooltips ${
                  approver?.status
                    ? approver?.status?.toLowerCase()
                    : "yetToSent"
                }-tooltips`}
              >
                <span></span>
              </Tooltip>
            ))}
        </div>
      ),
    },
  ];

  const aAColumn = [
    {
      title: "Admin Approval",
      dataIndex: "adminApprover",
      render: (text) => <span>{text}</span>,
    },
  ];
  let additionalSAColumn =
    name === "dashboardListOfExpense" ||
    searchIn === "list-of-expense" ||
    searchIn === "pending-requests"
      ? sAColumn
      : "";
  let additionalAAColumn =
    name === "dashboardListOfExpense" ||
    searchIn === "list-of-expense" ||
    searchIn === "pending-requests"
      ? aAColumn
      : "";

  const columns = [
    {
      title: "Sr. No.",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "empName",
      key: "empName",
    },
    {
      title: "Emp. Id.",
      dataIndex: "empID",
    },
    {
      title: "Business Purpose",
      dataIndex: "businessPurpose",
    },
    {
      title: "Project Code",
      dataIndex: "projectCodeID",
    },
    {
      title: "Expense Period",
      dataIndex: "expensePeriod",
    },
    {
      title: "Expense Month",
      dataIndex: "month",
    },
    {
      title: "Amount",
      dataIndex: "totalReimbursement",
      render: (text) => `₹ ${text}`,
    },
    ...additionalSAColumn,
    ...additionalAAColumn,
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <span
          className={`table-btn cursor-unset text-capitalize ${text?.toLowerCase()}-btn`}
        >
          {text?.toLowerCase() ===
          process.env.REACT_APP_NORESPONSE_STATUS.replace(
            " ",
            ""
          )?.toLowerCase()
            ? process.env.REACT_APP_NORESPONSE_STATUS
            : text?.toLowerCase()}
        </span>
      ),
    },
    {
      title: "Payment Paid On",
      dataIndex: "paymentPaidOn",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <>
          <div className="whiteSpaceNowrap">
            <Link to={`/${searchIn}/${record?.key}`}>
              <i className="flaticon-eye icon-lg text-grey-custom mr-5"></i>
            </Link>
            <i
              className="fas fa-file-pdf icon-lg text-grey-custom cursor-pointer"
              onClick={() => handlePdfExport(record)}
            ></i>
          </div>
        </>
      ),
    },
  ];
  const [expenseDetail, setExpenseDetail] = useState(null);

  const generatePdfContent = (
    record,
    expenseDetail,
    storeRole,
    storeToken,
    storeAccessTokenExpiresOn
  ) => {
    return ReactDOMServer.renderToString(
      <ExportPdf
        record={record}
        expenseDetail={expenseDetail}
        storeRole={storeRole}
        storeToken={storeToken}
        storeAccessTokenExpiresOn={storeAccessTokenExpiresOn}
      />
    );
  };
  const handlePdfExport = async (record) => {
    try {
      // Fetch expense details
      const endpoint = `/list-of-expense/${record?.key}`;
      const method = "GET";
      const payload = {};
      const response = await APICaller(
        storeRole,
        storeToken,
        endpoint,
        method,
        payload,
        storeAccessTokenExpiresOn
      );
      setExpenseDetail(response);

      if (response) {
        // Step 1: Generate HTML content as a string
        const htmlContent = generatePdfContent(
          record,
          response,
          storeRole,
          storeToken,
          storeAccessTokenExpiresOn
        );

        // Define PDF options
        const options = {
          margin: [10, 10, 10, 10],
          filename: `${record.key}_Details.pdf`,
          html2canvas: {
            scale: 2, // Increase scale for better resolution
            useCORS: true,
            logging: true,
          },
          jsPDF: {
            unit: "mm",
            format: "a4",
            orientation: "portrait",
          },
        };

        // Generate PDF
        html2pdf()
          .from(htmlContent)
          .set(options)
          .save()
          .catch((error) => {
            console.error("Error generating PDF:", error);
          });
      }
    } catch (error) {
      console.error("Error fetching data or generating PDF:", error);
    }
  };
  const filteredERList =
    storeERList &&
    Array.isArray(storeERList) &&
    storeERList.map(({ key, id, ...rest }) => rest);

  // Filter the storeList based on the searchText

  const refinedData =
    currentPath === "dashboard"
      ? storeEListing
        ? storeEListing
        : []
      : dataLoaded === true
      ? storeListRef.current
      : storeList;
  const searchFilterData =
    refinedData &&
    Array.isArray(refinedData) &&
    refinedData.filter((item) => {
      const empID = item.empID ? item.empID : "";
      const businessPurpose = item.businessPurpose
        ? item.businessPurpose.toLowerCase()
        : "";
      const amount = item.totalReimbursement ? item.totalReimbursement : "";

      return (
        businessPurpose.includes(searchText.toLowerCase()) ||
        empID === Number(searchText) ||
        amount === Number(searchText)
      );
    });
  return (
    <>
      <CustomLoader showLoader={showLoader} />
      {name === "dashboardPendingRequest" ? (
        <>
          {filteredERList && filteredERList.length > 0 ? (
            filteredERList.map((item, index) => (
              <div className="d-flex align-items-center mb-5" key={index}>
                <div className="symbol symbol-30 symbol-circle mr-5">
                  <img
                    src={
                      item?.profilePicUrl
                        ? process.env.REACT_APP_BACKEND +
                          "/images/" +
                          item?.profilePicUrl
                        : media.user
                    }
                    alt="img"
                  />
                </div>
                <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                  <span className="font-size-lg mb-1 text-dark-75">
                    {item?.empName}
                  </span>
                  <span className="text-dark-grey-custom font-size-md">
                    {item?.departmentID}
                  </span>
                </div>
                <div className="ml-2 text-dark-custom font-size-md font-weight-boldest">
                  ₹ {item?.totalReimbursement}
                </div>
              </div>
            ))
          ) : (
            <center>No data found</center>
          )}
        </>
      ) : (
        <>
          {currentPath !== "dashboard" && (
            <Search
              className="table-search"
              placeholder="Search EmpID, Business Purpose & Amount.."
              onSearch={(value) => setSearchText(value)}
              onChange={(e) => setSearchText(e.target.value)}
            />
          )}
          <Table
            columns={columns}
            dataSource={searchFilterData}
            pagination={currentPath === "dashboard" ? false : pagination}
            className="border table-style"
            onChange={(e) => {
              getPendingRequestsList("pagination", e);
            }}
          />
        </>
      )}
    </>
  );
}
