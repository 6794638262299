import React, { useEffect, useRef, useState } from "react";
import NotificationPanel from "../QuickPanel/Notification";
import media from "../../media";
import { useMsal } from "@azure/msal-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  searchInfo,
  clearRedux,
  emailVerificationSuccess,
} from "../../../store/actions";
import HandleSearch from "../../../utils/CommonSearchComponent";
import APICaller from "../../../utils/APICaller";

import useCurrentPath, { decryptData } from "../../Constants";
import { store } from "../../../store";
import { message } from "antd";
import moment from "moment";

export default function TopHeader() {
  //Logout Button
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const handleDropdownClick = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const handleOutsideClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setDropdownOpen(false);
    }
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = useCurrentPath();
  const { instance } = useMsal();
  const navigate = useNavigate();

  const [showLoader, setShowLoader] = useState(true);
  const [list, setList] = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });

  let storeRole = useSelector((state) => state.auth.role);

  let storeToken = useSelector((state) => state.auth.accessToken);

  let storeLIUId = useSelector((state) => state.auth.lIUId);

  let storeUserInfo = useSelector((state) => state.auth.userInfo);
  storeUserInfo = decryptData(storeUserInfo);

  const storeProfileImage = storeUserInfo?.user?.profilePicUrl;

  const [nCount, setNCount] = useState("");

  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );

  const getNotificationList = () => {
    const endpoint = "/notifications/findAll";
    const method = "POST";
    const payload = {
      lIUId: storeLIUId,
      fetchWithoutPagination: "yes",
      page: 1,
      pageSize: 4,
      isRecent: true,
    };
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    ).then((response) => {
      const temp =
        response &&
        response?.response.map((records, index) => {
          if (records?.isRead === "0") {
            return {
              id: records._id,
              title: records.title,
              excerpt: records.excerpt,
              assignedBy: records.assignedBy,
              assignedTo: records.assignedTo && records.assignedTo,
              message: records?.message,
              type: records?.type,
              isRead: records?.isRead === "0" ? "Un-read" : "Read",
              createdAt: records.createdAt
                ? moment
                    .utc(records.createdAt)
                    .local()
                    .format("DD-MM-YY hh:mm A")
                : "",
            };
          }
        });
      setStoreList(temp || []);
    });
  };

  const notificationCount = () => {
    const endpoint = "/notificationCount";
    const method = "POST";
    const payload = {
      role: storeRole,
      lIUId: storeLIUId,
    };
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        setNCount(response?.count);
      })
      .catch((error) => {
        if (error.data && error.data.message) {
          message.error(error.data.message);
        }
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      // Run the function immediately
      if (storeRole !== process.env.REACT_APP_SROLE) {
        notificationCount();
        getNotificationList();
      }

      const intervalId = setInterval(() => {
        if (storeRole !== process.env.REACT_APP_SROLE) {
          notificationCount();
          getNotificationList();
        }
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [storeRole, storeToken, isLoggedIn]);

  //Logout Button
  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownOpen]);

  const handleSearch = async (e) => {
    const searchIn = location.pathname.replace("/", "");

    const callActionFrom = "searchBar";

    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
    };

    const response = await HandleSearch(
      storeRole,
      storeToken,
      storeLIUId,
      storeAccessTokenExpiresOn,
      searchIn,
      callActionFrom,
      allStates,
      ""
    );
    if (response) {
      // Update local state after fetching data
      const decryptedData = store.getState().auth.list[currentPath + "_info"];
      //const decryptedData = data ? decryptData(data) : [];

      setStoreList(decryptedData);

      setShowLoader(false);
    }
  };

  const navigateSignIn = () => {
    navigate("/", { replace: true });
  };

  const handleSignOut = async () => {
    try {
      // Log the user out using the appropriate method.
      await instance.logoutPopup();

      // Clear the Redux state using the dispatch function.
      dispatch(clearRedux());

      // Navigate the user to the sign-in page after logout.
      navigateSignIn();
    } catch (error) {}
  };

  return (
    <>
      <div id="kt_header" className="header header-fixed border-bottom">
        <div className="container-fluid d-flex align-items-center justify-content-end">
          {/* <div
            className="header-menu-wrapper header-menu-wrapper-left w-350px"
            id="kt_header_menu_wrapper"
          >
            <div
              id="kt_header_menu"
              className="header-menu header-menu-mobile header-menu-layout-default"
            >
              <div className="input-group">
                <span className="App bg-transparent border-0 input-group-text pr-0">
                  <i className="flaticon2-search-1 font-size-h5"></i>
                </span>
                <input
                  type="text"
                  className="border-0 font-size-lg font-weight-bold form-control"
                  placeholder="Type to search"
                  aria-label="Username"
                  onChange={handleSearch}
                />
              </div>
            </div>
          </div> */}

          <div className="topbar">
            {storeRole !== process.env.REACT_APP_SROLE && (
              <div className="topbar-item mr-5">
                <div
                  className="btn btn-icon btn-clean btn-lg mr-1 position-relative"
                  id="kt_quick_panel_toggle"
                  onClick={() => {
                    document
                      .getElementById("kt_quick_panel")
                      .classList.add("offcanvas-on");
                    document
                      .querySelector("body")
                      .classList.add("overflow-hidden");
                  }}
                >
                  <span className="label label-danger ml-2 position-absolute right-0 top-0">
                    {nCount ? nCount : 0}
                  </span>
                  <span className="svg-icon svg-icon-xl svg-icon-primary">
                    <i className="flaticon2-bell-4 text-dark-75"></i>
                  </span>
                </div>
              </div>
            )}
            <NotificationPanel storeList={storeList} nCount={nCount} />
            <div className="dropdow cursor-pointer" ref={dropdownRef}>
              <div className="topbar-item" onClick={handleDropdownClick}>
                <div className="symbol symbol-30 symbol-lg-40 symbol-circle">
                  <img
                    alt="Pic"
                    className="w-100px"
                    src={
                      storeProfileImage
                        ? process.env.REACT_APP_BACKEND +
                          "/images/" +
                          storeProfileImage
                        : media.user
                    }
                  />
                </div>
              </div>

              <div
                className={`borders dropdown-menu p-0 m-0 ${
                  dropdownOpen ? "login-active" : ""
                }`}
              >
                <ul className="p-0 mb-0 list-unstyled">
                  <li
                    className="menu-item p-2 px-5 custom-menu-item-hover"
                    aria-haspopup="true"
                    onClick={handleSignOut}
                  >
                    <span className="cursor-pointer font-size-lg font-weight-bolder">
                      Logout
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
