import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { decryptData, sideMenu } from "../../Constants";
import media from "../../media";
import { useSelector } from "react-redux";
import APICaller from "../../../utils/APICaller";
import { message } from "antd";

export default function Sidebar() {
  const location = useLocation();
  const activeMenu = location?.pathname;
  let storeRole = useSelector((state) => state.auth.role);

  let storeToken = useSelector((state) => state.auth.accessToken);

  let storeLIUId = useSelector((state) => state.auth.lIUId);

  let storeUserInfo = useSelector((state) => state.auth.userInfo);

  const decryptedUserInfo = useMemo(
    () => decryptData(storeUserInfo),
    [storeUserInfo]
  );
  const storeCanSendExpenseRequest =
    decryptedUserInfo?.user?.canSendExpenseRequest;

  const [eRcount, setERcount] = useState("");

  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );

  const expenseRequestsCount = () => {
    const endpoint = "/expenseRequestsCount";
    const method = "POST";
    const payload = {
      role: storeRole,
      lIUId: storeLIUId,
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        setERcount(response?.count);
      })
      .catch((error) => {
        // if (error.data && error.data.message) {
        //   message.error(error.data.message);
        // }
      });
  };

  useEffect(() => {
    if (isLoggedIn) {
      // Run the function immediately
      if (storeRole !== process.env.REACT_APP_EROLE) {
        expenseRequestsCount();
      }
      const intervalId = setInterval(() => {
        if (storeRole !== process.env.REACT_APP_EROLE) {
          expenseRequestsCount();
        }
      }, 5000);
      return () => clearInterval(intervalId);
    }
  }, [storeRole, storeToken, isLoggedIn]);

  return (
    <>
      <div
        className="aside aside-left aside-fixed d-flex flex-column flex-row-auto custom-aside-menu-style"
        id="kt_aside"
      >
        <div className="brand flex-column-auto" id="kt_brand">
          <Link to="/dashboard" className="brand-logo">
            <img alt="Logo" src={media.Logo} className="logo-style" />
          </Link>
        </div>
        {/*Except approver who can not send expense request can use this event*/}
        {storeRole !== process.env.REACT_APP_SROLE &&
          storeCanSendExpenseRequest === true && (
            <Link
              to="/add-expense"
              className="text-white btn font-size-lg font-weight-bold mt-8 mx-auto px-8 py-3 redbutton rounded-lg"
            >
              <i className="flaticon2-plus mr-2 text-white"></i> Add New Expense
            </Link>
          )}
        <div
          className="aside-menu-wrapper flex-column-fluid scrollbars"
          id="kt_aside_menu_wrapper"
        >
          <div
            id="kt_aside_menu"
            className="aside-menu"
            data-menu-vertical="1"
            data-menu-scroll="1"
            data-menu-dropdown-timeout="500"
          >
            <ul className="menu-nav">
              {sideMenu &&
                sideMenu[storeRole].map((menuItem, index) => {
                  const [key, value] = Object.entries(menuItem)[0]; // Extract key and value
                  return (
                    <li
                      key={key}
                      className={
                        activeMenu === key
                          ? "menu-item menu-item-active"
                          : "menu-item"
                      }
                    >
                      <Link className="menu-link" to={key}>
                        <span className="svg-icon menu-icon">{value.icon}</span>
                        <span className="menu-text">
                          {value.name}
                          {value.showCount === "yes" && (
                            <span className="label label-dark label-lg ml-2 text-white font-weight-bold">
                              {eRcount ? eRcount : 0}
                            </span>
                          )}
                        </span>
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
