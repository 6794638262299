import React, { useEffect, useState } from "react";
import { Form, message, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import HandleSearch from "../../utils/CommonSearchComponent";

import useCurrentPath, { decryptData } from "../../components/Constants";
import { store } from "../../store";
import APICaller from "../../utils/APICaller";
import CustomLoader from "../../components/CustomLoader";

const Notifications = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPath = useCurrentPath();
  const [form] = Form.useForm();

  let storeRole = useSelector((state) => state.auth.role);
  //storeRole = storeRole ? decryptData(storeRole) : null;

  let storeToken = useSelector((state) => state.auth.accessToken);
  //storeToken = storeToken ? decryptData(storeToken) : null;

  let storeLIUId = useSelector((state) => state.auth.lIUId);
  //storeLIUId = storeLIUId ? decryptData(storeLIUId) : null;

  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );
  // Initialize storeList with a default value
  const [storeList, setStoreList] = useState([]);
  const storePagination = useSelector((state) => state.auth.pagination);

  const [pagination, setPagination] = useState({ current: 1, pageSize: 50 });
  const [list, setList] = useState([]),
    [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  const columns = [
    {
      title: "Sr. No.",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Assigned By",
      dataIndex: "assignedBy",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Message",
      dataIndex: "message",
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: "Status",
      dataIndex: "isRead",
      render: (text) => (
        <span
          className={`notificationStatus ${text.toLowerCase()}-notificationStatus`}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Link to={`/${currentPath}/${record?.key}`}>
          <i className="flaticon-eye icon-lg text-grey-custom"></i>
        </Link>
      ),
    },
  ];

  const getNotificationList = async (source = "", pagination = "") => {
    setShowLoader(true);

    const searchIn = location.pathname.replace("/", "");
    const callActionFrom = source ? source : "";
    setSelectedRowKeys([]);
    const allStates = {
      showLoader: showLoader,
      setShowLoader: setShowLoader,
      pagination: pagination
        ? pagination
        : { current: 1, page: 1, pageSize: 50 },
      setPagination: setPagination,
      list: list,
      setList: setList,
      fetchWithoutPagination: "yes",
      isRecent: false,
    };

    await HandleSearch(
      storeRole,
      storeToken,
      storeLIUId,
      storeAccessTokenExpiresOn,
      searchIn,
      callActionFrom,
      allStates,
      ""
    );
    // Update local state after fetching data
    const decryptedData = store.getState().auth.list[currentPath + "_info"];
    //const decryptedData = data ? decryptData(data) : [];

    setStoreList(decryptedData);

    setShowLoader(false);
  };

  useEffect(() => {
    getNotificationList();
  }, []);

  const handleReadAll = () => {
    setShowLoader(true);
    const endpoint = "/notifications/readAll";
    const method = "POST";
    const payload = {
      role: storeRole,
      lIUId: storeLIUId,
      action: "readAll",
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        message.success("All notification are read.");
        getNotificationList();
        setShowLoader(false);
      })
      .catch((error) => {
        // if (error.data && error.data.message) {
        //   message.error(error.data.message);
        // }
        setShowLoader(false);
      });
  };

  let totalCount =
    storeList && storeList.filter((list) => list.isRead === "Un-read");
  totalCount = totalCount && totalCount.length;

  return (
    <>
      <div className="container">
        <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-0">
          <div className="border-2 card-header p-5">
            <div className="align-items-center d-flex justify-content-between">
              <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark text-size-16">
                Notifications
              </h3>
              {totalCount > 0 && (
                <button
                  className="text-white btn font-size-lg font-weight-bold px-3 py-1 redbutton rounded-lg"
                  onClick={handleReadAll}
                >
                  Read All
                </button>
              )}
            </div>
          </div>

          <div className="p-3" id="alltable-size">
            <CustomLoader showLoader={showLoader} />
            <Table
              columns={columns}
              dataSource={storeList}
              pagination={storePagination}
              onChange={(e) => {
                getNotificationList("pagination", e);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Notifications;
