import { useMsal } from "@azure/msal-react";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import { useDispatch, useSelector } from "react-redux";
import {
  clearRedux,
  emailVerificationSuccess,
  loginSuccess,
} from "../../store/actions";
import axios from "axios";
import APICaller from "../../utils/APICaller";
import { message } from "antd";
import { encryptData } from "../../components/Constants";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const hasCheckedServerStatus = useRef(false); // To track if the server status has been checked
  const [serverStatus, setServerStatus] = useState("Error");
  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );
  const navigateHome = (role) => {
    navigate("/dashboard");
  };

  const handleSignIn = async () => {
    dispatch(clearRedux());
    try {
      const loginResponse = await instance.loginPopup({
        scopes: ["user.read", "offline_access"],
      });

      if (loginResponse) {
        postToken(loginResponse);
      }
    } catch (error) {}
  };

  //Declaring API for Getting User Reward
  const postToken = (loginResponse) => {
    const endpoint = "/validateToken";
    const method = "POST";
    const payload = {
      auth: loginResponse.accessToken,
    };
    APICaller("", loginResponse.accessToken, endpoint, method, payload, "")
      .then((response) => {
        if (response.statusCode === 200) {
          response.statusCode === 200 && validateEmail(loginResponse);
        } else {
          // Handle non-2xx status codes
          // You might want to log or handle the error accordingly
          console.error("Non-2xx status code:", response);
        }
      })
      .catch((error) => {});
  };

  //Validating MS Detail in the DB
  const validateEmail = async (loginResponse) => {
    //Saving user device info
    const response = await axios.get(process.env.REACT_APP_IPIFY);
    let deviceInfo = "";

    if (response) {
      const userAgent = navigator.userAgent; // Accessing navigator
      const screenWidth = window.innerWidth; // Screen width
      const screenHeight = window.innerHeight; // Screen height
      const ip = response.data.ip;
      deviceInfo = {
        userAgent,
        screenWidth,
        screenHeight,
        ip,
      };
    }
    const token = loginResponse.accessToken;
    const email = loginResponse?.account?.username;

    const getAccessToken = loginResponse.accessToken;
    const getAccessTokenExpiresOn = new Date(loginResponse.expiresOn).getTime();
    const getRefreshToken = loginResponse.account.refreshToken;
    const getRefreshTokenExpiresOn = new Date(
      loginResponse.account.refreshTokenExpiresOn
    ).getTime();

    //Verifying Email received from login in Database
    const endpoint = "/emailVerification";
    const method = "POST";
    const payload = {
      email: email,
      device: deviceInfo,
    };
    APICaller("", token, endpoint, method, payload, "")
      .then((response) => {
        response.statusCode === 200 &&
          // Dispatch action to update Redux state with tokens
          dispatch(
            loginSuccess({
              accessToken: getAccessToken,
              accessTokenExpiresOn: getAccessTokenExpiresOn,
              refreshToken: getRefreshToken,
              refreshTokenExpiresOn: getRefreshTokenExpiresOn,
            })
          );
        dispatch(
          emailVerificationSuccess({
            isLoggedIn: true,
            lIUId: response?.respnse?.user?._id,
            role: response?.respnse?.user?.roleID?.name,
            name: response?.respnse?.user?.name,
            empID: response?.respnse?.user?.empID,
            profileImage: response?.respnse?.user?.profilePicUrl,
            userInfo: encryptData({
              user: response?.respnse?.user,
              userDepartment: response?.respnse?.departmentNames,
            }),
          })
        );
        navigateHome(response?.respnse?.roleID?.name);
      })
      .catch(async (error) => {
        showNotification(
          "The email you used does not exist in our system. Please logout and try logging in with a different account."
        );

        // Trigger the MSAL login popup again to allow the user to log in with a different email
        clearAndLogout();
      });
  };

  //Show notification using Ant Design's notification component
  const showNotification = (errorMsg, moreThan = "no") => {
    message.error({
      content: (
        <div style={{ whiteSpace: "pre-line" }}>
          {moreThan === "yes" ? (
            <strong>Errors occurred:</strong>
          ) : (
            <strong>An error occurred:</strong>
          )}
          <p>{errorMsg}</p> {/* This ensures line breaks are respected */}
        </div>
      ),
      duration: 5, // duration in seconds
      className: "error-message", // Add any custom styles you need
    });
  };

  //Trigger the MSAL login popup again
  const clearAndLogout = async () => {
    try {
      // Clear the Redux state using the dispatch function.
      dispatch(clearRedux());

      // Log the user out
      await instance.logoutPopup();

      const newLoginResponse = await instance.loginPopup({
        scopes: ["user.read", "offline_access"],
      });

      if (newLoginResponse) {
        postToken(newLoginResponse);
      }
    } catch (error) {
      console.error("Logout/Login failed:", error);
      // Optionally, handle login errors (e.g., show an error message to the user)
    }
  };

  //Server is running or not
  const checkServerStatus = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BACKEND + "/checkServerStatus"
      );

      setServerStatus(response.data.status);
    } catch (error) {
      navigate("/underMaintenance");
      setServerStatus("Error");
    }
  };

  useEffect(() => {
    if (!hasCheckedServerStatus.current) {
      checkServerStatus();
      hasCheckedServerStatus.current = true; // Mark as checked
    }
  }, []);

  return (
    <>
      <div className="login_box">
        <div className="login-wrap">
          <div className="login-page-logo">
            <img src="/logo.svg" style={{ width: "200px" }} alt="logo" />
            <span> &nbsp;</span>
            <span> &nbsp;</span>
            <span className="separator"></span>
            <span className="expense_logo">
              &nbsp;Expense <br />
              Management System
            </span>
          </div>
          <span
            className="sign_in"
            style={{ textAlign: "center" }}
            onClick={() => {
              handleSignIn();
            }}
          >
            Sign in with Microsoft
          </span>
        </div>
      </div>
    </>
  );
}
