import {
  Row,
  Col,
  DatePicker,
  Button,
  message,
  Typography,
  Modal,
  Badge,
  Card,
  List,
  Tooltip,
} from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import APICaller from "../../utils/APICaller";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { decryptData } from "../../components/Constants";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import CustomLoader from "../../components/CustomLoader";
import dayjs from "dayjs";

export default function ExpenseListingDetail() {
  const { RangePicker } = DatePicker;
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname?.split("/")?.[2];

  let storeRole = useSelector((state) => state.auth.role);

  const decryptedEmpID = useSelector((state) => state.auth.empID);
  //const decryptedEmpID = storeEmpID ? decryptData(storeEmpID) : null;

  let storeToken = useSelector((state) => state.auth.accessToken);

  let storeLIUId = useSelector((state) => state.auth.lIUId);

  let storeUserInfo = useSelector((state) => state.auth.userInfo);
  storeUserInfo = decryptData(storeUserInfo);

  // Initialize storeList with a default value
  const [showLoader, setShowLoader] = useState(false);
  const [selectedManager, setSelectedManager] = useState(null);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState([]);

  const [departments, setDepartments] = useState([]);
  const [projectCodes, setProjectCodes] = useState([]);
  const [expenseCodes, setExpenseCodes] = useState([]);
  const [expenseDetail, setExpenseDetail] = useState();
  const [errorMsg, setErrorMsg] = useState({ expensesDetails: [] }),
    [errors, setErrors] = useState([]);
  const [mediaList, setMediaList] = useState();

  const [allApprovers, setAllApprovers] = useState();
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [paymentPaidOn, setPaymentPaidOn] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [mediaItem, setMediaItem] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );

  const [rows, setRows] = useState([
    {
      date: "",
      description: "",
      departmentID: "",
      expenseCodeID: "",
      cost: "",
      mediaInfo: [],
    },
  ]);

  const handleChange = (index, field, value) => {
    // Create a copy of the mediaInfo array for the respective detail

    let updatedRows = [...rows];

    if (!updatedRows[index]) {
      message.error(`Row at index ${index} does not exist!`);
      return; // Exit early if the row at the given index does not exist
    }
    if (field === "departmentID" || field === "expenseCodeID") {
      updatedRows[index][field] = value?.value;
    } else {
      updatedRows[index][field] = value;
    }
    setRows(updatedRows);

    // Update the mediaInfo in the specific detail
    const updatedDetails = (expenseDetail?.expensesDetails || []).map(
      (item, idx) => {
        if (idx === index) {
          return {
            ...item,
            [field]: updatedRows[index][field],
          };
        }
        return item;
      }
    );
    // Update the state or parent component's state with the updated expenseDetail
    setExpenseDetail((prevState) => ({
      ...prevState,
      expensesDetails: updatedDetails,
    }));
  };

  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  // GetProjectCodes
  const getProjectCodes = () => {
    const endpoint = "/project-code/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [{ label: "N/A", value: null }];
        response?.response?.map((projectCode, index) => {
          temp.push({
            label: projectCode?.code + " - " + projectCode?.companyName,
            value: projectCode?._id,
          });
        });

        setProjectCodes(temp);
      })
      .catch((error) => {});
  };

  // GetDepartments
  const getDepartments = () => {
    const endpoint = "/departments/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response?.response?.map((department, index) => {
          temp.push({ label: department?.name, value: department?._id });
        });
        setDepartments(temp);
      })
      .catch((error) => {});
  };

  // GetExpenseCodes
  const getExpenseCodes = () => {
    const endpoint = "/expense-code/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [];
        response?.response?.map((expenseCode, index) => {
          temp.push({
            label: expenseCode?.costCode + " - " + expenseCode?.name,
            value: expenseCode?._id,
          });
        });
        setExpenseCodes(temp);
      })
      .catch((error) => {});
  };

  // GetUsers
  const getUsers = async () => {
    try {
      const endpoint = "/users/findAll";
      const method = "POST";
      const payload = {
        fetchWithoutPagination: "yes",
      };

      const response = await APICaller(
        storeRole,
        storeToken,
        endpoint,
        method,
        payload,
        storeAccessTokenExpiresOn
      );
      const approvers =
        response &&
        response?.response.filter((item) => item.level2Approver === true);
      setUsers(approvers);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const getExpenseDetail = async () => {
    try {
      const endpoint = `/list-of-expense/${id}`;
      const method = "GET";
      const payload = {};
      const response = await APICaller(
        storeRole,
        storeToken,
        endpoint,
        method,
        payload,
        storeAccessTokenExpiresOn
      );
      setExpenseDetail(response);

      // Transform the response.expensesDetails to match the rows structure
      if (response?.expensesDetails) {
        const rowsData = response.expensesDetails.map((detail) => ({
          date: detail.date || "", // Map the appropriate fields
          description: detail.description || "",
          departmentID: detail.departmentID || "",
          expenseCodeID: detail.expenseCodeID || null,
          cost: detail.cost || "",
          mediaInfo: detail.mediaInfo || [], // If mediaInfo exists
        }));

        // Set the transformed data into the rows state
        setRows(rowsData);
      }

      // const temp = users && users
      //   .filter((user) => user?._id !== response?.managerID?._id)
      //   .map((user) => ({ label: user?.name, value: user?._id }));

      // setAllApprovers(temp);
    } catch (error) {
      console.error("Error fetching expense details:", error);
    }
  };

  useEffect(() => {
    getExpenseDetail();
  }, [users]);
  useEffect(() => {
    getUsers();
    getProjectCodes();
    getExpenseCodes();
    getDepartments();
  }, []);

  // Disable dates after the current date
  const disableFutureDates = (current) => {
    const startDate = moment(current).subtract(1, "month").startOf("day");

    // Calculate the end date for the next financial year
    const currentYear = moment().year();
    const nextFinancialYearEnd = moment()
      .month(2)
      .date(31)
      .year(
        currentYear + (moment().isAfter(moment().month(2).date(31)) ? 1 : 0)
      )
      .endOf("day");

    // Disable dates outside the range two months ago from today to next financial year's 31st March
    return (
      current &&
      (current.isBefore(startDate) || current.isAfter(moment().endOf("day")))
    );
  };

  const disableFutureDatesForPaymentPaidOn = (current) => {
    const startDate = moment(current).startOf("day");

    // Calculate the end date for the next financial year
    const currentYear = moment().year();
    const nextFinancialYearEnd = moment()
      .month(2)
      .date(31)
      .year(
        currentYear + (moment().isAfter(moment().month(2).date(31)) ? 1 : 0)
      )
      .endOf("day");

    // Disable dates outside the range two months ago from today to next financial year's 31st March
    return (
      current &&
      (current.isBefore(startDate) || current.isAfter(nextFinancialYearEnd))
    );
  };

  const images =
    expenseDetail?.expensesDetails?.flatMap((detail) =>
      detail.mediaInfo
        ?.filter(
          (file) =>
            file?.savedFileType === "jpeg" ||
            file?.savedFileType === "jpg" ||
            file?.savedFileType === "png"
        )
        ?.map(
          (file) => process.env.REACT_APP_BACKEND + "/" + file?.savedFilepath
        )
    ) || [];

  const handleImageClick = (filePath) => {
    const index = images.findIndex((img) => img === filePath);
    if (index !== -1) {
      setPhotoIndex(index);
      setIsOpen(true);
    }
  };
  const parseToFloat = (value) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  };
  const subTotal = rows.reduce(
    (total, row) => total + parseToFloat(row.cost),
    0
  );

  const totalReimbursement =
    !rows?.[0]?.cost && expenseDetail?.totalReimbursement
      ? expenseDetail?.totalReimbursement.toFixed(2)
      : subTotal.toFixed(2);

  const approversApproved =
    expenseDetail &&
    expenseDetail?.approvers.filter((expenseValue) => {
      return (
        expenseValue?.status ===
        process.env.REACT_APP_APPROVED_STATUS.toUpperCase()
      );
    }).length;
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    setIsModalVisible(false);
    setShowLoader(true);
    setErrors({ fileSizeExceed: "", fileCountExceed: false });
    const endpoint = `/list-of-expense/${id}`;
    const method = "PUT";
    const payload = {
      role: storeRole,
      lIUId: storeLIUId,
      empName: expenseDetail?.empName,
      empID: parseInt(expenseDetail?.empID),
      departmentID: expenseDetail?.departmentID?._id,
      expensePeriodFromDate: expenseDetail?.expensePeriodFromDate,
      expensePeriodToDate: expenseDetail?.expensePeriodToDate,
      businessPurpose: expenseDetail?.businessPurpose,
      expensesDetails: rows?.[0]?.cost ? rows : expenseDetail?.expensesDetails,
      paymentPaidOn: paymentPaidOn,
      totalReimbursement: totalReimbursement,
      historySource: "backend",
      historyAction:
        paymentPaidOn != null
          ? `Admin updated payment status: ${paymentPaidOn}`
          : expenseDetail?.status ===
            process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
          ? "sentback-resubmitted"
          : "update",
      historyUserID: storeLIUId,
      status: paymentPaidOn
        ? expenseDetail?.status
        : expenseDetail?.status ===
          process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
        ? process.env.REACT_APP_RESUBMITTED_STATUS.toUpperCase()
        : expenseDetail?.status,
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        message.success(
          response && response?.paymentPaidOn !== null
            ? "Expense updated successfully."
            : "Expense resubmitted successfully."
        );
        setExpenseDetail({ ...expenseDetail });
        setRows([
          {
            date: "",
            description: "",
            departmentID: "",
            expenseCodeID: "",
            cost: "",
            mediaInfo: [],
          },
        ]);
        setShowLoader(false);
        navigate(`/${process.env.REACT_APP_DASHBOARD_EL_SEARCH_IN}`);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };
  const onFinish = (e) => {
    e.preventDefault();
    if (
      storeRole === process.env.REACT_APP_ADROLE &&
      expenseDetail?.status ===
        process.env.REACT_APP_APPROVED_STATUS.toUpperCase() &&
      paymentStatus === false
    ) {
      //!approversApproved
      message.error("Payment Status is Mandatory");
      setErrorMsg({
        ...errorMsg,
        paymentStatus: "Payment Status is Mandatory",
      });
    } else if (
      storeRole === process.env.REACT_APP_ADROLE &&
      paymentStatus === true &&
      paymentPaidOn === null
    ) {
      message.error("Payment Paid On date is Mandatory");
      setErrorMsg({
        ...errorMsg,
        paymentPaidOn: "Payment Paid On date is Mandatory",
      });
    } else if (errors?.fileSizeExceed === "single") {
      message.error("File size exceeds 5 MB.");
    } else if (errors?.fileSizeExceed === "total") {
      message.error("The total size of all files must not exceed 10 MB.");
    } else if (errors?.fileCountExceed) {
      message.error(
        "You cannot only upload one attachment so upload accordingly."
      );
    } else if (totalReimbursement.indexOf("-") !== -1) {
      message.error(
        totalReimbursement +
          " amount is with you. If you have any concern then please contact with Finance Department!!"
      );
    } else {
      setIsModalVisible(true);
    }
  };

  const hasViewAccess =
    storeRole === process.env.REACT_APP_AROLE
      ? expenseDetail &&
        expenseDetail?.approvers.filter((expenseValue) => {
          return expenseValue?.userID == storeLIUId;
        })
      : "";

  useEffect(() => {
    // Only check conditions once the data is available , itself || manager || admin after manager approver
    if (storeRole && expenseDetail) {
      if (decryptedEmpID !== expenseDetail?.empID) {
        if (hasViewAccess && hasViewAccess.length === 0) {
          setShouldRedirect(true);
        }
      }
      setIsLoading(false);
    }
  }, [storeRole, expenseDetail]);

  if (isLoading) {
    return <CustomLoader showLoader={showLoader} />; // Show a loading state while checking conditions
  }

  if (shouldRedirect) {
    return <Navigate to="/dashboard" />;
  }

  // Function to remove media
  const handleMediaRemove = (detail, index) => {
    // Show confirmation modal
    Modal.confirm({
      title: "Confirm Removal",
      content: "Are you sure you want to remove this media item?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        // Create a copy of the mediaInfo array for the respective detail
        let updatedMediaInfo = [...detail.mediaInfo];
        // Remove the selected file from the array (e.g., removing the first item for simplicity)
        updatedMediaInfo.splice(0, 1); // You can adjust the index based on your logic

        // Check if mediaInfo is empty after removal and set it explicitly to an empty array
        if (updatedMediaInfo.length === 0) {
          updatedMediaInfo = []; // Set to empty array if no files are left
          //updateRowFiles(index, fileSources, updatedRows);
        }
        // Update the mediaInfo in the specific detail
        const updatedDetails = expenseDetail.expensesDetails.map(
          (item, idx) => {
            if (idx === index) {
              return {
                ...item,
                mediaInfo: updatedMediaInfo,
              };
            }
            return item;
          }
        );

        // Update the state or parent component's state with the updated expenseDetail
        setExpenseDetail((prevState) => ({
          ...prevState,
          expensesDetails: updatedDetails,
        }));
        setRows((prevRows) =>
          prevRows.map((row, idx) => {
            if (idx === index) {
              return {
                ...row,
                mediaInfo: updatedMediaInfo, // Update mediaInfo for the specific row
              };
            }
            return row; // Keep other rows unchanged
          })
        );
        setMediaItem((prevState) => ({
          ...prevState,
          ["item_" + index]: "removed",
        }));
      },
      onCancel: () => {
        // Optionally, handle cancellation logic here
        console.log("Removal cancelled");
      },
    });
  };

  const handleFileChange = (index, event) => {
    const files = event.target.files;
    if (files.length && files.length > 1) {
      setErrors({ fileCountExceed: true });
      message.error(
        `Only one file can be uploaded. Allowed file type: .jpg, .png, max size: 4MB.`
      );
    } else {
      setErrors({ fileCountExceed: false });

      let totalSize = 0;

      const maxSingleFileSize = 4 * 1024 * 1024; // 5 MB in bytes

      const updatedRows = [...rows];

      const arrayBufferToBase64 = (buffer) => {
        let binary = "";
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      };

      const loadFile = (file) => {
        const reader = new FileReader();
        const fileType = file.type;
        totalSize += file.size;

        if (file.size > maxSingleFileSize) {
          setErrors({ fileSizeExceed: "single" });
          message.error(`File "${file.name}" exceeds 4 MB.\n`);
        } else {
          reader.onload = (e) => {
            const newFileData = {
              src: fileType.startsWith("image/")
                ? e.target.result
                : `data:${fileType};base64,${arrayBufferToBase64(
                    e.target.result
                  )}`,
              name: file.name,
              type: fileType,
            };
            const fileSources = [newFileData];
            // Ensure the new file is set for the correct row
            if (fileSources.length <= 1) {
              updateRowFiles(index, fileSources, updatedRows);
            }

            // Update only the mediaInfo for the specific index
            const updatedDetails = expenseDetail.expensesDetails.map(
              (item, idx) => {
                if (idx === index) {
                  const updatedMediaInfo = [...item.mediaInfo];

                  // Replace or add the new file, assuming only one file can be uploaded
                  if (updatedMediaInfo.length > 0) {
                    updatedMediaInfo[0] = newFileData; // Replace the first item
                  } else {
                    updatedMediaInfo.push(newFileData); // Add the new file
                  }

                  return {
                    ...item,
                    mediaInfo: updatedMediaInfo, // Update mediaInfo for the current index
                  };
                }
                return item; // Keep other items intact
              }
            );

            // Update the state with the updated mediaInfo array
            setExpenseDetail((prevState) => ({
              ...prevState,
              expensesDetails: updatedDetails,
            }));
          };

          if (fileType.startsWith("image/")) {
            reader.readAsDataURL(file); // For images
          } else {
            reader.readAsArrayBuffer(file); // For non-image files
          }
        }
      };

      Array.from(files).forEach(loadFile);
    }
  };
  const updateRowFiles = (index, fileSources, updatedRows) => {
    if (!updatedRows[index].mediaInfo) {
      updatedRows[index].mediaInfo = [];
    }
    updatedRows[index].mediaInfo = fileSources;
    setRows(updatedRows);
  };

  const getStatusBadge = (status, reason) => {
    switch (status) {
      case "APPROVED":
        return (
          <Badge
            status="success"
            text="Approved"
            icon={<CheckCircleOutlined />}
            className="approved-badge"
          />
        );
      case "PENDING":
        return (
          <Badge
            status="warning"
            text="Pending Approval: Request Sent"
            icon={<ClockCircleOutlined />}
            className="pending-badge"
          />
        );
      case "CANCELED":
        return (
          <Badge
            status="error"
            text={
              <span>
                <strong>Canceled</strong>: {reason}
              </span>
            }
            icon={<CloseCircleOutlined />}
            className="canceled-badge"
          />
        );
      case "SENTBACK":
        return (
          <Badge
            status="processing"
            text={
              <span>
                <strong>Sent Back</strong>: {reason}
              </span>
            }
            icon={<RedoOutlined />}
            className="sentBack-badge"
          />
        );
      default:
        return (
          <Badge
            status="default"
            text="Awaiting previous Approver's Approval"
            icon={<ExclamationCircleOutlined />}
          />
        );
    }
  };
  return (
    <>
      <div className="container">
        <CustomLoader showLoader={showLoader} />
        <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-0">
          <div className="border-2 card-header p-6">
            <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark font-size-lg">
              Expense Details
            </h3>
          </div>
          <div className="card-body p-3">
            <form
              layout="vertical"
              autoComplete="off"
              className="form-validation-start"
              onSubmit={onFinish}
            >
              <Row gutter={[24, 24]}>
                <Col sm={8}>
                  <label className="mb-3" name="empID">
                    Employee ID
                  </label>

                  <input
                    placeholder="Id"
                    className="form-control h-45px rounded-md"
                    defaultValue={expenseDetail?.empID}
                    disabled
                  />
                </Col>

                <Col sm={8}>
                  <label className="mb-3" name="empName">
                    Employee Name
                  </label>

                  <input
                    defaultValue={expenseDetail?.empName}
                    disabled
                    placeholder="Employee Name"
                    className="form-control h-45px rounded-md"
                  />
                </Col>

                <Col sm={8}>
                  <label className="mb-3" name="departmentID">
                    Department
                  </label>

                  <input
                    placeholder="Department"
                    className="form-control h-45px rounded-md"
                    disabled
                    defaultValue={expenseDetail?.departmentID?.name}
                  />
                </Col>

                <Col sm={8}>
                  <label className="mb-3" htmlFor="expensePeriodFromDate">
                    <span className="mandatory-symbol">*</span>Expense Period:
                    (From - To)
                  </label>

                  {/* <RangePicker
                    disabled={
                      expenseDetail?.empID === decryptedEmpID &&
                      expenseDetail?.status ===
                        process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
                        ? false
                        : true
                    }
                    disabledDate={disableFutureDates}
                    className="form-control h-45px rounded-md d-inline-flex"
                    format="DD-MM-YYYY"
                    allowClear={true}
                    defaultValue={[
                      expenseDetail?.expensePeriodFromDate
                        ? dayjs(
                            expenseDetail?.expensePeriodFromDate,
                            "YYYY-MM-DD"
                          )
                        : null,
                      expenseDetail?.expensePeriodToDate
                        ? dayjs(
                            expenseDetail?.expensePeriodToDate,
                            "YYYY-MM-DD"
                          )
                        : null,
                    ]}
                    onChange={(date, dates) => {
                      const formattedDates = dates.map((date) =>
                        moment(date, "DD-MM-YYYY").format("YYYY-MM-DD")
                      );

                      if (
                        formattedDates &&
                        formattedDates[0] &&
                        formattedDates[1]
                      ) {
                        setExpenseDetail({
                          ...expenseDetail,
                          expensePeriodFromDate: formattedDates[0],
                          expensePeriodToDate: formattedDates[1],
                        });
                      }
                      
                      setErrorMsg({
                        ...errorMsg,
                        expensePeriod: date
                          ? ""
                          : "Please enter Expense Period: (From - To)",
                      });
                    }}
                  />

                  <small className="text-danger">
                    {errorMsg?.expensePeriod}
                  </small> */}

                  <RangePicker
                    disabledDate={disableFutureDates}
                    className="form-control h-45px rounded-md d-inline-flex"
                    format="DD-MM-YYYY"
                    value={[
                      expenseDetail?.expensePeriodFromDate
                        ? moment(expenseDetail?.expensePeriodFromDate)
                        : null,
                      expenseDetail?.expensePeriodToDate
                        ? moment(expenseDetail?.expensePeriodToDate)
                        : null,
                    ]}
                    disabled
                  />
                </Col>

                <Col sm={8}>
                  <label className="mb-3" name="projectCodeID">
                    <span className="mandatory-symbol">*</span>Project Code
                  </label>
                  <span className="form-control h-45px rounded-md customDisabled">
                    {expenseDetail.projectCodeID?.code &&
                    expenseDetail.projectCodeID?.companyName
                      ? expenseDetail.projectCodeID?.code +
                        " - " +
                        expenseDetail.projectCodeID?.companyName
                      : "N/A"}
                  </span>
                  {/* <Select
                    isDisabled={
                      expenseDetail?.empID === decryptedEmpID &&
                      expenseDetail?.status ===
                        process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
                        ? false
                        : true
                    }
                    className={
                      expenseDetail?.empID === decryptedEmpID &&
                      expenseDetail?.status ===
                        process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
                        ? "rounded-md h-45px"
                        : "select-isDisabled rounded-md h-45px"
                    }
                    placeholder="Select Project Code"
                    defaultValue={
                      expenseDetail?.projectCodeID
                        ? projectCodes.find(
                            (obj) =>
                              obj.value === expenseDetail?.projectCodeID?._id
                          ) || { label: "N/A", value: null }
                        : { label: "N/A", value: null }
                    }
                    onChange={(value) => {
                      setExpenseDetail({
                        ...expenseDetail,

                        projectCodeID: value ? value : "",
                      });

                      if (value === "") {
                        setErrorMsg({
                          ...errorMsg,
                          projectCodeID: "Project Code is Mandatory",
                        });
                      } else {
                        const updatedErrorMsg = { ...errorMsg };
                        delete updatedErrorMsg.projectCodeID;
                        setErrorMsg(updatedErrorMsg);
                      }
                    }}
                    options={projectCodes}
                  />

                  <small className="text-danger">
                    {errorMsg.projectCodeID}
                  </small> */}
                </Col>

                <Col sm={8}>
                  <label className="mb-3" name="businessPurpose">
                    <span className="mandatory-symbol">*</span>Business Purpose
                  </label>

                  <span className="form-control h-45px rounded-md customDisabled">
                    {expenseDetail?.businessPurpose}
                  </span>
                  {/* <input
                    disabled={
                      expenseDetail?.empID === decryptedEmpID &&
                      expenseDetail?.status ===
                        process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
                        ? false
                        : true
                    }
                    placeholder=" Business Purpose"
                    className="form-control h-45px rounded-md"
                    defaultValue={expenseDetail?.businessPurpose}
                    onChange={(e) => {
                      setExpenseDetail({
                        ...expenseDetail,

                        businessPurpose: e?.target?.value
                          ? e?.target?.value
                          : "",
                      });

                      if (e?.target.value === "") {
                        setErrorMsg({
                          ...errorMsg,
                          businessPurpose: "Business Purpose is Mandatory",
                        });
                      } else {
                        const updatedErrorMsg = { ...errorMsg };
                        delete updatedErrorMsg.businessPurpose;
                        setErrorMsg(updatedErrorMsg);
                      }
                    }}
                  />

                  <small className="text-danger">
                    {errorMsg?.businessPurpose}
                  </small> */}
                </Col>
              </Row>

              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <div className="border-top my-10"></div>
                </Col>
              </Row>

              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <div className="border-top my-10"></div>
                </Col>
              </Row>

              {/*Repeater Area*/}

              <div className="repeter border border-light-dark mb-5 p-3 repeter rounded-sm">
                <Row gutter={[12, 12]} className="m-0">
                  <Col sm={4} className="repeat-label">
                    <label className="mb-0">
                      <span className="mandatory-symbol">*</span>Date
                    </label>
                  </Col>
                  <Col sm={4} className="repeat-label">
                    <label className="mb-0">
                      <span className="mandatory-symbol">*</span>Description
                    </label>
                  </Col>
                  <Col sm={4} className="repeat-label">
                    <label className="mb-0">
                      <span className="mandatory-symbol">*</span>Department
                    </label>
                  </Col>
                  <Col sm={4} className="repeat-label">
                    <label className="mb-0">
                      <span className="mandatory-symbol">*</span>Expense Code
                    </label>
                  </Col>
                  <Col sm={4} className="repeat-label">
                    <label className="mb-0">
                      <span className="mandatory-symbol">*</span>Cost
                    </label>
                  </Col>
                  <Col sm={4} className="repeat-label">
                    <label className="mb-0">Upload</label>
                  </Col>
                </Row>

                {expenseDetail?.expensesDetails &&
                  expenseDetail?.expensesDetails.map((detail, index) => {
                    return (
                      <Row gutter={[12, 12]} className="mb-4" key={index}>
                        <Col sm={4}>
                          <DatePicker
                            format="DD-MM-YYYY"
                            disabledDate={disableFutureDates}
                            disabled={
                              expenseDetail?.empID === decryptedEmpID &&
                              expenseDetail?.status ===
                                process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
                                ? false
                                : true
                            }
                            className="form-control h-45px rounded-md"
                            defaultValue={[
                              detail?.date
                                ? dayjs(detail?.date, "YYYY-MM-DD")
                                : null,
                            ]}
                            allowClear={true}
                            onChange={(date) => {
                              const formattedDate = date.format("YYYY-MM-DD");
                              handleChange(index, "date", formattedDate);

                              const tempErrors = [...errorMsg.expensesDetails];

                              tempErrors[index] = {
                                ...tempErrors[index],

                                date: date ? "" : "Date is Mandatory",
                              };

                              setErrorMsg({
                                ...errorMsg,
                                expensesDetails: tempErrors,
                              });
                            }}
                          />

                          <small className="text-danger">
                            {errorMsg.expensesDetails[index]?.date}
                          </small>
                        </Col>

                        <Col sm={4}>
                          <input
                            disabled={
                              expenseDetail?.empID === decryptedEmpID &&
                              expenseDetail?.status ===
                                process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
                                ? false
                                : true
                            }
                            placeholder="Description"
                            className="form-control h-auto rounded-md text-capitalize"
                            defaultValue={detail?.description}
                            onChange={(e) => {
                              handleChange(
                                index,
                                "description",
                                e.target.value
                              );

                              const tempErrors = [...errorMsg.expensesDetails];
                              tempErrors[index] = {
                                ...tempErrors[index],
                                description: e.target.value
                                  ? ""
                                  : "Description is mandatory",
                              };
                              setErrorMsg({
                                ...errorMsg,
                                expensesDetails: tempErrors,
                              });
                            }}
                          />

                          <small className="text-danger">
                            {errorMsg.expensesDetails[index]?.description}
                          </small>
                        </Col>

                        <Col sm={4}>
                          <Select
                            isDisabled={
                              expenseDetail?.empID === decryptedEmpID &&
                              expenseDetail?.status ===
                                process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
                                ? false
                                : true
                            }
                            className={
                              expenseDetail?.empID === decryptedEmpID &&
                              expenseDetail?.status ===
                                process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
                                ? "h-45px rounded-md"
                                : "select-isDisabled h-45px rounded-md"
                            }
                            placeholder="Select Department"
                            defaultValue={{
                              label: detail?.departmentID?.name,

                              value: detail?.departmentID?._id,
                            }}
                            onChange={(value) => {
                              handleChange(index, "departmentID", value);
                              const tempErrors = [...errorMsg.expensesDetails];
                              tempErrors[index] = {
                                ...tempErrors[index],
                                departmentID: value?.value
                                  ? ""
                                  : "Department is Mandatory",
                              };
                              setErrorMsg({
                                ...errorMsg,
                                expensesDetails: tempErrors,
                              });
                            }}
                            options={departments}
                          />

                          <small className="text-danger">
                            {errorMsg.expensesDetails[index]?.departmentID}
                          </small>
                        </Col>

                        <Col sm={4}>
                          <Select
                            isDisabled={
                              expenseDetail?.empID === decryptedEmpID &&
                              expenseDetail?.status ===
                                process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
                                ? false
                                : true
                            }
                            className={
                              expenseDetail?.empID === decryptedEmpID &&
                              expenseDetail?.status ===
                                process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
                                ? "rounded-md h-45px"
                                : "select-isDisabled rounded-md h-45px"
                            }
                            placeholder="Select Expense Code"
                            defaultValue={expenseCodes.find(
                              (obj) => obj.value === detail?.expenseCodeID?._id
                            )}
                            onChange={(value) => {
                              handleChange(index, "expenseCodeID", value);
                              const tempErrors = [...errorMsg.expensesDetails];
                              tempErrors[index] = {
                                ...tempErrors[index],
                                expenseCodeID: value?.value
                                  ? ""
                                  : "Expense Code is Mandatory",
                              };
                              setErrorMsg({
                                ...errorMsg,
                                expensesDetails: tempErrors,
                              });
                            }}
                            options={expenseCodes}
                          />

                          <small className="text-danger">
                            {errorMsg.expensesDetails[index]?.expenseCodeID}
                          </small>
                        </Col>

                        <Col sm={4}>
                          <input
                            disabled={
                              expenseDetail?.empID === decryptedEmpID &&
                              expenseDetail?.status ===
                                process.env.REACT_APP_SENTBACK_STATUS.toUpperCase()
                                ? false
                                : true
                            }
                            placeholder="Cost"
                            className="form-control h-45px rounded-md"
                            defaultValue={detail?.cost}
                            onChange={(e) => {
                              const value = e.target.value;
                              handleChange(index, "cost", value);

                              // Regular expression to allow only numbers and decimals
                              const regex = /^[0-9]*\.?[0-9]*$/;

                              // Update the cost field only if it matches the regex
                              if (regex.test(value) || value === "") {
                                const tempErrors = [
                                  ...errorMsg.expensesDetails,
                                ];

                                tempErrors[index] = {
                                  ...tempErrors[index],

                                  cost: value ? "" : "Cost is Mandatory",
                                };

                                setErrorMsg({
                                  ...errorMsg,
                                  expensesDetails: tempErrors,
                                });
                              } else {
                                // Set error message if invalid input

                                const tempErrors = [
                                  ...errorMsg.expensesDetails,
                                ];

                                tempErrors[index] = {
                                  ...tempErrors[index],

                                  cost: "Only numbers and decimals are allowed",
                                };

                                setErrorMsg({
                                  ...errorMsg,

                                  expensesDetails: tempErrors,
                                });
                              }
                            }}
                          />

                          <small className="text-danger">
                            {errorMsg?.expensesDetails[index]?.cost}
                          </small>
                        </Col>

                        <Col sm={4}>
                          {detail?.mediaInfo.length > 0 &&
                            mediaItem?.["item_" + index] !== "removed" &&
                            detail?.mediaInfo.map((file, indx) => (
                              <div
                                className="symbol symbol-100 symbol-light mr-3 h-100 float-left border border-light-dark"
                                key={indx}
                              >
                                {expenseDetail?.empID === decryptedEmpID &&
                                  expenseDetail?.status ===
                                    process.env.REACT_APP_SENTBACK_STATUS.toUpperCase() && (
                                    <i
                                      className="ki ki-close upload_close"
                                      onClick={() =>
                                        handleMediaRemove(detail, index)
                                      }
                                    ></i>
                                  )}

                                {file?.savedFileType === "jpeg" ||
                                file?.savedFileType === "jpg" ||
                                file?.savedFileType === "png" ? (
                                  <SlideshowLightbox>
                                    <img
                                      className="rounded"
                                      src={`${process.env.REACT_APP_BACKEND}/${file?.savedFilepath}`}
                                      alt="media"
                                      width="100"
                                      height="100"
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleImageClick(
                                          `${process.env.REACT_APP_BACKEND}/${file?.savedFilepath}`
                                        )
                                      }
                                    />
                                  </SlideshowLightbox>
                                ) : (
                                  <div className="align-items-center d-flex h-100px justify-content-center w-100px">
                                    <a
                                      href={`${process.env.REACT_APP_BACKEND}/${file?.savedFilepath}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <i className="flaticon-file-2 icons"></i>
                                    </a>
                                  </div>
                                )}
                              </div>
                            ))}
                          {expenseDetail?.empID === decryptedEmpID &&
                            expenseDetail?.status ===
                              process.env.REACT_APP_SENTBACK_STATUS.toUpperCase() && (
                              <div>
                                {mediaItem?.["item_" + index] === "removed" && (
                                  <div className="symbol symbol-100 symbol-light mr-3 h-100 float-left">
                                    <input
                                      id={`fileInput${index}`}
                                      type="file"
                                      accept=".png, .jpg, .jpeg, .pdf, .docx, .doc"
                                      onChange={(event) =>
                                        handleFileChange(index, event)
                                      }
                                      style={{ display: "none" }}
                                    />
                                    <Button
                                      type="primary"
                                      onClick={() =>
                                        document
                                          .getElementById(`fileInput${index}`)
                                          .click()
                                      }
                                      className="redbutton d-block"
                                    >
                                      Upload
                                    </Button>
                                    {/* Typography section that synchronizes with mediaInfo */}
                                    {detail?.mediaInfo &&
                                    detail?.mediaInfo.length > 0 ? (
                                      <Typography.Text>
                                        {detail?.mediaInfo.length} file(s)
                                        attached
                                      </Typography.Text>
                                    ) : (
                                      <Typography.Text>
                                        Only one file can be uploaded. Allowed
                                        file types: .jpg, .png, max size: 4MB.
                                      </Typography.Text>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                        </Col>
                      </Row>
                    );
                  })}
              </div>
              {/*Repeater Area*/}

              <Row gutter={[24, 24]}>
                <Col sm={12}>
                  {/* Card for Approvers */}
                  <Card
                    title="Approvers Status"
                    bordered={true}
                    style={{ marginBottom: "10px" }}
                  >
                    {expenseDetail?.approvers && (
                      <List
                        itemLayout="horizontal"
                        dataSource={expenseDetail?.approvers}
                        renderItem={(approver, index) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <>
                                  <Tooltip
                                    title={`Approver ${index + 1}: ${
                                      approver?.additional?.name
                                    }`}
                                  >
                                    <span>
                                      <strong>Approver Name: </strong>
                                      {approver?.additional?.name}
                                    </span>
                                  </Tooltip>
                                </>
                              }
                              description={getStatusBadge(
                                approver?.status,
                                approver?.reason
                              )}
                            />
                          </List.Item>
                        )}
                      />
                    )}
                  </Card>
                </Col>

                <Col sm={12}>
                  {/* Card for Admin Approvers */}
                  <Card title="Admin Approver Status" bordered={true}>
                    {expenseDetail?.adminApprover &&
                    expenseDetail?.adminApprover.length > 0 ? (
                      <List
                        itemLayout="horizontal"
                        dataSource={expenseDetail?.adminApprover}
                        renderItem={(adminApprover, index) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <>
                                  <Tooltip
                                    title={`Approver: ${adminApprover?.userID?.name}`}
                                  >
                                    <span>
                                      <strong>Approver Name: </strong>
                                      {adminApprover?.userID?.name}
                                    </span>
                                  </Tooltip>
                                </>
                              }
                              description={getStatusBadge(
                                adminApprover?.status,
                                adminApprover?.reason
                              )}
                            />
                          </List.Item>
                        )}
                      />
                    ) : (
                      <div className="admin-status-awaiting">
                        <span>
                          Admin approval will take place after all approvers
                          complete their approvals.
                        </span>
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>

              {storeRole === process.env.REACT_APP_ADROLE &&
                expenseDetail?.adminApprover[0]?.status ===
                  process.env.REACT_APP_APPROVED_STATUS.toUpperCase() &&
                expenseDetail?.paymentPaidOn === null && (
                  <>
                    <div className="mt-5">
                      <Row gutter={[24, 24]}>
                        <Col sm={12}>
                          <label className="font-weight-bolder font-size-lg">
                            <input
                              type="checkbox"
                              defaultValue={true}
                              onChange={(e) => {
                                setPaymentStatus(e.target.checked);

                                if (e.target.checked === false) {
                                  setErrorMsg({
                                    ...errorMsg,
                                    paymentStatus:
                                      "Payment Status is Mandatory",
                                  });
                                } else {
                                  const updatedErrorMsg = { ...errorMsg };
                                  delete updatedErrorMsg.paymentStatus;
                                  delete updatedErrorMsg.paymentPaidOn;
                                  setErrorMsg(updatedErrorMsg);
                                }
                              }}
                            />
                            <span className="ml-2">
                              Confirm Payment Status as Paid
                            </span>
                            <br />
                            <small className="text-danger">
                              {errorMsg.paymentStatus}
                            </small>
                          </label>
                        </Col>
                      </Row>
                      {paymentStatus === true && (
                        <div className="mt-5">
                          <Row gutter={[24, 24]}>
                            <Col sm={12}>
                              <label className="font-weight-bolder font-size-lg">
                                <label className="mb-3">Payment Paid On</label>

                                <DatePicker
                                  disabledDate={
                                    disableFutureDatesForPaymentPaidOn
                                  }
                                  format="DD-MM-YYYY"
                                  placeholder="Select Date"
                                  className="form-control h-45px rounded-md"
                                  allowClear={true}
                                  defaultValue={[
                                    expenseDetail?.paymentPaidOn
                                      ? dayjs(
                                          expenseDetail?.paymentPaidOn,
                                          "YYYY-MM-DD"
                                        )
                                      : null,
                                  ]}
                                  onChange={(date, dateString) => {
                                    const formattedDate = moment(
                                      dateString,
                                      "DD-MM-YYYY"
                                    ).format("YYYY-MM-DD");
                                    setPaymentStatus(true);
                                    if (formattedDate) {
                                      setPaymentPaidOn(formattedDate);
                                    }

                                    if (date === "") {
                                      setErrorMsg({
                                        ...errorMsg,
                                        paymentPaidOn:
                                          "Payment Paid On date is Mandatory",
                                      });
                                    } else {
                                      const updatedErrorMsg = { ...errorMsg };
                                      delete updatedErrorMsg.paymentPaidOn;
                                      setErrorMsg(updatedErrorMsg);
                                    }
                                  }}
                                />

                                <small className="text-danger">
                                  {errorMsg.paymentPaidOn}
                                </small>
                              </label>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </div>
                  </>
                )}
              {expenseDetail?.paymentPaidOn !== null && (
                <p className="expenseStatusApproved statusmesg">
                  Payment Paid On :{" "}
                  <strong>
                    {moment(expenseDetail?.paymentPaidOn).format(
                      "Do MMM, YYYY"
                    )}
                  </strong>
                </p>
              )}
              <Row>
                <Col xs={24}>
                  <p className="font-size-h5 font-weight-boldest m-0 ml-auto text-uppercase text-right">
                    Total Reimbursement: ₹ {totalReimbursement}
                  </p>
                </Col>
              </Row>
              {((expenseDetail?.status ===
                process.env.REACT_APP_NORESPONSE_STATUS.toUpperCase().replace(
                  " ",
                  ""
                ) &&
                expenseDetail?.isNoResponse &&
                expenseDetail?.history[
                  expenseDetail?.history.length - 1
                ]?.status.toUpperCase() ===
                  process.env.REACT_APP_NORESPONSE_STATUS.toUpperCase().replace(
                    " ",
                    ""
                  ) &&
                storeRole === process.env.REACT_APP_ADROLE) ||
                (expenseDetail?.empID === decryptedEmpID &&
                  expenseDetail?.status ===
                    process.env.REACT_APP_SENTBACK_STATUS.toUpperCase())) && (
                <>
                  <div className="text-right mt-5">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="h-auto btn font-size-h5 font-weight-bold mx-auto px-5 py-2 redbutton rounded-lg text-white"
                    >
                      Resubmit
                    </Button>
                  </div>
                </>
              )}
              {storeRole === process.env.REACT_APP_ADROLE &&
                expenseDetail?.status ===
                  process.env.REACT_APP_APPROVED_STATUS.toUpperCase() &&
                expenseDetail?.paymentPaidOn === null && (
                  <div className="text-right mt-5">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="h-auto btn font-size-h5 font-weight-bold mx-auto px-5 py-2 redbutton rounded-lg text-white"
                    >
                      Update
                    </Button>
                  </div>
                )}
              <Modal
                title="Confirm Expense"
                open={isModalVisible}
                onCancel={handleCancel}
                // cancelText="No"
                // okText="Yes"
                footer={[
                  <div className="addExpenseConfirmationModalFooter">
                    <Button
                      key="submit"
                      className="okSbmit submit-btn border-0 rounded"
                      onClick={handleOk}
                    >
                      Yes
                    </Button>
                    <div className={`loader`}></div>
                  </div>,
                  <Button
                    key="back"
                    className="cancelSbmit submit-btn border-0 rounded"
                    onClick={handleCancel}
                  >
                    No
                  </Button>,
                ]}
              >
                {(expenseDetail?.isNoResponse === true &&
                  expenseDetail?.history[expenseDetail?.history.length - 1]
                    ?.status ===
                    process.env.REACT_APP_RESENT_AFTER_NORESPONSE) ||
                (paymentStatus === true && paymentPaidOn !== null) ? (
                  <p>
                    Please review your selected or entered information carefully
                    before updating the form, as changes may not be possible
                    once it's updated.
                  </p>
                ) : (
                  <p>
                    Please review your selected or entered information carefully
                    before resubmitting the form, as changes may not be possible
                    once it's resubmitted.
                  </p>
                )}
              </Modal>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
