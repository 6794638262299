import { useState, useEffect, useRef } from "react";
import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { msalConfig } from "./msalConfig";
import { useNavigate } from "react-router-dom";
import { clearRedux, loginSuccess } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { store } from "../store";

const msalInstance = new PublicClientApplication(msalConfig);

const TOKEN_REFRESH_THRESHOLD = 300000; // 5 minutes in milliseconds

const TokenRefresher = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [prevAccessTokenExpiry, setPrevAccessTokenExpiry] = useState(null);
  const timeoutRef = useRef(null);
  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );

  const scheduleTokenRefresh = async () => {
    try {
      await msalInstance.initialize();
      const currentTimestamp = Date.now();
      const customAccessTokenExpiry = currentTimestamp + 60 * 1000; // 1 minute in milliseconds

      let accessTokenExpiry =
        customAccessTokenExpiry || storeAccessTokenExpiresOn;

      if (
        !accessTokenExpiry ||
        currentTimestamp > accessTokenExpiry - TOKEN_REFRESH_THRESHOLD
      ) {
        const account = msalInstance.getActiveAccount();
        if (!account) { 
          msalInstance.loginPopup({
            scopes: ["user.read", "offline_access"],
          });
          return;
        }

        const tokenResponse = await msalInstance.acquireTokenSilent({
          account,
          scopes: ["user.read", "offline_access"],
        });
        dispatch(
          loginSuccess({
            accessToken: tokenResponse.accessToken,
            accessTokenExpiresOn: tokenResponse.expiresOn.getTime(),
            refreshToken: tokenResponse.refreshToken,
            refreshTokenExpiresOn: tokenResponse.extExpiresOn.getTime(),
          })
        );
      }

      const expiresIn = accessTokenExpiry - currentTimestamp;
      // const refreshTimeout =
      //   expiresIn > 0 ? expiresIn - TOKEN_REFRESH_THRESHOLD : 0;
      timeoutRef.current = setTimeout(scheduleTokenRefresh, 300000);

      // Update previous access token expiry
      setPrevAccessTokenExpiry(accessTokenExpiry);
    } catch (error) {
      if (error instanceof InteractionRequiredAuthError) {
        // If silent sign-in fails due to no user signed in, initiate interactive sign-in
        try {
          const interactiveRequest = {
            scopes: ["user.read", "offline_access"],
          };
          const interactiveResponse = await msalInstance.acquireTokenPopup(
            interactiveRequest
          );
          dispatch(
            loginSuccess(
              {accessToken: interactiveResponse.accessToken,
              accessTokenExpiresOn: interactiveResponse.expiresOn.getTime(),
              refreshToken: interactiveResponse.refreshToken,
              refreshTokenExpiresOn: interactiveResponse.extExpiresOn.getTime()}              
            )
          );

          navigate("/dashboard", { replace: true });
        } catch (error) {
          
          // Show error message
          message.error(
            "Due to inactivity, your session has expired. Please log in again to continue.",
            2
          );

          // Dispatch reset action to clear Redux state
          store.dispatch(clearRedux());
          
          // Log the user out and redirect to the login page
          window.location.href = "/";
        }
      } else {
        console.error("Error acquiring token:", error);
        // Handle error
      }
    }
  };

  useEffect(() => {
    scheduleTokenRefresh();
    return () => clearTimeout(timeoutRef.current);
  }, []);

  return null;
};

export default TokenRefresher;
