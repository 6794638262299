import {
  Form,
  Row,
  Col,
  DatePicker,
  Button,
  message,
  List,
  Tooltip,
  Card,
  Badge,
  Checkbox,
} from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import React, { useState, useEffect } from "react";
import Select from "react-select";
import APICaller from "../../utils/APICaller";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { SlideshowLightbox } from "lightbox.js-react";
import "lightbox.js-react/dist/index.css";
import CustomLoader from "../../components/CustomLoader";
import { decryptData } from "../../components/Constants";

export default function PendingRequestDetail() {
  const CheckboxGroup = Checkbox.Group;
  const { RangePicker } = DatePicker;
  const [selectedValues, setSelectedValues] = useState([]);
  const [prevApproverNotSelected, setPrevApproverNotSelected] = useState(false);
  const [adminSelectedApprovers, setAdminSelectedApprovers] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname?.split("/")?.[2];
  const [form] = Form.useForm();

  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );
  let storeRole = useSelector((state) => state.auth.role);
  //storeRole = storeRole ? decryptData(storeRole) : null;

  let storeToken = useSelector((state) => state.auth.accessToken);
  //storeToken = storeToken ? decryptData(storeToken) : null;

  const decryptedEmpID = useSelector((state) => state.auth.empID);
  //const decryptedEmpID = storeEmpID ? decryptData(storeEmpID) : null;

  let storeLIUId = useSelector((state) => state.auth.lIUId);
  //storeLIUId = storeLIUId ? decryptData(storeLIUId) : null;

  let storeUserInfo = useSelector((state) => state.auth.userInfo);
  storeUserInfo = decryptData(storeUserInfo);

  const [photoIndex, setPhotoIndex] = useState(0);

  const [projectCodes, setProjectCodes] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [expenseValues, setExpenseValues] = useState();
  const [checkboxes, setCheckboxes] = useState([]);

  // Update checkboxes when expenseValues.approvers changes
  useEffect(() => {
    if (expenseValues?.approvers) {
      // Initialize checkboxes based on the approvers' status
      const initialCheckboxes = expenseValues?.approvers.map(
        (approver) =>
          approver.status ===
          process.env.REACT_APP_APPROVED_STATUS.toUpperCase()
      );
      setCheckboxes(initialCheckboxes);
    }
  }, [expenseValues]);

  const handleCheckboxChange = (index) => {
    setPrevApproverNotSelected(false);
    if (index > 0 && !checkboxes[index - 1]) {
      message.error(`Please check previous checkboxes first!`);
      setPrevApproverNotSelected(true);
      return;
    }

    // Update checkbox state
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = !checkboxes[index];
    setCheckboxes(updatedCheckboxes);

    // Update selected values based on the current checkbox state
    const updatedSelectedValues =
      updatedCheckboxes &&
      updatedCheckboxes
        .map((checked, i) =>
          checked ? expenseValues.approvers[i]?.additional?._id : null
        )
        .filter((id) => id !== null); // Filter out null values

    handleStatus(storeRole, updatedSelectedValues);
  };
  const [expensesResponse, setExpensesResponse] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(
    process.env.REACT_APP_PENDING_STATUS.toUpperCase()
  );
  const [reason, setReason] = useState(null);
  const [otherReason, setOtherReason] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const initialValues = { ...expenseValues, ...expensesResponse };

  // Disable dates after the current date
  const disableFutureDates = (current) => {
    return current && current.isAfter(moment().endOf("day"), "day");
  };
  const [adminApproved, setAdminApproved] = useState(
    expenseValues?.adminApprover?.status ===
      process.env.REACT_APP_APPROVED_STATUS.toUpperCase()
  );
  // Effect to set default selected values based on some criteria
  useEffect(() => {
    // Assuming you want to select specific approvers by their userID
    const defaultSelected = expenseValues?.approvers
      .filter(
        (approver) =>
          approver.status ===
          process.env.REACT_APP_APPROVED_STATUS.toUpperCase()
      )
      .map((approver) => approver.userID); // Map to userIDs

    if (defaultSelected && defaultSelected.length > 0) {
      setSelectedValues(defaultSelected);
      setAdminSelectedApprovers(1);
    }
  }, [expenseValues]);
  const formatDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  };

  const getExpenseDetail = () => {
    const endpoint = `/pending-requests/${id}`;
    const method = "GET";
    const payload = {};
    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    ).then((response) => {
      setExpenseValues(response);
      setExpensesResponse(response?.expensesDetails);

      form.setFieldsValue({
        empName: response?.empName,
        empID: response?.empID,
        departmentID: response?.departmentID?.name,
        expensePeriodFromDate: [
          response?.expensePeriodFromDate
            ? moment(response?.expensePeriodFromDate.split("T")[0])
            : null,
          response?.expensePeriodToDate
            ? moment(response?.expensePeriodToDate.split("T")[0])
            : null,
        ],
        businessPurpose: response?.businessPurpose,
      });
    });
  };

  // GetProjectCodes
  const getProjectCodes = () => {
    const endpoint = "/project-code/findAll";
    const method = "POST";
    const payload = {
      fetchWithoutPagination: "yes",
    };

    APICaller(
      storeRole,
      storeToken,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    )
      .then((response) => {
        const temp = [{ label: "N/A", value: null }];
        response?.response?.map((projectCode, index) => {
          temp.push({
            label: projectCode?.code + " - " + projectCode?.companyName,
            value: projectCode?._id,
          });
        });

        setProjectCodes(temp);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getExpenseDetail();
    getProjectCodes();
  }, []);

  const images =
    expenseValues?.expensesDetails?.flatMap((detail) =>
      detail.mediaInfo
        ?.filter(
          (file) =>
            file?.savedFileType === "jpeg" ||
            file?.savedFileType === "jpg" ||
            file?.savedFileType === "png"
        )
        ?.map(
          (file) => process.env.REACT_APP_BACKEND + "/" + file?.savedFilepath
        )
    ) || [];

  const handleImageClick = (filePath) => {
    const index = images.findIndex((img) => img === filePath);
    if (index !== -1) {
      setPhotoIndex(index);
      setIsOpen(true);
    }
  };
  const onFinish = () => {
    if (
      storeRole === process.env.REACT_APP_ADROLE &&
      prevApproverNotSelected === true
    ) {
      message.error("Please check previous checkboxes first!");
    } else if (
      storeRole === process.env.REACT_APP_ADROLE &&
      currentStatus === process.env.REACT_APP_APPROVED_STATUS.toUpperCase() &&
      adminSelectedApprovers.length === 0
    ) {
      message.error("Please select first approver.");
    } else if (
      currentStatus === process.env.REACT_APP_PENDING_STATUS.toUpperCase()
    ) {
      message.error("Please select the status.");
    } else if (
      currentStatus === process.env.REACT_APP_CANCELED_STATUS.toUpperCase() &&
      reason === null
    ) {
      message.error("Please provide the reason.");
    } else if (
      currentStatus === process.env.REACT_APP_SENTBACK_STATUS.toUpperCase() &&
      reason === null &&
      otherReason === null
    ) {
      message.error("Please provide the reason.");
    } else if (
      (currentStatus === process.env.REACT_APP_SENTBACK_STATUS.toUpperCase() ||
        currentStatus ===
          process.env.REACT_APP_CANCELED_STATUS.toUpperCase()) &&
      otherReason === "Others" &&
      reason === null
    ) {
      message.error("Others: Please provide the reason.");
    } else if (
      currentStatus === process.env.REACT_APP_CANCELED_STATUS.toUpperCase() &&
      reason === null
    ) {
      message.error("Please provide the reason.");
    } else {
      setShowLoader(true);
      const endpoint = `/pending-requests/${id}`;
      const method = "PUT";
      const payload = {
        role: storeRole,
        status: currentStatus,
        reason: reason,
        projectCodeID: expenseValues?.projectCodeID?._id || null,
        historySource: "backend",
        historyAction: "update",
        historyUserID: storeLIUId,
        adminSelectedApprovers: adminSelectedApprovers,
      };

      APICaller(
        storeRole,
        storeToken,
        endpoint,
        method,
        payload,
        storeAccessTokenExpiresOn
      )
        .then((response) => {
          message.success("Expense updated successfully.");
          setShowLoader(false);
          navigate(`/${process.env.REACT_APP_DASHBOARD_PR_SEARCH_IN}`);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    setShowLoader(false);
  };

  const hasViewAccess =
    storeRole === process.env.REACT_APP_AROLE
      ? expenseValues &&
        expenseValues?.approvers.filter((expenseValue) => {
          return expenseValue?.userID == storeLIUId;
        })
      : "";

  const showStatusDropdown =
    storeRole === process.env.REACT_APP_AROLE
      ? expenseValues &&
        expenseValues?.approvers.filter((expenseValue) => {
          return (
            expenseValue?.userID == storeLIUId &&
            expenseValue?.status ===
              process.env.REACT_APP_PENDING_STATUS.toUpperCase()
          );
        })
      : "";

  //Approver - a1 / a2 id with status is pending or admin
  useEffect(() => {
    // Only check conditions once the data is available
    if (storeRole && expenseValues) {
      if (decryptedEmpID !== expenseValues?.empID) {
        if (hasViewAccess && hasViewAccess.length === 0) {
          setShouldRedirect(true);
        }
      }
      setIsLoading(false);
    }
  }, [storeRole, expenseValues]);

  if (isLoading) {
    return <CustomLoader showLoader={showLoader} />; // Show a loading state while checking conditions
  }

  if (shouldRedirect) {
    return <Navigate to="/dashboard" />;
  }

  const getStatusBadge = (status, reason) => {
    switch (status) {
      case "APPROVED":
        return (
          <Badge
            status="success"
            text="Approved"
            icon={<CheckCircleOutlined />}
            className="approved-badge"
          />
        );
      case "PENDING":
        return (
          <Badge
            status="warning"
            text="Pending Approval: Request Sent"
            icon={<ClockCircleOutlined />}
            className="pending-badge"
          />
        );
      case "CANCELED":
        return (
          <Badge
            status="error"
            text={
              <span>
                <strong>Canceled</strong>: {reason}
              </span>
            }
            icon={<CloseCircleOutlined />}
            className="canceled-badge"
          />
        );
      case "SENTBACK":
        return (
          <Badge
            status="processing"
            text={
              <span>
                <strong>Sent Back</strong>: {reason}
              </span>
            }
            icon={<RedoOutlined />}
            className="sentBack-badge"
          />
        );
      default:
        return (
          <Badge
            status="default"
            text="Awaiting previous Approver's Approval"
            icon={<ExclamationCircleOutlined />}
          />
        );
    }
  };

  const handleStatus1 = (checkedValues) => {
    setSelectedValues(checkedValues);
  };

  const handleStatus = (source, checkedValues) => {
    if (source === process.env.REACT_APP_AROLE) {
      // Filter to keep only those values whose status allows them to be checked
      const filteredValues =
        checkedValues &&
        checkedValues.filter((value) => {
          const approver = expenseValues?.approvers.find(
            (approver) => approver?.additional?._id === value
          );

          // Only include if the approver's status is not "APPROVED"
          return (
            approver &&
            approver.status !==
              process.env.REACT_APP_APPROVED_STATUS.toUpperCase()
          );
        });
    }
    if (source === process.env.REACT_APP_ADROLE) {
      setAdminSelectedApprovers(checkedValues);
      setSelectedValues(checkedValues);
    }
  };

  const existedApprovers = expenseValues?.approvers.length;
  const existedApprovalCount = expenseValues?.approvers.filter(
    (item) => item.status === process.env.REACT_APP_PENDING_STATUS.toUpperCase()
  ).length;

  return (
    <div className="container">
      <CustomLoader showLoader={showLoader} />
      <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-0">
        <div className="border-2 card-header p-6 d-flex justify-content-between align-items-center">
          <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark font-size-lg">
            Pending Expense Details
          </h3>
        </div>
        <div className="card-body p-3">
          <Form
            layout="vertical"
            autoComplete="off"
            className="form-validation-start"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={[12, 12]}>
              <Col sm={8}>
                <Form.Item className="m-0" label="Employee ID" name="empID">
                  <span className="form-control h-45px rounded-md customDisabled">
                    {expenseValues?.empID}
                  </span>
                </Form.Item>
              </Col>

              <Col sm={8}>
                <Form.Item className="m-0" label="Employee Name" name="empName">
                  <span className="form-control h-45px rounded-md customDisabled">
                    {expenseValues?.empName}
                  </span>
                </Form.Item>
              </Col>

              <Col sm={8}>
                <Form.Item
                  className="m-0"
                  label="Department"
                  name="departmentID"
                >
                  <span className="form-control h-45px rounded-md customDisabled">
                    {expenseValues?.departmentID?.name}
                  </span>
                </Form.Item>
              </Col>

              <Col sm={8}>
                <Form.Item
                  className="m-0"
                  label={
                    <span>
                      <span className="mandatory-symbol">*</span>Expense Period:
                      (From - To)
                    </span>
                  }
                  name="expensePeriodFromDate"
                >
                  <RangePicker
                    disabledDate={disableFutureDates}
                    className="form-control h-45px rounded-md d-inline-flex"
                    format="DD-MM-YYYY"
                    value={[
                      expenseValues?.expensePeriodFromDate
                        ? moment(expenseValues?.expensePeriodFromDate)
                        : null,
                      expenseValues?.expensePeriodToDate
                        ? moment(expenseValues?.expensePeriodToDate)
                        : null,
                    ]}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col sm={8}>
                <Form.Item
                  className="m-0"
                  label={
                    <span>
                      <span className="mandatory-symbol">*</span>Project Code
                    </span>
                  }
                  name="projectCodeID"
                >
                  <span className="form-control h-45px rounded-md customDisabled">
                    {expenseValues.projectCodeID?.code &&
                    expenseValues.projectCodeID?.companyName
                      ? expenseValues.projectCodeID?.code +
                        " - " +
                        expenseValues.projectCodeID?.companyName
                      : "N/A"}
                  </span>
                </Form.Item>
              </Col>

              <Col sm={8}>
                <Form.Item
                  className="m-0"
                  label={
                    <span>
                      <span className="mandatory-symbol">*</span>Business
                      Purpose
                    </span>
                  }
                  name="businessPurpose"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "Please enter the Reason",
                  //   },
                  // ]}
                >
                  <span className="form-control h-45px rounded-md customDisabled">
                    {expenseValues?.businessPurpose}
                  </span>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <div className="border-top my-10"></div>
              </Col>
            </Row>

            <Row gutter={[24, 24]}>
              <Col xs={24}>
                <div className="border-top my-10"></div>
              </Col>
            </Row>

            {/*Repeater Area*/}
            <div className="repeter border border-light-dark mb-5 p-3 repeter rounded-sm">
              <Row gutter={[12, 12]} className="m-0">
                <Col sm={4} className="repeat-label">
                  <label className="mb-0">
                    <span className="mandatory-symbol">*</span>Date
                  </label>
                </Col>
                <Col sm={4} className="repeat-label">
                  <label className="mb-0">
                    <span className="mandatory-symbol">*</span>Description
                  </label>
                </Col>
                <Col sm={4} className="repeat-label">
                  <label className="mb-0">
                    <span className="mandatory-symbol">*</span>Department
                  </label>
                </Col>
                <Col sm={4} className="repeat-label">
                  <label className="mb-0">
                    <span className="mandatory-symbol">*</span>Expense Code
                  </label>
                </Col>
                <Col sm={4} className="repeat-label">
                  <label className="mb-0">
                    <span className="mandatory-symbol">*</span>Cost
                  </label>
                </Col>
                <Col sm={4} className="repeat-label">
                  <label className="mb-0">Upload</label>
                </Col>
              </Row>

              {expenseValues?.expensesDetails &&
                expenseValues?.expensesDetails.map((detail, index) => {
                  return (
                    <Row gutter={[12, 12]} className="mb-4" key={index}>
                      <Col sm={4}>
                        <span className="form-control h-45px rounded-md customDisabled">
                          {moment
                            .utc(detail?.date)
                            .local()
                            .format("DD-MM-YYYY")}
                        </span>
                      </Col>

                      <Col sm={4}>
                        <span className="form-control h-auto rounded-md customDisabled">
                          {detail?.description}
                        </span>
                      </Col>

                      <Col sm={4}>
                        <span className="form-control h-45px rounded-md customDisabled">
                          {detail.departmentID?.name}
                        </span>
                      </Col>

                      <Col sm={4}>
                        <span className="form-control h-45px rounded-md customDisabled">
                          {detail.expenseCodeID?.costCode}
                        </span>
                      </Col>

                      <Col sm={4}>
                        <span className="form-control h-45px rounded-md customDisabled">
                          {detail.cost}
                        </span>
                      </Col>

                      <Col sm={4}>
                        {detail?.mediaInfo.map((file, index) => (
                          <div
                            className="symbol symbol-100 symbol-light mr-3 h-100 float-left border border-light-dark"
                            key={index}
                          >
                            {file?.savedFileType === "jpeg" ||
                            file?.savedFileType === "jpg" ||
                            file?.savedFileType === "png" ? (
                              <SlideshowLightbox>
                                <img
                                  key={index}
                                  src={`${process.env.REACT_APP_BACKEND}/${file?.savedFilepath}`}
                                  alt="media"
                                  width="100"
                                  height="100"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleImageClick(
                                      `${process.env.REACT_APP_BACKEND}/${file?.savedFilepath}`
                                    )
                                  }
                                />
                              </SlideshowLightbox>
                            ) : (
                              <div className="align-items-center d-flex h-100px justify-content-center w-100px">
                                <a
                                  href={
                                    process.env.REACT_APP_BACKEND +
                                    "/" +
                                    file?.savedFilepath
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <i className="flaticon-file-2 icons"></i>
                                  {/* {file?.savedFileName} */}
                                </a>
                              </div>
                            )}
                          </div>
                        ))}
                      </Col>
                    </Row>
                  );
                })}
            </div>
            {/*Repeater Area*/}

            <Row gutter={[24, 24]}>
              <Col sm={12}>
                {/* Card for Approvers */}
                <Card
                  title="Approvers Status"
                  bordered={true}
                  style={{ marginBottom: "10px" }}
                >
                  {expenseValues?.approvers && (
                    <List
                      itemLayout="horizontal"
                      dataSource={expenseValues?.approvers}
                      renderItem={(approver, index) => (
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <>
                                <Tooltip
                                  title={`Approver ${index + 1}: ${
                                    approver?.additional?.name
                                  }`}
                                >
                                  <span>
                                    <strong>Approver Name: </strong>
                                    {approver?.additional?.name}
                                  </span>
                                </Tooltip>
                              </>
                            }
                            description={getStatusBadge(
                              approver?.status,
                              approver?.reason
                            )}
                          />
                        </List.Item>
                      )}
                    />
                  )}
                </Card>
              </Col>

              <Col sm={12}>
                {/* Card for Admin Approvers */}
                <Card title="Admin Approver Status" bordered={true}>
                  {expenseValues?.adminApprover &&
                  expenseValues?.adminApprover.length > 0 ? (
                    <List
                      itemLayout="horizontal"
                      dataSource={expenseValues?.adminApprover}
                      renderItem={(adminApprover, index) => (
                        <List.Item>
                          <List.Item.Meta
                            title={
                              <>
                                <Tooltip
                                  title={`Approver: ${adminApprover?.userID?.name}`}
                                >
                                  <span>
                                    <strong>Approver Name: </strong>
                                    {adminApprover?.userID?.name}
                                  </span>
                                </Tooltip>
                              </>
                            }
                            description={getStatusBadge(
                              adminApprover?.status,
                              adminApprover?.reason
                            )}
                          />
                        </List.Item>
                      )}
                    />
                  ) : (
                    <div className="admin-status-awaiting">
                      <span>
                        Admin approval will take place after all approvers
                        complete their approvals.
                      </span>
                    </div>
                  )}
                </Card>
              </Col>
            </Row>

            {(storeRole === process.env.REACT_APP_ADROLE ||
              (showStatusDropdown && showStatusDropdown.length > 0)) && (
              <>
                <Card
                  title={
                    storeRole === process.env.REACT_APP_ADROLE
                      ? "Manage Approvers & Request Status"
                      : "Manage Request Status"
                  }
                  bordered={true}
                >
                  <div className="">
                    <Row gutter={[24, 24]}>
                      <Col sm={6}>
                        <label className="font-weight-bolder font-size-lg">
                          Status
                        </label>
                        <Select
                          className="h-45px rounded-md"
                          placeholder="Select options"
                          options={[
                            {
                              label: "Approved",
                              value:
                                process.env.REACT_APP_APPROVED_STATUS.toUpperCase(),
                            },
                            {
                              label: "Sent Back",
                              value:
                                process.env.REACT_APP_SENTBACK_STATUS.toUpperCase(),
                            },
                            {
                              label: "Canceled",
                              value:
                                process.env.REACT_APP_CANCELED_STATUS.toUpperCase(),
                            },
                          ]}
                          onChange={(item) => {
                            setCurrentStatus(item.value);
                            setReason(null);
                            setOtherReason(null);
                          }}
                        />
                      </Col>

                      {(currentStatus ===
                        process.env.REACT_APP_SENTBACK_STATUS.toUpperCase() ||
                        currentStatus ===
                          process.env.REACT_APP_CANCELED_STATUS.toUpperCase()) && (
                        <>
                          {currentStatus ===
                            process.env.REACT_APP_SENTBACK_STATUS.toUpperCase() && (
                            <Col sm={6}>
                              <label className="font-weight-bolder font-size-lg">
                                <span className="mandatory-symbol">* </span>
                                Reason
                              </label>
                              <Select
                                className="h-45px rounded-md"
                                placeholder="Select options"
                                options={[
                                  {
                                    label: "Incorrect Information Provided",
                                    value: "Incorrect Information Provided",
                                  },
                                  {
                                    label: "Invalid Document Uploaded",
                                    value: "Invalid Document Uploaded",
                                  },
                                  { label: "Others", value: "Others" },
                                ]}
                                onChange={(item) => {
                                  setOtherReason(item.value);
                                  if (item.value === "Others") {
                                    setReason(null);
                                  } else {
                                    setReason(item.value);
                                  }
                                }}
                              />
                            </Col>
                          )}
                          {(currentStatus ===
                            process.env.REACT_APP_CANCELED_STATUS.toUpperCase() ||
                            otherReason === "Others") && (
                            <Col sm={6}>
                              <label className="font-weight-bolder font-size-lg">
                                <span className="mandatory-symbol">* </span>
                                {currentStatus ===
                                process.env.REACT_APP_CANCELED_STATUS.toUpperCase()
                                  ? "Reason"
                                  : "Others"}
                              </label>
                              <TextArea
                                className="h-45px rounded-md"
                                placeholder="Please enter the reason"
                                onChange={(e) => {
                                  setReason(e.target.value);
                                }}
                              />
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  </div>
                  {storeRole === process.env.REACT_APP_ADROLE &&
                    currentStatus ===
                      process.env.REACT_APP_APPROVED_STATUS.toUpperCase() && (
                      <>
                        <div className="mt-5">
                          <Row gutter={[24, 24]}>
                            <Col sm={12}>
                              {/* Approver Section */}
                              <div className="approver-section">
                                <div className="font-size-lg">
                                  Select Approvers
                                </div>
                                {/* <CheckboxGroup
                                  options={expenseValues?.approvers.map(
                                    (approver) => ({
                                      label: approver?.additional?.name,
                                      value: approver?.additional?._id,
                                      disabled:
                                        approver.status ===
                                        process.env.REACT_APP_APPROVED_STATUS.toUpperCase(),
                                    })
                                  )}
                                  defaultValue={selectedValues}
                                  onChange={(values, index) => {
                                    handleStatus(storeRole, values);
                                  }}
                                /> */}
                                <div className="selectedApp">
                                  {expenseValues?.approvers.map(
                                    (approver, index) => {
                                      const isDisabled =
                                        approver.status ===
                                        process.env.REACT_APP_APPROVED_STATUS.toUpperCase();
                                      return (
                                        <label
                                          key={index}
                                          className={
                                            isDisabled ? "disabled-label" : ""
                                          }
                                        >
                                          <span className="checkbox-container">
                                            <input
                                              type="checkbox"
                                              checked={
                                                checkboxes[index] || false
                                              }
                                              onChange={() => {
                                                handleCheckboxChange(index);
                                              }}
                                              disabled={isDisabled} // Disable based on status
                                            />
                                          </span>
                                          <span>
                                            {approver?.additional?.name}
                                          </span>
                                        </label>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </>
                    )}

                  <div className="text-right mt-5">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="h-auto btn font-size-h5 font-weight-bold mx-auto px-10 py-3 redbutton rounded-lg text-white"
                    >
                      Update
                    </Button>
                  </div>
                </Card>
              </>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
}
