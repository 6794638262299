import { Link } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
//import Chart from "react-google-charts";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { message } from "antd";
import { useSelector } from "react-redux";
import ExpenseMultitasking from "../../components/ExpenseMultitasking";
import APICaller from "../../utils/APICaller";
import DashboardCards from "./dashboardCards";
import { decryptData } from "../../components/Constants";
import CustomLoader from "../../components/CustomLoader";

import { Bar } from "react-chartjs-2";
import { PDFDocument, rgb } from "pdf-lib";
import { saveAs } from "file-saver";
import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import moment from "moment";
Chart.register(CategoryScale, ChartDataLabels);

export default function Dashboard() {
  let storeRole = useSelector((state) => state.auth.role);

  let storeLIUId = useSelector((state) => state.auth.lIUId);

  let storeToken = useSelector((state) => state.auth.accessToken);

  let storeUserInfo = useSelector((state) => state.auth.userInfo);
  storeUserInfo = decryptData(storeUserInfo);
  const storeLoggedInUserName = storeUserInfo?.user?.name;
  const [dashboardDetails, setDashboardDetails] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const expenseReport = ["12 Months", "6 Months", "30 Days"];
  const [selectedPeriod, setSelectedPeriod] = useState("12 Months");
  const [chartLabels, setChartLabels] = useState(),
    [chartValues, setChartValues] = useState();

  const storeAccessTokenExpiresOn = useSelector(
    (state) => state.auth.accessTokenExpiresOn
  );
  const getDashboardDetails = async () => {
    try {
      setShowLoader(true);
      const endpoint = "/dashboardDetails";
      const method = "POST";
      const payload = {
        role: storeRole,
        lIUId: storeLIUId,
      };
      const response = await APICaller(
        storeRole,
        storeToken,
        endpoint,
        method,
        payload,
        storeAccessTokenExpiresOn
      );
      if (response) {
        setDashboardDetails(response);
        setShowLoader(false);
      }
    } catch (error) {
      const errorMessage = error.data?.message || "An error occurred";
    }
  };

  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  //isLoggedIn = isLoggedIn ? decryptData(isLoggedIn) : null;

  // useEffect(() => {

  //   const intervalId = setInterval(()=>{
  //       if (storeRole !== process.env.REACT_APP_EROLE) {
  //         getDashboardDetails();
  //       }
  //     return () => clearInterval(intervalId);
  //   }, 600);
  // }, [storeRole, storeToken, storeLIUId,isLoggedIn ]);

  const columns = [];
  columns.push({
    title: "Sr. No.",
    render: (text, record, index) => index + 1,
  });
  //Table Data
  const data = [
    {
      title: "Name",
      dataIndex: "empName",
      key: "empName",
    },
    {
      title: "Emp. Id.",
      dataIndex: "empID",
    },
    {
      title: "Business Purpose",
      dataIndex: "businessPurpose",
    },
    {
      title: "Date",
      dataIndex: "createdAt",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return (
          <span
            className={`py-3 table-btn cursor-unset text-capitalize ${text?.toLowerCase()}-btn`}
          >
            {text?.toLowerCase()}
          </span>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "totalReimbursement",
      render: (text) => {
        return `₹ ${text}`;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Link to={`/pending-requests/${record?.key}`}>
          <i className="flaticon-eye icon-lg text-grey-custom"></i>
        </Link>
      ),
    },
  ];
  columns.push(...data);

  // const chartRef = useRef(null);
  // const initialData = [
  //   [{ type: "date", label: "Date" }, "Amount"],
  //   [new Date(), 0],
  // ];

  const initialData = dashboardDetails?.twelveMonths?.labels;

  const commonData = (items, period) => {
    setChartLabels(items?.labels);
    period === "12 Months"
      ? setChartValues(items?.twelveMonthValues)
      : period === "6 Months"
      ? setChartValues(items?.sixMonthValues)
      : setChartValues(items?.dayValues);
  };

  const handleExpenseReport = (period) => {
    setSelectedPeriod(period);
    const dataMapping = {
      "12 Months": dashboardDetails?.twelveMonths || [],
      "6 Months": dashboardDetails?.sixMonths || [],
      "30 Days": dashboardDetails?.thirtyDays || [],
    };
    commonData(dataMapping[period], period);
  };

  //New CHART Start
  const chartdata = {
    labels: chartLabels,
    datasets: [
      {
        label: moment.utc(new Date()).local().format("YYYY"),
        data: chartValues,
        backgroundColor: "rgba(77, 192, 192, 0.6)",
        barThickness: 20,
      },
    ],
  };

  const chartoptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      datalabels: {
        display: true,
        color: "black",
        anchor: "end",
        align: "top",
        font: {
          weight: "bold",
          size: "12px",
        },
        formatter: (value, context) => {
          const month = context.chart.data.labels[context.dataIndex];
          const year = moment.utc(new Date()).local().format("YYYY");
          const currencySymbol = "Rs.";
          return `${currencySymbol} ${value}`;
        },
      },
    },
  };

  const chartRef = useRef(null);
  const downloadPDF = async () => {
    const chartCanvas = chartRef.current.canvas;
    const chartImage = chartCanvas.toDataURL("image/png");

    // Extract chart data
    const labels = chartRef.current.data.labels;
    const dataset = chartRef.current.data.datasets[0];

    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();
    const pageWidth = 595; // Width for A4 size in points
    const pageHeight = 842; // Height for A4 size in points

    // ** NEW ** Embed company logo image (replace with your logo URL or data)
    const companyLogoUrl = "/PdfLogo.png"; // Replace this URL with the actual logo path
    const logoImageBytes = await fetch(companyLogoUrl).then((res) =>
      res.arrayBuffer()
    );
    const logoImage = await pdfDoc.embedPng(logoImageBytes); // or embedJpg if JPG format

    const drawChartOnPage = async (page) => {
      // Embed the chart image into the PDF
      const pngImage = await pdfDoc.embedPng(chartImage);
      page.drawImage(pngImage, {
        x: 20,
        y: pageHeight - 370,
        width: 550,
        height: 300,
      });
    };

    const drawTextOnPage = (page, text, yPosition) => {
      page.drawText(text, {
        x: 20,
        y: yPosition,
        size: 12,
        color: rgb(0, 0, 0),
      });
    };

    const addExpenseDetails = (page, startY) => {
      page.drawText("Expense Details:", {
        x: 20,
        y: startY,
        size: 16,
        color: rgb(0, 0, 0),
      });
      return startY - 20;
    };

    // Start creating pages
    let page = pdfDoc.addPage([pageWidth, pageHeight]);
    let yPosition = pageHeight - 100;

    // Add chart title and company logo side by side
    page.drawText("Expense Report", {
      x: 20,
      y: pageHeight - 50,
      size: 20,
      color: rgb(0, 0, 0),
    });

    page.drawImage(logoImage, {
      x: pageWidth - 120, // Adjust X position based on the logo width
      y: pageHeight - 60, // Adjust Y position based on the logo height
      width: 100, // Set the width of the logo
      height: 30, // Set the height of the logo
    });

    // Add chart
    await drawChartOnPage(page);

    // Initialize for expense details
    yPosition -= 300; // Adjust yPosition based on chart height
    yPosition = addExpenseDetails(page, yPosition); // Add "Expense Details" title only on the first page

    let currentPageIndex = 0;
    const linesPerPage = Math.floor((pageHeight - 100 - 300 - 20) / 20); // Calculate how many lines fit per page
    const totalLines = labels.length;

    const addTextToPage = () => {
      let startIndex = currentPageIndex * linesPerPage;
      let endIndex = Math.min(startIndex + linesPerPage, totalLines);

      if (endIndex <= totalLines) {
        for (let i = startIndex; i < endIndex; i++) {
          const label = labels[i];
          const value = dataset.data[i];
          const currencySymbol = "Rs.";
          drawTextOnPage(
            page,
            `${label}: ${currencySymbol}${value} (${moment
              .utc(new Date())
              .local()
              .format("YYYY")})`,
            yPosition
          );
          yPosition -= 20;
        }

        if (endIndex < totalLines) {
          // If more lines to add, create a new page
          currentPageIndex++;
          page = pdfDoc.addPage([pageWidth, pageHeight]);
          yPosition = pageHeight - 50; // Reset position on new page without re-adding the "Expense Details" title
          addTextToPage(); // Recurse to add remaining lines without re-adding "Expense Details" title
        }
      }
    };

    addTextToPage(); // Start adding text

    // Save the PDF and trigger download
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    saveAs(blob, "expenseReport.pdf");
  };
  //New CHART END

  useEffect(() => {
    getDashboardDetails();
  }, [storeRole, storeToken, storeLIUId]);

  useEffect(() => {
    handleExpenseReport(selectedPeriod);
    setShowLoader(false);
  }, [selectedPeriod, dashboardDetails]);

  return (
    <>
      <div className="container">
        <CustomLoader showLoader={showLoader} />
        <p className="font-size-h5 text-grey-custom">
          <strong className="text-dark-custom">
            Hi, {storeLoggedInUserName} -
          </strong>{" "}
          Welcome to Expense Management System
        </p>
      </div>

      <div className="container">
        {/*Top Card Start*/}
        <div className="row">
          <div className="col-lg-3">
            <DashboardCards
              item="Today's Expenses"
              itemValue={dashboardDetails}
            />
          </div>
          <div className="col-lg-3">
            <DashboardCards
              item="Total Expenses"
              itemValue={dashboardDetails}
            />
          </div>
          <div className="col-lg-3">
            <DashboardCards item="Last Month" itemValue={dashboardDetails} />
          </div>
          {storeRole !== process.env.REACT_APP_EROLE && (
            <div className="col-lg-3">
              <DashboardCards
                item="Request Pending"
                itemValue={dashboardDetails}
                isCurrency="no"
              />
            </div>
          )}
        </div>
        {/*Top Card End*/}

        {/*Expense Report Start* */}
        <div className="expense-report">
          <div className="row">
            <div
              className={
                storeRole === process.env.REACT_APP_EROLE
                  ? "col-sm-12"
                  : "col-sm-9"
              }
            >
              <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-5">
                <div className="d-flex justify-content-between mb-6 align-items-center">
                  <h3 className="font-weight-bolder text-dark text-size-16 m-0">
                    Expense Report
                  </h3>
                  <div className="card-toolbar m-0">
                    <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                      {expenseReport &&
                        expenseReport.map((period, index) => {
                          const borderClass =
                            period === selectedPeriod
                              ? "border border-2 border-light-dark"
                              : "";
                          return (
                            <li
                              className="nav-item"
                              onClick={() => {
                                setShowLoader(true);
                                setTimeout(() => {
                                  handleExpenseReport(period);
                                }, 2000);
                              }}
                              key={index}
                            >
                              <a
                                className={`font-weight-boldest nav-link px-4 py-2 text-dark-custom cursor-pointer border border-2 border-transparent ${borderClass}`}
                              >
                                {period}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className="card-toolbar m-0">
                    <button
                      className="border border-light-dark btn font-size-md font-weight-bold font-weight-bolder px-4 py-2 text-dark-custom"
                      onClick={downloadPDF}
                    >
                      <i className="fas fa-file-download mr-2"></i>Export PDF
                    </button>

                    {/* <button
                      className="border border-light-dark btn font-size-md font-weight-bold font-weight-bolder px-4 py-2 text-dark-custom"
                      onClick={exportPDF}
                    >
                      <i className="fas fa-file-download mr-2"></i>Export PDF
                    </button>  */}
                  </div>
                </div>
                {/* <div className="chart-area" ref={chartRef}>
                  <Chart
                    chartType="Line"
                    width="100%"
                    height="400px"
                    data={datas}
                    options={options}
                  />
                </div>  */}
                <div className="chart-area">
                  <Bar ref={chartRef} data={chartdata} options={chartoptions} />
                </div>
              </div>
            </div>
            {storeRole !== process.env.REACT_APP_EROLE && (
              <div className="col-sm-3">
                <div className="card card-stretch gutter-b p-3 border-2">
                  <div className="d-flex justify-content-between mb-10">
                    <div className="d-block">
                      <h3 className="card-label font-weight-bolder text-dark font-size-h6 mb-1">
                        Pending Requests
                      </h3>
                    </div>
                    <div className="card-toolbar">
                      <Link
                        to="/pending-requests"
                        className="font-size-sm font-weight-boldest text-info text-hover-info"
                      >
                        See All
                        <i className="flaticon2-right-arrow text-info font-size-sm ml-3"></i>
                      </Link>
                    </div>
                  </div>

                  <ExpenseMultitasking
                    name="dashboardPendingRequest"
                    recordLimit="5"
                    status={[
                      process.env.REACT_APP_PENDING_STATUS.toUpperCase(),
                      process.env.REACT_APP_NORESPONSE_STATUS.replace(
                        " ",
                        ""
                      ).toUpperCase(),

                      process.env.REACT_APP_RESUBMITTED_STATUS.toUpperCase(),
                    ]}
                    reqSearchIn={process.env.REACT_APP_DASHBOARD_PR_SEARCH_IN}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {/*Expense Report End* */}

        {/** Expense Listing Start**/}
        <div className="row">
          <div className="col-sm-12">
            <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-5">
              <div className="d-flex justify-content-between mb-7">
                <div className="d-block">
                  <h3 className="card-label font-weight-bolder text-dark mb-1 text-size-16">
                    Expense Listing
                  </h3>
                </div>
                <div className="card-toolbar">
                  <Link
                    to="/list-of-expense"
                    className="font-size-md font-size-sm font-weight-boldest text-info text-hover-info"
                  >
                    See All
                    <i className="flaticon2-right-arrow text-info font-size-sm ml-3"></i>
                  </Link>
                </div>
              </div>

              <div
                className="table-responsive list-of-expense-table"
                id="alltable-size"
              >
                <ExpenseMultitasking
                  name="dashboardListOfExpense"
                  status={["ALL"]}
                  reqSearchIn={process.env.REACT_APP_DASHBOARD_EL_SEARCH_IN}
                  recordLimit="5"
                />
              </div>
            </div>
          </div>
        </div>
        {/** Expense Listing Emd**/}
      </div>
    </>
  );
}
