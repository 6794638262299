// actions are payloads of information that send data from your application to your Redux store and carry a type property that indicates the type of action being performed.
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"; //Purpose

//loginSuccess is an action creator function that creates and returns an action object.
export const loginSuccess = ({
  lIU,
  accessToken,
  accessTokenExpiresOn,
  refreshToken,
  refreshTokenExpiresOn,
}) => ({
  type: LOGIN_SUCCESS,
  payload: {
    lIU,
    accessToken,
    accessTokenExpiresOn,
    refreshToken,
    refreshTokenExpiresOn,
  },
});

export const CLEAR_REDUX = "CLEAR_REDUX";

export const clearRedux = () => ({
  type: CLEAR_REDUX,
});

// actions are payloads of information that send data from your application to your Redux store and carry a type property that indicates the type of action being performed.
export const EMAIL_VERIFICATION_SUCCESS = "EMAIL_VERIFICATION_SUCCESS"; //Purpose

//emailVerificationSuccess is an action creator function that creates and returns an action object.
export const emailVerificationSuccess = ({
  isLoggedIn,
  lIUId,
  role,
  name,
  empID,
  profileImage,
  userInfo,
}) => ({
  type: EMAIL_VERIFICATION_SUCCESS,
  payload: {
    isLoggedIn,
    lIUId,
    role,
    name,
    empID,
    profileImage,
    userInfo,
  },
});

export const SEARCH_INFO = "SEARCH_INFO";

//pageInfo is an action creator function that creates and returns an action object.
export const searchInfo = ({
  list,
  recordCountFor,
  recordCount,
  pagination,
  totalCount,
}) => ({
  type: SEARCH_INFO,
  payload: {
    list,
    recordCountFor,
    recordCount,
    pagination,
    totalCount,
  },
});

export const SELECTEDROWS_INFO = "SELECTEDROWS_INFO";

//pageInfo is an action creator function that creates and returns an action object.
export const selectedRowInfo = ({ selectedRowKeysChildInfo }) => ({
  type: SELECTEDROWS_INFO,
  payload: {
    selectedRowKeysChildInfo,
  },
});
