import APICaller from "./APICaller";
import moment from "moment";
import { store } from "../store";
import { searchInfo } from "../store/actions";
import { message } from "antd";
import { useSelector } from "react-redux";
import { encryptData } from "../components/Constants";

const processResponse = (response, totalCount, searchIn, pagination) => {
  const temp =
    response &&
    response.map((records, index) => ({
      key: records._id,
      id: index + 1,
      // Map specific fields based on searchIn value
      ...(searchIn === "permissions" && {
        name: records.name,
        createdAt: records.createdAt
          ? moment.utc(records.createdAt).local().format("DD-MM-YY hh:mm A")
          : "",
      }),
      ...(searchIn === "roles" && {
        name: records.name,
        permissions: records?.permissions,
        createdAt: records.createdAt
          ? moment.utc(records.createdAt).local().format("DD-MM-YY hh:mm A")
          : "",
      }),
      ...(searchIn === "notifications" && {
        id: records._id,
        title: records.title,
        excerpt: records.excerpt,
        assignedBy: records.assignedBy,
        assignedTo: records?.assignedTo && records.assignedTo,
        message: records?.message,
        type: records?.type,
        isRead: records?.isRead === "0" ? "Un-read" : "Read",
        createdAt: records.createdAt
          ? moment.utc(records.createdAt).local().format("DD-MM-YY hh:mm:ss A")
          : "",
      }),
      ...(searchIn === "users" && {
        empID: records.empID,
        name: records.name,
        email: records.email,
        role: records?.roleID.name,
        department: records.departmentID,
        status: records.status,
        createdAt: records.createdAt
          ? moment.utc(records.createdAt).local().format("DD-MM-YY hh:mm A")
          : "",
        updatedAt: records.updatedAt
          ? moment.utc(records.updatedAt).local().format("DD-MM-YY hh:mm A")
          : "",
      }),
      ...(searchIn === "departments" && {
        name: records.name,
        code: records.code,
        createdAt: records.createdAt
          ? moment.utc(records.createdAt).local().format("DD-MM-YY hh:mm A")
          : "",
      }),
      ...(searchIn === "project-code" && {
        departmentID: records.departmentID?.name,
        code: records.code,
        clientName: records.clientName,
        companyName: records.companyName,
        approvers: records.approvers,
        status: records.status,
        createdAt: records.createdAt
          ? moment.utc(records.createdAt).local().format("DD-MM-YY hh:mm A")
          : "",
        updatedAt: records.updatedAt
          ? moment.utc(records.updatedAt).local().format("DD-MM-YY hh:mm A")
          : "",
      }),
      ...(searchIn === "expense-code" && {
        name: records.name,
        costCode: records.costCode,
        createdAt: records.createdAt
          ? moment.utc(records.createdAt).local().format("DD-MM-YY hh:mm A")
          : "",
        updatedAt: records.updatedAt
          ? moment.utc(records.updatedAt).local().format("DD-MM-YY hh:mm A")
          : "",
      }),
      ...(searchIn === "list-of-expense" && {
        empName: records.empName,
        empID: records.empID,
        businessPurpose: records.businessPurpose,
        projectCodeID: records.projectCodeID?.code
          ? `${records.projectCodeID?.code}-${records.projectCodeID?.companyName}`
          : "N/A",
        totalReimbursement: records.totalReimbursement,
        approvers: records.approvers,
        adminApprover:
          records.status ===
            process.env.REACT_APP_SENTBACK_STATUS.toUpperCase() &&
          records.adminApprover[0]?.status ===
            process.env.REACT_APP_SENTBACK_STATUS.toUpperCase() &&
          records.adminApprover[0]?.reason
            ? process.env.REACT_APP_SENTBACK_STATUS
            : records.adminApprover[0]?.status ===
              process.env.REACT_APP_APPROVED_STATUS.toUpperCase()
            ? process.env.REACT_APP_APPROVED_STATUS
            : records.adminApprover[0]?.status ===
                process.env.REACT_APP_CANCELED_STATUS.toUpperCase() &&
              records.adminApprover[0]?.reason
            ? process.env.REACT_APP_CANCELED_STATUS
            : process.env.REACT_APP_PENDING_STATUS,
        expensePeriod:
          records.expensePeriodFromDate && records.expensePeriodToDate
            ? moment(records.expensePeriodFromDate).format("Do MMM, YYYY") +
              " - " +
              moment(records.expensePeriodToDate).format("Do MMM, YYYY")
            : "",
        month: records.expensePeriodFromDate
          ? moment(records.expensePeriodFromDate).format("MMM")
          : "",
        status: records?.history[records?.history.length - 1]?.status,
        paymentPaidOn: records.paymentPaidOn
          ? moment(records.paymentPaidOn).format("Do MMM, YYYY")
          : "",
        expensePeriodFromDate: records.expensePeriodFromDate
          ? moment(records.expensePeriodFromDate).format("DD-MM-YY")
          : "",
      }),
      ...(searchIn === "pending-requests" && {
        empName: records.empName,
        empID: records.empID,
        businessPurpose: records.businessPurpose,
        projectCodeID: records.projectCodeID?.code
          ? `${records.projectCodeID?.code}-${records.projectCodeID?.companyName}`
          : "N/A",
        totalReimbursement: records.totalReimbursement,
        approvers: records.approvers,
        adminApprover:
          records.status ===
            process.env.REACT_APP_SENTBACK_STATUS.toUpperCase() &&
          records.adminApprover[0]?.status ===
            process.env.REACT_APP_SENTBACK_STATUS.toUpperCase() &&
          records.adminApprover[0]?.reason
            ? process.env.REACT_APP_SENTBACK_STATUS
            : records.adminApprover[0]?.status ===
              process.env.REACT_APP_APPROVED_STATUS.toUpperCase()
            ? process.env.REACT_APP_APPROVED_STATUS
            : records.adminApprover[0]?.status ===
                process.env.REACT_APP_CANCELED_STATUS.toUpperCase() &&
              records.adminApprover[0]?.reason
            ? process.env.REACT_APP_CANCELED_STATUS
            : process.env.REACT_APP_PENDING_STATUS,
        expensePeriod:
          records.expensePeriodFromDate && records.expensePeriodToDate
            ? moment(records.expensePeriodFromDate).format("Do MMM, YYYY") +
              " - " +
              moment(records.expensePeriodToDate).format("Do MMM, YYYY")
            : "",
        month: records.expensePeriodFromDate
          ? moment(records.expensePeriodFromDate).format("MMM")
          : "",
        status: records?.history[records?.history.length - 1]?.status,
        paymentPaidOn: records.paymentPaidOn
          ? moment(records.paymentPaidOn).format("Do MMM, YYYY")
          : "",
        expensePeriodFromDate: records.expensePeriodFromDate
          ? moment(records.expensePeriodFromDate).format("DD-MM-YY")
          : "",
      }),
    }));
  store.dispatch(
    searchInfo({
      totalCount,
      list: {
        name: searchIn,
        [searchIn + "_info"]: temp,
      },
      pagination: {
        ...pagination,
        page: pagination.current || pagination.page,
        pageSize: pagination.pageSize,
        total: totalCount,
      },
    })
  );
};

const HandleSearch = async (
  role,
  token,
  lIUId,
  storeAccessTokenExpiresOn,
  searchIn,
  callActionFrom,
  allStates,
  totalCount
) => {
  const { pagination, tab, fetchWithoutPagination, status, isRecent } =
    allStates;

  const { current, page, pageSize, total } = pagination;
  const pge = current || page;
  const action = "findAll";

  try {
    const endpoint = `/${searchIn}/${action}`;
    const method = "POST";
    const payload = {
      page: pge,
      pageSize,
      role,
      totalCount,
      lIUId,
      searchIn,
      tab,
      fetchWithoutPagination,
      status: status || "",
      isRecent: isRecent || false,
    };
    const allRecs = await APICaller(
      role,
      token,
      endpoint,
      method,
      payload,
      storeAccessTokenExpiresOn
    );

    if (allRecs?.response) {
      processResponse(
        allRecs.response,
        allRecs.totalCount,
        searchIn,
        pagination
      );
    }
  } catch (error) {
    //message.error("Error fetching data:"+ error);
  }
};

export default HandleSearch;
