import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import Dashboard from "./view/Dashboard";
import ListOfExpense from "./view/ListOfExpense";
import PendingRequests from "./view/PendingRequests";
import ProjectCode from "./view/ProjectCode";
import ExpenseCode from "./view/ExpenseCode";
import Settings from "./view/Settings";
import Login from "./view/Login";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Main from "./components/Layouts/Main";
import UnderMaintenance from "./components/Layouts/UnderMaintenance";
import NotFound from "./components/Layouts/NotFound";
import { clearRedux } from "./store/actions";
import { useMsal } from "@azure/msal-react";
import AddExpense from "./view/AddNewExpense";
import Users from "./view/Users";
import Roles from "./view/Roles";
import Departments from "./view/Departments";
import TokenRefresher from "./utils/TokenRefresher";
import Permissions from "./view/Permissions";
import Notifications from "./view/Notifications";
import PendingRequestDetail from "./view/PendingRequests/pendingRequestDetail";
import ExpenseListingDetail from "./view/ListOfExpense/expenseListingDetail";
import NotificationDetail from "./view/Notifications/notificationDetail";
import { decryptData } from "./components/Constants";
import { message } from "antd";

const App = ({ msalInstance }) => {
  const account = msalInstance.getActiveAccount();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { instance } = useMsal();
  let userRole = useSelector((state) => state.auth.role);

  let accessToken = useSelector((state) => state.auth.accessToken);

  let isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const componentMap = {
    "/dashboard": (
      <Main children={<Dashboard />} key_elem={"1"} nameLink="Dashboard" />
    ),
    "/add-expense": (
      <Main children={<AddExpense />} key_elem={"2"} nameLink="Add Expense" />
    ),
    "/list-of-expense": (
      <Main
        children={<ListOfExpense />}
        key_elem={"3"}
        nameLink="List of Expense"
      />
    ),
    "/list-of-expense/:id": (
      <Main
        children={<ExpenseListingDetail />}
        key_elem={"13"}
        nameLink="Expense Listing Detail"
      />
    ),
    "/pending-requests": (
      <Main
        children={<PendingRequests />}
        key_elem={"4"}
        nameLink="Pending Requests"
      />
    ),
    "/pending-requests/:id": (
      <Main
        children={<PendingRequestDetail />}
        key_elem={"13"}
        nameLink="Pending Request Detail"
      />
    ),
    "/expense-code": (
      <Main children={<ExpenseCode />} key_elem={"5"} nameLink="Expense Code" />
    ),
    "/project-code": (
      <Main children={<ProjectCode />} key_elem={"6"} nameLink="Project Code" />
    ),
    "/settings": (
      <Main children={<Settings />} key_elem={"7"} nameLink="Settings" />
    ),
    "/users": <Main children={<Users />} key_elem={"8"} nameLink="Users" />,
    "/permissions": (
      <Main children={<Permissions />} key_elem={"9"} nameLink="Permissions" />
    ),
    "/roles": <Main children={<Roles />} key_elem={"10"} nameLink="Roles" />,

    "/departments": (
      <Main children={<Departments />} key_elem={"11"} nameLink="Departments" />
    ),
    "/notifications": (
      <Main
        children={<Notifications />}
        key_elem={"12"}
        nameLink="Notifications"
      />
    ),
    "/notifications/:id": (
      <Main
        children={<NotificationDetail />}
        key_elem={"13"}
        nameLink="Notification Detail"
      />
    ),
    noRoute: "/404",
    "/underMaintenance": <UnderMaintenance />,
  };

  const hasAccess = (role) => {
    const roles = {
      SUPERADMIN: [
        "/dashboard",
        "/departments",
        "/list-of-expense",
        "/list-of-expense/:id",
        "/pending-requests",
        "/pending-requests/:id",
        "/expense-code",
        "/project-code",
        "/permissions",
        "/roles",
        "/users",
        "/notifications",
        "/notifications/:id",
        "/notificationUnReadCount",
        "/settings",
      ],
      ADMIN: [
        "/dashboard",
        "/departments",
        "/add-expense",
        "/list-of-expense",
        "/list-of-expense/:id",
        "/pending-requests",
        "/pending-requests/:id",
        "/notifications",
        "/notifications/:id",
        "/notificationUnReadCount",
        "/project-code",
        "/expense-code",
      ],
      APPROVER: [
        "/dashboard",
        "/add-expense",
        "/list-of-expense",
        "/list-of-expense/:id",
        "/pending-requests",
        "/pending-requests/:id",
        "/project-code",
        "/notifications",
        "/notifications/:id",
        "/notificationUnReadCount",
      ],
      EMPLOYEE: [
        "/dashboard",
        "/add-expense",
        "/list-of-expense",
        "/list-of-expense/:id",
        "/project-code",
        "/notifications",
        "/notifications/:id",
        "/notificationUnReadCount",
      ],
    };
    return roles[role] || [];
  };

  const accessibleRoutes = userRole
    ? hasAccess(userRole)
    : !account
    ? []
    : ["/underMaintenance"];
  const [loading, setLoading] = useState(true);

  //For Disabling
  useEffect(() => {
    const preventRightClick = (e) => {
      //e.preventDefault();
    };
    const handleKeyDown = (e) => {
      // Disable F12 and Ctrl+Shift+I (DevTools)
      if (e.key === "F12" || (e.ctrlKey && e.shiftKey && e.key === "I")) {
        //e.preventDefault();
      }
    };
    const handleClickOutside = (event) => {
      const element = document.getElementById("kt_quick_panel");
      const bodyElement = document.querySelector("body");

      if (
        element &&
        element.classList.contains("offcanvas-on") &&
        !element.contains(event.target)
      ) {
        element.classList.remove("offcanvas-on");
        if (bodyElement && bodyElement.classList.contains("overflow-hidden")) {
          bodyElement.classList.remove("overflow-hidden");
        }
      }
    };
    document.addEventListener("contextmenu", preventRightClick);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("contextmenu", preventRightClick);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Check if the user should be redirected to the login page
    if (!account || !accessToken || !userRole) {
      // Only perform logout if it's the initial load or certain conditions are met
      if (location.pathname !== "/" && isLoggedIn) {
        instance
          .logoutPopup()
          .then(() => {
            dispatch(clearRedux());
            navigate("/", { replace: true });
          })
          .catch((error) => {
            console.error("Logout error:", error);
          });
      }
    } else if (location.pathname === "/" && isLoggedIn) {
      // Redirect to dashboard if user is logged in
      navigate("/dashboard", { replace: true });
    }
    setLoading(false);
  }, [
    account,
    accessToken,
    userRole,
    instance,
    dispatch,
    navigate,
    location.pathname,
    isLoggedIn,
  ]);

  // Render loading indicator if still loading
  if (loading) {
    return <div></div>;
  }

  return (
    <>
      <Routes>
        <Route
          index
          element={
            isLoggedIn ? (
              <Main
                children={<Dashboard />}
                key_elem={"1"}
                nameLink="Dashboard"
              />
            ) : (
              <Login />
            )
          }
        />
        <Route path="/underMaintenance" element={<UnderMaintenance />} />
        {accessibleRoutes.length === 0 ? (
          <Route path="*" element={<Navigate to="/" replace />} />
        ) : (
          accessibleRoutes &&
          accessibleRoutes.map((route) => {
            return (
              <Route key={route} path={route} element={componentMap[route]} />
            );
          })
        )}
        <Route
          path="*"
          element={<Navigate to={componentMap.noRoute} replace />}
        />{" "}
        {/* Handling default or unmatched routes */}
        <Route path="/404" element={<NotFound />} />
      </Routes>
      {accessToken && <TokenRefresher />}
    </>
  );
};
export default App;
