import React, { useState } from "react";
import { useSelector } from "react-redux";
import FileUpload from "../../utils/FileUpload";
import { decryptData } from "../../components/Constants";

export default function Settings() {
  let storeRole = useSelector((state) => state.auth.role);
  //storeRole = storeRole ? decryptData(storeRole) : null;

  const [collapseClass, setCollapseClass] = useState("");

  return (
    <>
      <div className="container">
        <div className="card bgi-no-repeat card-stretch gutter-b border-2 rounded-lg p-0">
          <div className="border-2 card-header p-5">
            <div className="align-items-center d-flex justify-content-between">
              <h3 className="card-title m-0 p-0 border-2 font-weight-bolder text-dark text-size-16">
                Settings
              </h3>
              {storeRole === process.env.REACT_APP_SROLE && (
                <button
                  className="btn font-size-lg font-weight-bold py-2 redbutton rounded-lg text-white collapsed cus-ml"
                  data-toggle="collapse"
                  href="#importBulkData"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseContent"
                >
                  {" "}
                  Import bulk data
                </button>
              )}
            </div>
          </div>

          <div className="p-3">
            <div className="m-0"></div>
            <div
              id="importBulkData"
              className={`collapse card-body ${collapseClass} border border-light-dark p-4 m-0`}
            >
              <FileUpload role={storeRole} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
