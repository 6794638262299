import { GoHome } from "react-icons/go";
import { LuBarChart } from "react-icons/lu";
import { VscGitPullRequestGoToChanges } from "react-icons/vsc";
import { IoMdCodeWorking } from "react-icons/io";
import { IoCodeSlashOutline } from "react-icons/io5";
import { BiBriefcase } from "react-icons/bi";
import { LuUser2 } from "react-icons/lu";
import { LiaUserLockSolid } from "react-icons/lia";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { IoSettingsOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import { createContext, useContext } from "react";
import { useMsal } from "@azure/msal-react";

export const sideMenu = {
  SUPERADMIN: [
    { "/dashboard": { name: "Dashboard", icon: <GoHome /> } },
    { "/list-of-expense": { name: "List of Expense", icon: <LuBarChart /> } },
    {
      "/pending-requests": {
        name: "Pending Requests",
        icon: <VscGitPullRequestGoToChanges />,
        showCount: "yes",
      },
    },
    { "/project-code": { name: "Project Code", icon: <IoMdCodeWorking /> } },
    { "/expense-code": { name: "Expense Code", icon: <IoCodeSlashOutline /> } },
    {
      "/departments": {
        name: "Departments",
        icon: <HiOutlineBuildingOffice2 />,
      },
    },
    { "/roles": { name: "Roles", icon: <BiBriefcase /> } },
    { "/permissions": { name: "Permissions", icon: <LiaUserLockSolid /> } },
    { "/users": { name: "Users", icon: <LuUser2 /> } },
    {
      "/notifications": {
        name: "Notifications",
        icon: <MdOutlineNotificationsActive />,
      },
    },
    { "/settings": { name: "Settings", icon: <IoSettingsOutline /> } },
  ],
  ADMIN: [
    { "/dashboard": { name: "Dashboard", icon: <GoHome /> } },
    { "/list-of-expense": { name: "List of Expense", icon: <LuBarChart /> } },
    {
      "/pending-requests": {
        name: "Pending Requests",
        icon: <VscGitPullRequestGoToChanges />,
        showCount: "yes",
      },
    },
    { "/project-code": { name: "Project Code", icon: <IoMdCodeWorking /> } },
    { "/expense-code": { name: "Expense Code", icon: <IoCodeSlashOutline /> } },
    {
      "/departments": {
        name: "Departments",
        icon: <HiOutlineBuildingOffice2 />,
      },
    },
    {
      "/notifications": {
        name: "Notifications",
        icon: <MdOutlineNotificationsActive />,
      },
    },
  ],
  APPROVER: [
    { "/dashboard": { name: "Dashboard", icon: <GoHome /> } },
    { "/list-of-expense": { name: "List of Expense", icon: <LuBarChart /> } },
    {
      "/pending-requests": {
        name: "Pending Requests",
        icon: <VscGitPullRequestGoToChanges />,
        showCount: "yes",
      },
    },
    { "/project-code": { name: "Project Code", icon: <IoMdCodeWorking /> } },
    {
      "/notifications": {
        name: "Notifications",
        icon: <MdOutlineNotificationsActive />,
      },
    },
  ],
  EMPLOYEE: [
    { "/dashboard": { name: "Dashboard", icon: <GoHome /> } },
    { "/list-of-expense": { name: "List of Expense", icon: <LuBarChart /> } },
    { "/project-code": { name: "Project Code", icon: <IoMdCodeWorking /> } },
    {
      "/notifications": {
        name: "Notifications",
        icon: <MdOutlineNotificationsActive />,
      },
    },
  ],
};

/*Custom hook to call currentPath*/
export default function useCurrentPath() {
  const location = useLocation();
  return location.pathname.replace("/", "");
}

// Define a secret key for encryption/decryption
const SECRET_KEY = "@ex#mgtSys"; // Make sure to use a strong key

export const encryptData = (data) => {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
  } catch (error) {
    console.error("Encryption failed:", error);
    return null;
  }
};

export const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return bytes && JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Decryption failed:", error);
    return null;
  }
};

const MsalContext = createContext();

export const MsalProvider = ({ children }) => {
  const msalInstance = useMsal();

  return (
    <MsalContext.Provider value={msalInstance}>{children}</MsalContext.Provider>
  );
};

export const useMsalInstance = () => {
  return useContext(MsalContext);
};
